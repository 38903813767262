//import { useState } from "react";
import styles from "./MgmNewsMessage.module.css";
import { Icon, TextLink } from "@mgmresorts/mgm-ui";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_RELATED_NEWS } from "../../features/api-wrapper/apiQueries";
import moment from "moment";
import { useQuery } from "@apollo/client";
import Loader from "../global/Loader";
import APIError from "../../pages/ErrorHandling/APIError";
import InnerHtml from "./InnerHtml";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import ScrollToTop from "../global/ScrollToTop";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { domActions } from "../../data/dom-slice";

const MgmNewsMessage = (props) => {
  const dispatch = useDispatch();
  const [newsData, setNewsData] = useState([]);

  const breadcrumbForceEnable = useSelector(
    (state) => state.navigation.breadcrumbForceEnable
  );
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );

  const { loading, error, data } = useQuery(GET_RELATED_NEWS, {
    client: apiMiddlewareClient,
  });

  const currentPageURL = useSelector((state) => state.dom.currentPageURL);

  const location = useLocation();
  let currentLocation;
  useEffect(() => {
    currentLocation = { ...location };
    dispatch(domActions.setCurrentPageURL(currentLocation.pathname));
  }, []);

  useEffect(() => {
    setNewsData(props.data);
  }, [props]);

  useEffect(() => {
    if (!breadcrumbForceEnable && currentPageURL === location.pathname) {
      props.loadLandingPage();
    }
  }, [breadcrumbForceEnable]);

  const getFormatedDate = (dateString) => {
    let dateNum = new Date(dateString).getMonth();
    return `${globalProperties?.short_month[dateNum]} ${moment(
      dateString
    ).format(" D, YYYY")}`;
  };

  if (loading) return <Loader />;
  if (error) return <APIError error={error} />;

  //check with current related new
  let count = 0;
  const relatedNewsData = data?.relatedNews
    ?.map((d) => {
      if (d?.newsId != props.data?.newsId && count < 3) {
        count++;
        return d;
      }
    })
    .filter((d) => d);

  const moveLeft = (e) => {
    e.preventDefault();
    props.moveLeft();
  };
  const moveRight = (e) => {
    //debugger; // eslint-disable-line no-debugger
    e.preventDefault();
    props.moveRight();
  };

  return (
    <section className={styles.mgmNewsMessageHolder}>
      <ScrollToTop force={true} />
      <div className={styles.message}>
        <div>
          <h2>{newsData?.newsTitle}</h2>
          <p className={styles.newsSummary}>{newsData?.newsSummary}</p>
        </div>

        <div className={styles.mainContainer}>
          <section style={{ width: "100%" }}>
            {newsData?.newsContentType === "video" && (
              <div className={styles.videoHolder}>
                <div>
                  <InnerHtml
                    description={newsData.videos[0].videoEmbedHTML}
                    style={styles.videos}
                  />
                </div>
                <div className={styles.leftArrow} onClick={(e) => moveLeft(e)}>
                  <Icon
                    name="chevron-left"
                    variant="outlined"
                    size="small"
                    role="img"
                  />
                </div>
                <div
                  className={styles.rightArrow}
                  onClick={(e) => moveRight(e)}
                >
                  <Icon
                    name="chevron-right"
                    variant="outlined"
                    size="small"
                    role="img"
                  />
                </div>
              </div>
            )}

            {newsData && newsData?.newsContentType != "video" && (
              <div className={styles.imageHolder}>
                <img
                  src={
                    newsData.images &&
                    newsData.images.length &&
                    newsData.images[0].imageURL
                  }
                  alt="article image"
                />

                <div className={styles.leftArrow} onClick={(e) => moveLeft(e)}>
                  <Icon
                    name="chevron-left"
                    variant="outlined"
                    size="small"
                    role="img"
                  />
                </div>
                <div
                  className={styles.rightArrow}
                  onClick={(e) => moveRight(e)}
                >
                  <Icon
                    name="chevron-right"
                    variant="outlined"
                    size="small"
                    role="img"
                  />
                </div>
              </div>
            )}

            {newsData?.newsContentType === "article" && (
              <InnerHtml
                description={newsData.newsBody}
                style={styles.innerHtml}
              />
            )}

            <hr></hr>
            <div className={styles.buttonHolder}>
              <TextLink
                href="#"
                iconLeft="arrow-left"
                variant="large"
                onClick={(e) => moveLeft(e)}
              >
                <span style={{ marginBottom: "2px" }}>
                  {globalProperties.previous}
                </span>
              </TextLink>
              <TextLink
                href="#"
                iconRight="arrow-right"
                variant="large"
                onClick={(e) => moveRight(e)}
              >
                <span style={{ marginBottom: "2px" }}>
                  {globalProperties.next}
                </span>
              </TextLink>
            </div>
          </section>
          <section className={styles.relatedNews}>
            <h3>{props.relatedMgmNewsLabel}</h3>
            <hr />
            <div style={{ marginTop: "20px" }}></div>
            {relatedNewsData.map((d, index) => (
              <div key={index} className={styles.news}>
                <div
                  className={styles.imageContainer}
                  onClick={() => {
                    setNewsData(d);
                  }}
                >
                  <img
                    src={d.images[0].imageURL}
                    className={styles.image}
                    alt={"related new article image"}
                  />
                </div>
                <p className={styles.date}>
                  {getFormatedDate(d.newsPublishedDate)}
                </p>
                <h5
                  onClick={() => {
                    setNewsData(d);
                  }}
                >
                  {d.newsTitle}
                </h5>
              </div>
            ))}
          </section>
        </div>
      </div>
    </section>
  );
};

export default MgmNewsMessage;