import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ErrorCard from "./ErrorCard";
import styles from "./UnauthorizedAccess.module.css";

const UnauthroizedAccess = () => {
  const [errorData, setErrorData] = useState();
  const globalNotification = useSelector((state) => state.globalNotification);

  useEffect(() => {
    if (globalNotification.systemErrors) {
      const { systemErrors } = globalNotification || {};
      const pageNotFound = systemErrors.filter((err) => {
        return err.type == "403";
      });
      setErrorData(pageNotFound[0]);
    }
  }, [globalNotification]);

  return (
    <div className={styles.unAuthAccessHolder}>
      {errorData && (
        <ErrorCard
          title={errorData.heading}
          message={errorData.description}
          cta={errorData.cta}
        />
      )}
    </div>
  );
};

export default UnauthroizedAccess;
