import { Link } from "react-router-dom";
import mgmofferstyle from "./mgmoffer.module.css";
import { useEffect, useState } from "react";
import { CenterModal, Button } from "@mgmresorts/mgm-ui";
// import moment from "moment";
import ChooseCta from "../../../hooks/ChooseCta";
import InnerHtml from "../../dynamic/InnerHtml";
// import { useSelector } from "react-redux";
import ConfirmationPopup from "../../global/ConfirmPrompt";
import useWindowDimensions from "../../../hooks/useScreenDetails";

const Mgmoffer = (props) => {
  const { children: offerCards, heading, link } = props.data || {};
  // const globalProperties = useSelector(
  //   (state) => state.globalNotification?.globalProperties
  // );
  const [isMobile] = useWindowDimensions();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [selectedModal, setSelectedModal] = useState(0);
  const [isContinue, setIsContinue] = useState(false);
  const [zIndex, setZindex] = useState(false);

  useEffect(() => {
    if (isModalOpen) {
      //hide scrollbar
      document.body.style.overflow = "hidden";
      document.body.style.height = "100vh";

      //set modal width
      document.getElementsByTagName("dialog")[0].style.maxWidth = "776px";
      document.getElementsByTagName("dialog")[0].style.height = "max-content";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [isModalOpen]);

  const openModal = (args) => {
    setSelectedModal(args[0]);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  // const getFormatedDate = (dateString) => {
  //   let dateNum = new Date(dateString).getMonth();
  //   return `${globalProperties?.short_month[dateNum]}${moment(
  //     dateString
  //   ).format(", DD YYYY")}`;
  // };

  function windowOpen(url, name) {
    if (!url.match(/^https?:\/\//i)) {
      url = "http://" + url;
    }
    return window.open(url, name);
  }

  useEffect(() => {
    if (isModalOpen) {
      document.getElementsByTagName("dialog")[0].style.maxWidth = "776px";
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (isContinue) {
      setIsConfirmPopupOpen(false);
      setIsContinue(false);

      windowOpen(offerCards[selectedModal]?.redeem_link?.href, "_blank");
    }
  }, [isContinue]);

  return (
    <>
      <div className={mgmofferstyle.main}>
        <div className={mgmofferstyle.toptextholder}>
          <div className={mgmofferstyle.subTextHolder}>
            <div className={mgmofferstyle.offerTitle}>{heading}</div>
            <Link to={link.href} className={mgmofferstyle.offerLink}>
              {link.title}
            </Link>
          </div>
        </div>
        <div className={mgmofferstyle.offerHolder}>
          <div className={mgmofferstyle.offerSubHolder}>
            <div className={mgmofferstyle.allCardHolder}>
              {offerCards.map((tiledata, index) => (
                <div className={mgmofferstyle.cardHolder} key={index}>
                  <div className={mgmofferstyle.imageHolder}>
                    <div className={mgmofferstyle.offerCard}>
                      <div
                        className={mgmofferstyle.cardimg}
                        style={{ background: `url("${tiledata?.image}")` }}
                      >
                        {/*<img src={tiledata.image} alt="offer card image"></img>*/}
                      </div>

                      <div className={mgmofferstyle.cardSubHolder}>
                        {/* <div className={mgmofferstyle.cardDate}>
                          {getFormatedDate(tiledata.offerDate)}
                        </div> */}
                        <div className={mgmofferstyle.cardTitle}>
                          {tiledata.heading}
                        </div>

                        <div className={mgmofferstyle.description}>
                          {tiledata.short_description}
                        </div>

                        <ChooseCta
                          style={mgmofferstyle.cardBtn}
                          data={{ label: tiledata.ctaLabel, openIn: "modal" }}
                          args={[index]}
                          openModal={openModal}
                          type="secondary"
                          size="small"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={isMobile ? (zIndex ? "edit-popup" : "") : "edit-popup"}>
        <CenterModal
          open={isModalOpen}
          size="medium"
          onClose={closeModal}
          className={mgmofferstyle.modelPopup}
        >
          <div className={mgmofferstyle.offerImageContainerOuter}>
            <div className={mgmofferstyle.offerImageContainer}>
              <img
                src={offerCards[selectedModal].image}
                alt="offer card image"
              />
            </div>
            {/* <h3>
            {globalProperties?.published}{" "}
            {getFormatedDate(offerCards[selectedModal].offerDate)}
          </h3> */}
            <h2>{offerCards[selectedModal].heading}</h2>
            <InnerHtml
              description={offerCards[selectedModal].description}
              style={mgmofferstyle.innerHtml}
              /*hash={offerCards[selectedModal].description}*/
            />
            {/* <TextLink
          className={mgmofferstyle.redeemButton}
          data-cslp=""
          href={
            props.data.children[0].redeem_link?.href
              ? props.data.children[0].redeem_link.href
              : "#"
          }
          variant="large"
          target={props.data.children[0].redeem_link?.href ? "_blank" : "_self"}
        >
          {props.data.children[0].redeem_link?.title}
        </TextLink> */}
            <div className={mgmofferstyle.RedeemButton}>
              {/* <a
              rel="noreferrer"
              className={mgmofferstyle.redeemButton}
              //className={styles.redeemBtn}
              // href={data?.redeem_link?.href ? data.redeem_link.href : "#"}
              // href={
              //   offerCards[selectedModal].redeem_link?.href
              //     ? offerCards[selectedModal].redeem_link.href
              //     : null
              // }
              target={
                offerCards[selectedModal].redeem_link?.href ? "_blank" : "_self"
              }
              onClick={() =>
                offerCards[selectedModal].redeem_link?.href &&
                setIsConfirmPopupOpen(true)
              }
            >
              {/* {data?.redeem_link?.title} */}

              {/* {offerCards[selectedModal].redeem_link?.title} */}
              {/* </a> */}
              {offerCards[selectedModal].redeem_link?.title &&
              offerCards[selectedModal].redeem_link?.href ? (
                <Button
                  className={mgmofferstyle.redeemButton}
                  // href={data?.redeem_link?.href ? data.redeem_link.href : "#"}
                  target={
                    offerCards[selectedModal].redeem_link?.href
                      ? "_blank"
                      : "_self"
                  }
                  onClick={() =>
                    offerCards[selectedModal].redeem_link?.href &&
                    setIsConfirmPopupOpen(true)
                  }
                  variant="secondary"
                >
                  {offerCards[selectedModal].redeem_link?.title}
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
        </CenterModal>

        {isConfirmPopupOpen && (
          <ConfirmationPopup
            isConfirmPopupOpen={isConfirmPopupOpen}
            onContinue={setIsContinue}
            onClose={() => {
              setIsConfirmPopupOpen(false);
              setZindex(false);
            }}
          />
        )}
      </div>
    </>
  );
};
export default Mgmoffer;
