import styles from "./LinkSet.module.css";
import ChooseCta from "../../hooks/ChooseCta";
import { useState } from "react";
import InnerHtml from "./InnerHtml";
import Modal from "../global/ui/Modal";

const LinkSet = (props) => {
  //console.log(props);

  let [showPopup, setShowPopup] = useState(false);
  let [selectedModal, setSelectedModal] = useState();

  const openModal = (args) => {
    let modal = props.data?.children[[args]]?.cta?.modalContent;
    if (modal != null && modal != undefined) {
      setSelectedModal({
        heading: modal.heading,
        body: modal.content,
      });
    } else {
      setSelectedModal({ heading: "", body: "" });
    }
    setShowPopup(true);
  };

  const onPopupClose = () => {
    setShowPopup(false);
  };

  return (
    <>
      <div className={styles.linkSetHolder}>
        <div className={styles[props.data?.align]}>
          {props.data?.children?.map((item, index) => (
            <ChooseCta
              key={index}
              data={item.cta}
              type="text_link"
              args={index}
              openModal={openModal}
            />
          ))}
        </div>
      </div>
      {showPopup && (
        <Modal onClose={onPopupClose} heading={selectedModal.heading}>
          <InnerHtml description={selectedModal.body} />
        </Modal>
      )}
    </>
  );
};

export default LinkSet;
