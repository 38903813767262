import style from "./EmployeeId.module.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_EMPLOYEE_DETAILS } from "../../features/api-wrapper/apiQueries";
import { useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { profileUserActions } from "./../../data/profile-user-slice";
import AgentSearchHeader from "./AgentSearchHeader";
//import ErrorHanding from "../ErrorHandling/ErrorHanding";
//import Loader from "../../components/global/Loader"
import { getLocalStoreValue } from "../../utils/localStore";
import {handleTokenExpiry} from "../../utils/error"
import ErrorCardRestricted from "./../../pages/ErrorHandling/ErrorCardRestricted"
import axios from "axios";
import Loader from "../../components/global/Loader";
const { api } = window;
const EmployeeId = (props) => {
  const [errorAuth,setError] = useState();
  const employeeId_len = 5;
  const dispatch = useDispatch();
  const [loader,setLoader] = useState(true);
  //const [userid, setUserId] = useState("");
  const [user, setUser] = useState("");
  let userid;
  const [showErrorBox, setShowErrorBox] = useState(false);
  const [dataAvailable, setDataAvailable] = useState(false);
  const [getEmployeeDetails, { error, data }] = useLazyQuery(GET_EMPLOYEE_DETAILS, {
    enabled: false, manual: true, fetchPolicy: 'no-cache', client: apiMiddlewareClient
  });
  
  let token = getLocalStoreValue('token');
  let apigee_token = getLocalStoreValue("apigee_token");
  const headers = {
    Authorization: `Bearer ${apigee_token}`,
    jwt: token,
  };
  const baseURL = process.env.REACT_APP_BASE_URL;
  let oktaLogin = process.env.REACT_APP_OKTA_LOGIN;
  
  useEffect(() => {
    //sessionStorage.setItem("electron", "");
   
    try {
        // ipcRenderer.on becomes api.recieve
        api.recieve("electron-renderer", (args) => {

            console.log("electron-renderer called---", args);

            localStorage.setItem("electron", "electron");

        });
    } catch (error) {
        console.log(error);
    }
    axios
        .get(
            `${baseURL}api/check-agent-access`,
            {
                headers: headers,
            }
        )
        .then((response) => {
            console.log('response>>>>', response.status);
            setLoader(false);
        }).catch((error) => {
          setLoader(false);
            console.log("error>>>>>", error.response.status);
            if (error.response && error.response.status && error.response.status == 401) {
                //props.resize();
                if (oktaLogin == "true") {
                    window.location.replace(`${process.env.REACT_APP_BASE_URL_LOGIN}login`)
                    try {
                        api.send("resizeWindow", "resizeWindow");
                    } catch (e) {
                        //console.log("error occured");
                    }
                }

            }
            else if (error.response && error.response.status && error.response.status == 403) {
                setError({title:"Error 403: Access Restricted",message:"You do not have access to the application. Please contact the administrator."});
            }
        });
      
        

}, [])
  useEffect(() => {
    if(error) {
      handleTokenExpiry(error);
    }    
  }, [error]);
  useEffect(() => {
    let oktaLogin = process.env.REACT_APP_OKTA_LOGIN;
    let token = getLocalStoreValue("token");
    let apigee_token = getLocalStoreValue("apigee_token");
    console.log('token>>>',token);
    if (oktaLogin == "true") {
      if (
        !token ||
        token == null ||
        token == "" ||
        !apigee_token ||
        apigee_token == null ||
        apigee_token == ""
      ) {
        window.location.replace(`${process.env.REACT_APP_BASE_URL_LOGIN}login`);
      }
    }    
  }, []);
  useEffect(() => {
    console.log("data >>>>>", data);
    console.log("GET_EMPLOYEE_DETAILS", getEmployeeDetails)

    if (data && data.employeeDetails.data && data.employeeDetails.status == 200) {

      setShowErrorBox(false);
      setDataAvailable(true);
    }
    else {

      setShowErrorBox(true);
      setDataAvailable(false);
    }

  }, [data]);
  useEffect(() => {
    setShowErrorBox(false);
    setDataAvailable(false);

  }, []);

  //setUserId(props.id);
  // test user 585739 // 585735

  try {
    // ipcRenderer.on becomes api.recieve
    api.recieve("copiedMsg", (args) => {

      console.log("copiedMsg called---", args);
      let userid = args[0].replace(/\D/g, "");
      setUser(userid);
      if (userid && userid.length >= employeeId_len) {
        getEmployeeDetails({ variables: { "employeeId": Number(userid) } });
      }
      else {
        setDataAvailable(false);
      }

    });
  } catch (error) {
    console.log(error);
  }

  console.log(data);




  props.electronBgColor("#FAF9F5");
  props.resize();
  const navigate = useNavigate();
  const goToResults = () => {
    goToAgentSearchlanding();

    //getEmployeeDetails({ variables: { "employeeId": Number(userid) }});
  };
  const goToAgentSearchlanding = () => {
    //console.log('data.employeeDetails.data>>>',data.employeeDetails.data);
    if (dataAvailable === true) {
      dispatch(profileUserActions.setProfileUser(data.employeeDetails.data))
      navigate("/agentsearchlanding");

    }
  }
  const inputValChange = (value) => {
    console.log(value);
    userid = value.replace(/\D/g, "");
    console.log('userid>>>', userid.length);
    setUser(userid);
    if (userid && userid.length >= employeeId_len) {
      getEmployeeDetails({ variables: { "employeeId": Number(userid) } });
    }
    else {
      setDataAvailable(false);
    }

  };
  if(loader) return <Loader></Loader>
  return (<><AgentSearchHeader></AgentSearchHeader>{errorAuth?<ErrorCardRestricted
    title={errorAuth.title}
    message={errorAuth.message}
  />:<div className="container container_agent_search">
      <div className={style.agentpagecontainer}>
        <div className={style.empIdH}>
          <div className={style.emplab}>Enter employee ID:</div>
          <div className={style.inputH}>
            <input className={style.inp}
              type="text"
              name="idInput"
              value={user}
              onChange={(event) => inputValChange(event.target.value)}
              placeholder="Employee ID"
            />
            <button className={style.btn} onClick={() => goToResults()}>Next</button>
          </div>

          {dataAvailable === true && data && data.employeeDetails && data.employeeDetails.data ? <div className={style.empResult} onClick={() => goToAgentSearchlanding()}>{data.employeeDetails.data.name} ({data.employeeDetails.data.employeeId})</div> : <></>}
        </div>
        <div className={style.empBody}>
          <div className={style.empMsg}>
            <div className={style.empMsg1}>Enter employee ID to search</div>
            <div className={style.empMsg2}>
              You must enter an employee ID above to begin using the My MGM search
              tool.
            </div>
          </div>
          {showErrorBox === true ? <div className={style.empErrorHolder}>
            <div className={style.empError}>
              <div className={style.empMsg4}>Employee ID not found!</div>
              <div className={style.empMsg3}>
                No matching employee was found. Please try again.
              </div>
            </div>
          </div> : <></>}
          {error ? <div className={style.empErrorHolder}>
            <div className={style.empError}>
              <div className={style.empMsg4}>{error.message}</div>
            </div>
          </div> : <></>}
        </div>
      </div></div>}</>
  );
};

export default EmployeeId;
