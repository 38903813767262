import { CardContainer } from "@mgmresorts/mgm-ui";
import styles from "./ContentCardFrame.module.css";
import ChooseCta from "../../hooks/ChooseCta";
import InnerHtml from "../dynamic/InnerHtml";

const ContentCardFrame = (props) => {
  return (
    <section className={styles.contentCardFrame}>
      <CardContainer
        className={styles.cardContainer}
        style={{ borderRadius: "8px", margin: "0", padding: "0" }}
      >
        <div className={styles.cardContainerInner}>
          <h3 data-cslp={props.data?.$?.heading}>{props.data.heading}</h3>
          {props.data?.description && (
            <InnerHtml
              description={props.data?.description}
              style={styles.innerHtml}
              hash={props.data?.$?.description}
            />
          )}
          {props.data?.ctaEnabled && (
            <div className={styles.buttonHolder}>
              <ChooseCta data={props.data?.cta} size="small" type="secondary" />
            </div>
          )}
        </div>
      </CardContainer>
    </section>
  );
};

export default ContentCardFrame;
