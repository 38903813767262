import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  firstName: '',
  lastName: '',
  language: 'en-us',
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLanguage(state, action) {
      state.language = action.payload;
    },
  },
});

export const userActions = userSlice.actions;
export default userSlice;
