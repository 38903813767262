import styles from "./LeadershipMessage.module.css";
import InnerHtml from "./InnerHtml";
import { Button } from "@mgmresorts/mgm-ui";

const LeadershipMessage = (props) => {
  const openMessage = () => {
    props.openMessage(props.messageIndex);
  };
  return (
    <div className={styles.leadershipMessage}>
      <div
        className={styles.imgHolder}
        style={{ background: `url("${props.data?.image}")` }}
      >
        {/*<img src={props.data?.image} />*/}
      </div>
      <div>
        <h4>{props.date}</h4>
        <h3>{props.data?.heading}</h3>
        <InnerHtml
          description={props.data?.description}
          style={styles.innerHtml}
        />
        <div className={styles.buttonHolder}>
          <Button
            onClick={openMessage}
            size="small"
            variant="secondary"
            label={props.data?.ctaLabel}
          ></Button>
        </div>
      </div>
    </div>
  );
};

export default LeadershipMessage;
