import { useEffect, useState } from "react";
// import moment from "moment";
import { CenterModal, Button } from "@mgmresorts/mgm-ui";

import InnerHtml from "../../components/dynamic/InnerHtml";
import styles from "./OfferModal.module.css";
import ConfirmationPopup from "../global/ConfirmPrompt";
import useWindowDimensions from "../../hooks/useScreenDetails";
import { useSelector,useDispatch } from "react-redux";
import {setGoBack,setContinue} from "./../../data/notification-slice";

const OfferModal = (props) => {
  const data = props.data;
  const [isMobile] = useWindowDimensions();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isContinue, setIsContinue] = useState(false);
  const [zIndex, setZindex] = useState(false);
  let isRedeemClicked = false;
  const dispatch = useDispatch();
  const doBack = useSelector(
    (state) => state.globalNotification.back
  );
  const doContinue = useSelector(
    (state) => state.globalNotification.continue
  );
  const closeModal = (e) => {
    console.log('on close >>>>>',e.target.getAttribute('class'));
    if(!e.target.getAttribute('class'))
    {
      props.setModal(false);
      return;
    }
    console.log('back >>>>>',e.target.getAttribute('class').includes('back'));
    console.log('continue >>>>>',e.target.getAttribute('class').includes('continue'));
    console.log('confirmationPopupOn>>>>',confirmationPopupOn);
    if ((e.target.getAttribute('class').includes('back') != true || e.target.getAttribute('class').includes('continue') != true) && (confirmationPopupOn == false || typeof(confirmationPopupOn) == 'undefined')) {

      props.setModal(false);
    }


  };
  const confirmationPopupOn = useSelector(
    (state) => state.globalNotification.confirmationPopupOn
  );
  const globalNotification = useSelector((state) => state.globalNotification);
  const { whiteListedDomain } = globalNotification || [];

  function windowOpen(url, name) {
    console.log('url>>>>>', url);
    if (!url.match(/^https?:\/\//i)) {
      url = "http://" + url;
    }
    return window.open(url, name);
  }
  useEffect(() => {
    console.log('doBack>>>>>', doBack);
    if(doBack == 'back')
    {
      dispatch(setGoBack(''))
    }
    
  }, [doBack]);

  useEffect(() => {
    console.log('doContinue>>>>>', doContinue);
    if(doContinue == 'continue')
    {
      if(isRedeemClicked)
      {
        windowOpen(data.redeem_link?.href, "_blank");
        isRedeemClicked = false;
      }
      props.setModal(false);
      setIsContinue(false);

      dispatch(setContinue(''))
    }
    
  }, [doContinue]);

  useEffect(() => {
    //hide scrollbar
    document.body.style.overflow = "hidden";
    document.body.style.height = "100vh";

    //set modal width
    document.getElementsByTagName("dialog")[0].style.maxWidth = "776px";
    document.getElementsByTagName("dialog")[0].style.height = "max-content";

    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);
  useEffect(() => {
    console.log(isContinue);
    if (isContinue) {
      setIsModalOpen(false);
      setIsContinue(false);

      windowOpen(data.redeem_link?.href, "_blank");
    }
  }, [isContinue]);

  return (
    <div className={isMobile ? (zIndex ? "edit-popup" : "") : "edit-popup"}>
      <CenterModal
        title=""
        open
        size="medium"
        onClose={(e) => closeModal(e)}
        className={styles.OfferModal}
      >
        <div className={styles.offerImageContainerOuter}>
          <div className={styles.offerImageContainer}>
            <img
              className={styles.offerImage}
              src={
                data.description_image
                  ? data.description_image.url
                  : data.image.url
              }
            />
          </div>

          {/* <h3>
          {`${
            props.name_value?.filter((d) => d.name == "published")[0].value
          } ${moment(data.publish_details.time).format("MMMM, D YYYY") || ""}`}
        </h3> */}
          <h2>{data.heading}</h2>
          <InnerHtml description={data.description} style={styles.innerHtml} />
          <div className={styles.RedeemButton}>
            {data?.redeem_link?.title && data?.redeem_link?.href ? (
              <Button
                className={styles.redeemBtn}
                // href={data?.redeem_link?.href ? data.redeem_link.href : "#"}
                target={data?.redeem_link?.href ? "_blank" : "_self"}
                onClick={() => {

                  isRedeemClicked = true;
                  whiteListedDomain.some((v) => {
                    return data?.redeem_link?.href ? data?.redeem_link?.href.includes(v) : false;
                  })
                    ? window.open(data?.redeem_link?.href, "_blank")
                    : setIsModalOpen(true);
                  
                }}
                variant="secondary"
              >
                {data?.redeem_link?.title}
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>
      </CenterModal>
      {isModalOpen && (
       
        <ConfirmationPopup
          isModalOpen={isModalOpen}
          onContinue={setIsContinue}
          onClose={() => {
            setIsModalOpen(false);
            setZindex(false);
          }}
        />
        
      )}
    </div>
  );
};
export default OfferModal;
