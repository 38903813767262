import styles from "./FiftyFiftyPhoto.module.css";
import ChooseCta from "../../hooks/ChooseCta";
import InnerHtml from "./InnerHtml";
import Modal from "../global/ui/Modal";
import { useState } from "react";
import { CardContainer } from "@mgmresorts/mgm-ui";

const FiftyFiftyPhoto = (props) => {
  let [showPopup, setShowPopup] = useState(false);
  let [selectedModal, setSelectedModal] = useState();
  const whiteBackground = props?.data?.type == "white_bg";

  const openModal = () => {
    let modal = props.data?.cta?.modalContent;
    if (modal != null && modal != undefined) {
      setSelectedModal({
        heading: modal.heading,
        body: modal.content,
      });
    } else {
      setSelectedModal({ heading: "", body: "" });
    }
    setShowPopup(true);
  };

  const onPopupClose = () => {
    setShowPopup(false);
  };

  return (
    <>
      {whiteBackground && (
        <div className={styles.fiftyFiftyPhoto}>
          <CardContainer
            className={styles.cardContainer}
            style={{ borderRadius: "8px", margin: "0", padding: "0" }}
          >
            <FiftyFiftyPhotoInner openModal={openModal} data={props.data} />
          </CardContainer>
        </div>
      )}
      {!whiteBackground && (
        <div className={styles.fiftyFiftyPhoto}>
          <FiftyFiftyPhotoInner openModal={openModal} data={props.data} />
        </div>
      )}
      {showPopup && (
        <Modal onClose={onPopupClose} heading={selectedModal.heading}>
          <InnerHtml description={selectedModal.body} />
        </Modal>
      )}
    </>
  );
};

export default FiftyFiftyPhoto;

const FiftyFiftyPhotoInner = (props) => {
  return (
    <div className={styles.fiftyFiftyInner}>
      <div>
        <img data-cslp={props.data?.$?.image} src={props.data?.image} />
      </div>
      <div className={styles.FFcontainer}>
        <h3 data-cslp={props.data?.$?.heading}>{props.data?.heading}</h3>
        <InnerHtml
          description={props.data?.description}
          hash={props.data?.$?.description}
          style={styles.slideDescription}
        />
        {props.data?.ctaEnabled && (
          <div className={styles.buttonHolder}>
            <ChooseCta
              data={props.data?.cta}
              size="small"
              type="secondary"
              openModal={props.openModal}
            />
          </div>
        )}
      </div>
    </div>
  );
};
