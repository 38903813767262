import { useEffect, useState, useRef, useMemo } from "react";
import styles from "./MgmNews.module.css";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_MGM_NEWS } from "../../features/api-wrapper/apiQueries";
import { useQuery } from "@apollo/client";
import moment from "moment";
import "moment/locale/es";
import { useLocation } from "react-router";
import Loader from "../global/Loader";
import APIError from "../../pages/ErrorHandling/APIError";
import { Button, Icon } from "@mgmresorts/mgm-ui";
import { useDetectOutsideClick } from "../../pages/agentsearch/useDetectOutsideClick";
import { useDispatch } from "react-redux";
import { navigationActions } from "../../data/navigation-slice";
import { useSelector } from "react-redux";
import MgmNewsMessage from "./MgmNewsMessage";
import { useSearchParams, useNavigationType } from "react-router-dom";

const MgmNews = (props) => {
  //console.log("MgmNews >>",props.data);
  // debugger; // eslint-disable-line no-debugger
  const { state } = useLocation();
  const [openPageIndex, setOpenPageIndex] = useState(-1);
  const [newsAll, setnewsAll] = useState([]);
  const [newsData, setnewsData] = useState([]);
  const [newsLatest, setnewsLatest] = useState();
  const [selectedValue, setSelectedValue] = useState();
  const [selectedId, setSelectedId] = useState(0);
  const [dataAdded, setDataAdded] = useState(false);
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigationType = useNavigationType();

  const language = useSelector(
    (state) => state.gqlData?.profile?.preferredLanguage
  );

  if (language) {
    moment.locale(language);
  }

  const { loading, error, data } = useQuery(GET_MGM_NEWS, {
    client: apiMiddlewareClient,
  });

  const moveLeft = () => {
    let index = openPageIndex === 0 ? newsData.length - 1 : openPageIndex - 1;
    let numIndex = Number(index);
    setOpenPageIndex(numIndex);
    updateURL(numIndex, true);
  };
  const moveRight = () => {
    let index = openPageIndex === newsData.length - 1 ? 0 : openPageIndex + 1;
    let numIndex = Number(index);
    setOpenPageIndex(numIndex);
    updateURL(numIndex, true);
  };
  const loadLandingPage = () => {
    setOpenPageIndex(-1);
    updateURL(-1);
  };
  const readMoreHandler = (index) => {
    dispatch(navigationActions.setBreadcrumbForceEnable(true));
    let numIndex = Number(index);
    setOpenPageIndex(numIndex);
    updateURL(numIndex);
  };

  useEffect(() => {
    const messageId = searchParams.get("news");
    if (messageId) readMoreHandler(messageId);
  }, [searchParams]);

  const updateURL = (id, skipNavType) => {
    setTimeout(() => {
      if (navigationType == "PUSH" || skipNavType) {
        const url = new URL(window.location.href);
        if (id == -1) {
          url.searchParams.delete("news");
          window.history.pushState(null, "", url.toString());
        } else {
          url.searchParams.set("news", id);
          window.history.pushState(null, "", url.toString());
        }
      }
    }, 100);
  };

  const openSortDD = () => {
    setIsActive(!isActive);
  };
  const setSortOption = (selection) => {
    setIsActive(false);
    setSelectedValue(selection);
    updateData(selection);
  };
  const updateData = (sortBy) => {
    if (sortBy == apiWidgetValues.newest) {
      let arr = [];
      let l = data.mgmNews.length;
      for (let i = 1; i < l; i++) {
        data.mgmNews[i] && arr.push(data.mgmNews[i]);
      }
      setnewsAll(arr);
    } else {
      let arr = [];
      let l = data.mgmNews.length;
      for (let i = 0; i < l - 1; i++) {
        data.mgmNews[l - i - 1] && arr.push(data.mgmNews[l - i - 1]);
      }
      setnewsAll(arr);
    }
  };

  useEffect(() => {
    if (data && data.mgmNews && data.mgmNews.length) {
      setnewsAll(data.mgmNews.filter((d, i) => i != 0));
      setnewsData(data.mgmNews);
      setnewsLatest(data.mgmNews[0]);
    }
    if (state) {
      if (state.isExternalNews) {
        setnewsData((prev) => {
          let news = [...prev];
          news.push(state.news);
          return news;
        });
        setDataAdded(true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (dataAdded) {
      readMoreHandler(newsData.length - 1);
    }
  }, [dataAdded]);

  useEffect(() => {
    if (state) {
      setOpenPageIndex(
        state?.selectedIndex ? parseInt(state?.selectedIndex) : -1
      );
    }
  }, [state]);

  const getApiWidgetValues = (array) => {
    let obj = {};
    for (let i = 0; i < array.length; i++) {
      obj[array[i].name] = array[i].value;
      /*
      if (array[i].hash) obj[array[i].name + "_hash"] = array[i].hash;
      if (array[i].$?.value["data-cslp"])
        obj[array[i].name + "_hash"] = array[i].$?.value["data-cslp"];
      */
    }
    return obj;
  };

  const apiWidgetValues = useMemo(
    () => getApiWidgetValues(props.data.dictionary),
    [props.data]
  );

  useEffect(() => {
    let value =
      selectedId === 0 ? apiWidgetValues.newest : apiWidgetValues.oldest;
    setSelectedValue(value);
  }, [apiWidgetValues]);

  useEffect(() => {
    if (selectedValue === apiWidgetValues.newest) setSelectedId(0);
    else setSelectedId(1);
  }, [selectedValue]);

  if (loading) return <Loader />;
  if (error) return <APIError error={error} />;

  return (
    <div className={styles.mgmNewsContainer}>
      {openPageIndex === -1 && newsLatest && newsAll.length > 0 && (
        <div className={styles.mgmNews}>
          <div style={{ display: "flex" }}>
            <div className={styles.mgmNewsLatest}>
              <div
                style={{
                  background: `url("${
                    newsLatest?.newsContentType === "video"
                      ? newsLatest.videos[0].videoPreviewImageURL
                      : newsLatest.images[0].imageURL
                  }")`,
                }}
                className={styles.imgHolder}
              ></div>
              <div className={styles.content}>
                <p className={styles.date}>
                  {/* {
                    globalProperties?.short_month[
                      new Date(newsLatest.newsPublishedDate).getMonth()
                    ]
                  }{" "} */}
                  {moment
                    .utc(newsLatest.newsPublishedDate)
                    .format("MMM D, YYYY")}
                </p>
                <h2>{newsLatest.newsTitle}</h2>
                <div className={styles.desc}>
                  <p>{newsLatest.newsSummary}</p>
                </div>
                <Button
                  size="small"
                  variant="secondary"
                  className={styles.readMoreButton}
                  onClick={() => {
                    readMoreHandler(0);
                  }}
                >
                  {apiWidgetValues.read_more}
                </Button>
              </div>
            </div>
          </div>
          <hr />

          <div className={styles.filterMgmNews}>
            <div
              ref={dropdownRef}
              className="menu-container"
              style={{ maxWidth: "176px" }}
            >
              <button onClick={openSortDD} className="menu-trigger">
                <span style={{ width: "100%" }}>
                  {apiWidgetValues.sort_by}: {selectedValue}
                </span>
                <Icon name="chevron-down" size="small" />
              </button>
              <nav className={`menu ${isActive ? "active" : "inactive"}`}>
                <ul>
                  <li onClick={() => setSortOption(apiWidgetValues.newest)}>
                    {apiWidgetValues.newest}
                  </li>
                  <li onClick={() => setSortOption(apiWidgetValues.oldest)}>
                    {apiWidgetValues.oldest}
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          {newsAll.map((d, index) => {
            return (
              <div key={index} className={styles.mgmNewsDetail}>
                <div
                  style={{
                    background: `url("${
                      d?.newsContentType === "video"
                        ? d.videos[0].videoPreviewImageURL
                        : d.images[0]?.imageURL
                    }")`,
                  }}
                  className={styles.imgHolderDetail}
                ></div>
                <div className={styles.content}>
                  <p className={styles.date}>
                    {/* {
                      globalProperties?.short_month[
                        new Date(d.newsPublishedDate).getMonth()
                      ]
                    }{" "} */}
                    {moment.utc(d.newsPublishedDate).format("MMM D, YYYY")}
                  </p>
                  <h2>{d.newsTitle}</h2>
                  <div className={styles.desc}>
                    <p>{d.newsSummary}</p>
                  </div>
                  <Button
                    size="small"
                    variant="secondary"
                    className={styles.readMoreButton}
                    onClick={() => {
                      readMoreHandler(index + 1);
                    }}
                  >
                    {apiWidgetValues.read_more}
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {openPageIndex != -1 && newsData.length > 0 && (
        <MgmNewsMessage
          selectedIndex={openPageIndex}
          relatedMgmNewsLabel={apiWidgetValues.related_mgm_news}
          moveLeft={moveLeft}
          moveRight={moveRight}
          loadLandingPage={loadLandingPage}
          data={newsData[openPageIndex]}
        />
      )}
    </div>
  );
};

export default MgmNews;