import "../../styles/attendance.css";
const LeaveRequest = (props) => {
  console.log(props.attData);
  const link =
    props.attData?.data?.links_dictionary &&
    props.attData?.data?.links_dictionary[0]?.link;

  const getDictionaryItems = (array) => {
    let obj = {};
    for (let i = 0; i < array.length; i++) {
      obj[array[i].name] = array[i].value || array[i].html_value;
    }
    return obj;
  };

  const dictionaryItems = getDictionaryItems(props.attData?.data?.dictionary);

  return (
    <section className={props.leaveRequest}>
      <div className="col-12 row global_nopadding loa-request">
        <div className="timeoff_header_loa global_headercontainer_tf bottomBorder">
          {dictionaryItems?.leave_requests}
        </div>
        <div className="timeoff_statussubheader_tf  c-both">
          {dictionaryItems?.check_the_status_of_your_leave_requests}
        </div>
        <a
          className="timeoff_ptolink_loa"
          target="_blank"
          rel="noreferrer"
          href={link?.href}
        >
          {link?.title}
        </a>
        <p className="attendance-history-loa" />
      </div>
    </section>
  );
};

export default LeaveRequest;
