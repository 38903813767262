import { useSelector } from "react-redux";
import styles from "./TrendingTopicsGroup.module.css";
import TrendingTopic from "./TrendingTopic";


const TrendingTopicsGroup = (props) => {

  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );

  return (
    <>
      <div
        // className={`${styles.cardContainerInner1} ${styles[cardContainerWidth]}`}
        className={styles.cardContainerInner}
      >
        <div>
          <h3 className={`${styles.cardHeader} ${"col-8 text-left"}`}>
            {props.data.heading}
          </h3>
          {!props.isMobile && (
            <div className={`${styles.hideHolder} ${"col-4 text-right"}`}>
              <button onClick={() => props.setToggle()}>
                <span className={styles.textUnderline}>
                  {globalProperties?.hide}
                </span>
                &nbsp;
                <span className={styles.eyeHolder}></span>
              </button>
            </div>
          )}
        </div>
        <div className={props.isMobile ? styles.mobileContainer : ""}>
          {props.data?.trendingTopics?.map((item, index) => {
            return <TrendingTopic data={item} key={index} />;
          })}
        </div>
      </div>
    </>
  );
};

export default TrendingTopicsGroup;
