import styles from "./FooterLogin.module.css";
import logo from "../../assets/images/mgm_resorts_international.png";

const FooterLogin = () => {
  return (
    <footer>
      <div className={styles.loginFooter}>
        <div className={styles.logoBox}>
          <img src={logo} alt="MyMGM Resorts" />
          <p>© 2023 - MGM Resorts - All Rights Reserved</p>
        </div>
      </div>
    </footer>
  );
};

export default FooterLogin;
