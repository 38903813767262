import styles from "./ErrorCard.module.css";

const ErrorCardRestricted = (props) => {
  return (
    <div className={styles.container}>
      {props.image && <img src={props.image} />}
      <h2>{props.title}</h2>
      <p>{props.message}</p>
      
    </div>
  );
};

export default ErrorCardRestricted;
