import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomCarousal from "../../components/common/carousal/CustomCarousal";
import Mgmoffer from "../../components/common/mgmoffer/Mgmoffer";
import WithFetchData from "../../hoc/WithFetchData";
import style from "../../styles/HelpInfo.module.css";
import Cases from "./Cases";
import dashboard from "./Dashboard.module.css";
import HelpCenter from "./HelpCenter";
import MgmNews from "./MgmNews";
import MyTasks from "./MyTasks";
import MyTimesheet from "./MyTimesheet";
import QuickActions from "./QuickActions";
import SelfService from "./SelfService";
import UpdatesandEvents from "./UpdatesandEvents";
import Greetings from "./Greetings";
import MyTaskStayUptoDate from "./MyTaskStayUptoDate";

const dbObj = {
  greetings: null,
  mgmOffer: null,
  mgmNews: null,
  carousel: null,
  hrCases: null,
  itCases: null,
  quickActions: null,
  timeSheet: null,
  myTasks: null,
  callout: [],
  selfService: null,
  updateEvents: null,
  promoCard: null,
};

const Dashboard = (props) => {
  const [dashboardData, setDashboardData] = useState(dbObj);
  const gqlData = useSelector((state) => state.gqlData);
  const currHour = new Date().getHours();
  const [isError, setIsError] = useState(true);
  console.log("isError is", isError);

  useEffect(() => {
    if (props.data) {
      let dashboardObj = dbObj;
      props.data.map((page, index) => {
        switch (page.key) {
          case "carousel":
            dashboardObj = {
              ...dashboardObj,
              carousel: props.data[index].data,
            };
            break;
          case "api_widget":
            if (page.type == "greetings") {
              const welcomeData = props.data[index].data;
              const welcomeMsg =
                currHour < 12
                  ? welcomeData.dictionary[0].value
                  : currHour < 18
                  ? welcomeData.dictionary[2].value
                  : welcomeData.dictionary[3].value;
              dashboardObj = { ...dashboardObj, greetings: welcomeMsg };
            } else if (page.type == "hr_cases") {
              dashboardObj = {
                ...dashboardObj,
                hrCases: props.data[index].data,
              };
            } else if (page.type == "it_cases") {
              dashboardObj = {
                ...dashboardObj,
                itCases: props.data[index].data,
              };
            } else if (page.type == "news_events") {
              dashboardObj = {
                ...dashboardObj,
                mgmNews: props.data[index].data,
              };
            } else if (page.type == "time_sheet") {
              dashboardObj = {
                ...dashboardObj,
                timeSheet: props.data[index].data,
              };
            } else if (page.type == "my_tasks") {
              dashboardObj = {
                ...dashboardObj,
                myTasks: props.data[index].data,
              };
            } else if (page.type == "property_updates") {
              dashboardObj = {
                ...dashboardObj,
                updateEvents: props.data[index].data,
              };
            }
            break;
          case "offers":
            dashboardObj = {
              ...dashboardObj,
              mgmOffer: props.data[index].data,
            };
            break;
          case "quick_actions":
            dashboardObj = {
              ...dashboardObj,
              quickActions: props.data[index].data,
            };
            break;
          case "promo_card":
            dashboardObj = { ...dashboardObj, promoCard: props.data[index] };
            break;
          case "action_panel":
            dashboardObj = {
              ...dashboardObj,
              selfService: props.data[index].data,
            };
            break;
          case "callout":
            dashboardObj = {
              ...dashboardObj,
              callout: [...dashboardObj.callout, props.data[index]],
            };
            break;
        }
      });
      setDashboardData(dashboardObj);
    }
  }, [props.data]);

  const handleChild = (child) => {
    setIsError(child);
  };
  return (
    <>
      <div className={style.bufferSide}>
        {dashboardData.greetings && (
          <Greetings message={dashboardData.greetings} gqlData={gqlData} />
        )}
        {dashboardData.hrCases && dashboardData.itCases && (
          <Cases
            hrCases={dashboardData.hrCases}
            itCases={dashboardData.itCases}
          />
        )}
        {dashboardData.carousel && (
          <CustomCarousal
            carouselData={dashboardData.carousel}
          ></CustomCarousal>
        )}
        {dashboardData.quickActions && (
          <QuickActions quickActionData={dashboardData.quickActions} />
        )}
        <div className="col-12 mt-4">
          <div className={dashboard.timesheetcontainer}>
            {gqlData.profile &&
            dashboardData.updateEvents &&
            gqlData.profile.payRateGroup !== "Hourly" ? (
              <UpdatesandEvents data={dashboardData.updateEvents} />
            ) : gqlData.profile && gqlData.profile.payRateGroup === "Salary" ? (
              dashboardData.myTasks && (
                <>
                  <MyTasks myTasksData={dashboardData.myTasks} />
                  <MyTaskStayUptoDate />
                </>
              )
            ) : (
              gqlData.profile &&
              dashboardData.timeSheet &&
              gqlData.profile.payRateGroup == "Hourly" && (
                <MyTimesheet timesheetData={dashboardData.timeSheet} />
              )
            )}
            {dashboardData.selfService && (
              <SelfService selfServiceData={dashboardData.selfService} />
            )}
          </div>
        </div>
      </div>
      {dashboardData.mgmOffer && (
        <Mgmoffer offerData={dashboardData.mgmOffer}></Mgmoffer>
      )}
      <div className={style.bufferSide}>
        <div className="col-12">
          <div className={`${dashboard.newscontainer}`}>
            {dashboardData.mgmNews && (
              <MgmNews
                mgmNewsData={dashboardData.mgmNews}
                salaried={gqlData.profile && gqlData.profile.payRateGroup}
                isError={handleChild}
              />
            )}
          </div>
        </div>

        <div className={style.helpInfoContainer}>
          {dashboardData.callout && (
            <HelpCenter helpData={dashboardData.callout} />
          )}
        </div>
      </div>
    </>
  );
};

export default WithFetchData(Dashboard);
