import useFetchData from "../hooks/useFetchData";
import ErrorHanding from "../pages/ErrorHandling/ErrorHanding";
// import Loader from "../components/global/Loader";

const WithFetchFormData = (EmptyComponent) => {
  function PackedComponent(props) {
    //console.log("HIGHER ORDER COMP!! ");
    const { loading, error, data } = useFetchData(props.formURL, "form");
    if (loading) return "" ;
    if (error) return <ErrorHanding error={error} />;
    return <EmptyComponent data={data} {...props} />;
  }
  return PackedComponent;
};
export default WithFetchFormData;
