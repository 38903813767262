import styles from "./HeroBanner.module.css";

const HeroBanner = (props) => {
  //console.log(props.data);
  return (
    <div className={styles.heroBanner}>
      <div className={styles.imgHolder}>
        <div className={styles.imgOverlay}></div>
        <img data-cslp={props.data?.$?.image} src={props.data.image} />
      </div>
      <div className={styles.heading}>
        {props.data?.eyebrow && <p data-cslp={props.data?.$?.eyebrow}>{props.data.eyebrow}</p>}
        <h2 data-cslp={props.data?.$?.heading}>{props.data.heading}</h2>
      </div>
    </div>
  );
};

export default HeroBanner;
