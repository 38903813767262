import React, { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import { apiMiddlewareClient } from "../../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_TIMESHEET_MULTIPLE_LOCATIONS } from "../../../features/api-wrapper/apiQueries";
import Loader from "../../../components/global/Loader";

import { Tab } from "@mgmresorts/mgm-ui";
import { CardContainer } from "@mgmresorts/mgm-ui";
import LocationSelectDropdown from "./LocationSelectDropdown";

import styles from "./LocationSelectionTimesheet.module.css";
import APIError from "../../ErrorHandling/APIError";
import useWindowDimensions from "../../../hooks/useScreenDetails";
import { useSelector } from "react-redux";

const LocationSelectionTimesheet = (props) => {
  const { loading, error, data } = useQuery(GET_TIMESHEET_MULTIPLE_LOCATIONS, {
    client: apiMiddlewareClient,
    fetchPolicy: "cache-first",
  });
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );
  const [isMobile] = useWindowDimensions();
  const [selectedLocationTab, setSelectedLocationTab] = useState(0);
  const [currentLoc, setCurrentLoc] = useState("");

  if (data && data.timesheetMultipleLocations?.length === 0)
    return <p>{globalProperties?.something_went_wrong}</p>;

  const boolMultiProp = props?.multiProp?.isMultiplePropertyEmployee;

  const allLocations = data?.timesheetMultipleLocations;

  useEffect(() => {
    if (
      data?.timesheetMultipleLocations &&
      data?.timesheetMultipleLocations.length > 0
    ) {
      setCurrentLoc(allLocations[0].code);
    }
  }, [data?.timesheetMultipleLocations]);

  const handleTabClick = (i) => {
    setSelectedLocationTab(i);
    setCurrentLoc(allLocations[i].code);
  };

  const handleSelectClick = (eve) => {
    const selectedIndex = allLocations.findIndex(
      (val) => val.Company === eve.target.value
    );
    setSelectedLocationTab(selectedIndex);
    setCurrentLoc(allLocations[selectedIndex].code);
  };

  if (loading) return <Loader />;
  if (error) return <APIError error={error} />;

  return (
    <>
      {boolMultiProp && (
        <div className={styles.tabHolder}>
          {!isMobile &&
            allLocations &&
            allLocations.map((loc, i) => {
              return (
                <React.Fragment key={i}>
                  <Tab
                    label={loc.Company.slice(0, -5)}
                    key={i}
                    className={`${styles.tabItem} ${
                      selectedLocationTab === i ? styles.active : ""
                    }`}
                    onClick={() => handleTabClick(i)}
                  />
                </React.Fragment>
              );
            })}
          {isMobile && allLocations && (
            <section className={styles.locationDropdown}>
              <select onChange={(e) => handleSelectClick(e)}>
                {allLocations.map((loc, index) => (
                  <option
                    key={index}
                    selected={location == loc.Company ? true : false}
                    value={loc.Company}
                  >
                    {loc.Company}
                  </option>
                ))}
              </select>
            </section>
          )}
        </div>
      )}
      <CardContainer style={{ borderRadius: "8px", margin: "0", padding: "0" }}>
        <div className={styles.cardContainerInner}>
          <LocationSelectDropdown
            staticData={props.staticData}
            currentLoc={currentLoc}
            viewAllTimecards={props.viewAllTimecards}
            selectedDropdownState={props.selectedDropdownState}
          />
        </div>
      </CardContainer>
    </>
  );
};

export default LocationSelectionTimesheet;
