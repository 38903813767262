import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_TIMEOFFBALANCE_DETAILS } from "../../features/api-wrapper/apiQueries";
import { useQuery } from "@apollo/client";
//import moment from 'moment';
import { useSelector } from "react-redux";
import APIError from "../ErrorHandling/APIError";
import Loader from "../../components/global/Loader";

const CurrentTimeoff = (props) => {
  const gqlData = useSelector((state) => state.gqlData);

  const ftoData = useSelector((state) => state.gqlFTOData);

  const { loading, error, data } = useQuery(GET_TIMEOFFBALANCE_DETAILS, {
    client: apiMiddlewareClient,
  });
  if (loading) return <Loader />;
  if (error) return <APIError error={error} />;
  props.timeOffData(data.timeOffLeaveBalance);
  const timeOffData = data.timeOffLeaveBalance.filter(
    (item) => item.locationDescription === props.location
  );

  const hashKey = "data-cslp";
  const time_lefttitle =
    props?.attdata?.data?.dictionaryLables?.current_time_off;
  const time_lefttitle_hash =
    props?.attdata?.data?.dictionaryLables?.current_time_off_hash;

  const time_date = props?.attdata?.data?.dictionaryLables?.balance;
  const time_date_hash = props?.attdata?.data?.dictionaryLables?.balance_hash;

  const time_available = props?.attdata?.data?.dictionaryLables?.available;
  const time_available_hash =
    props?.attdata?.data?.dictionaryLables?.available_hash;

  const time_discription =
    props?.attdata?.data?.dictionaryLables?.requests_submitted_with_x_period;
  const time_discription_hash =
    props?.attdata?.data?.dictionaryLables
      ?.requests_submitted_with_x_period_hash;

  const no_timeoff_balance =
    props?.attdata?.data?.dictionaryLables?.no_timeoff_to_display_yet;
  const no_timeoff_balance_hash =
    props?.attdata?.data?.dictionaryLables?.no_timeoff_to_display_yet_hash;

  const getFormattedDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();

    return `${month}/${day}/${year}`;
  };
  const formattedDate = getFormattedDate();

  return gqlData.profile && gqlData.profile.isFTOEligible ? (
    <section className={props.currentTimeoff}>
      <div className="col-12 padding-left-zero timeoff_leftsection_loa">
        <p className="timeoff_header_attendance">
          {props?.attdataHis?.data?.dictionaryLables?.time_off_history_to_date}
        </p>
        <div>
          <div className="timeoff_attendance">
            <p className="timeoff_count">
              {parseInt(ftoData.PastSixMonthFTOCount.units).toFixed(1)}
            </p>
            <p className="timeoff_days">
              {props?.attdataHis?.data?.dictionaryLables?.days_taken}
            </p>
          </div>
        </div>
        <p className="attendance-border"></p>
        <p className="timeoff_disclaimer">
          {props?.attdataHis?.data?.dictionaryLables?.your_fto_policy_allows}
        </p>
        <a
          className="timeoff_ptolink"
          href={props.attdataHis.data.links_dictionary[0].link.href}
          target="_blank"
          rel="noreferrer"
        >
          {props.attdataHis.data.links_dictionary[0].link.title}
        </a>
      </div>
    </section>
  ) : props.isMultiplePropertyEmployee ? (
    <section className={props.currentTimeoff}>
      <div className="col-12 padding-left-zero timeoff_leftsection_loa">
        <p className="timeoff_header_new" data-cslp={time_lefttitle_hash}>
          {time_lefttitle}
        </p>
        {timeOffData.length === 0 ? (
          <p className="no_timeoff" data-cslp={no_timeoff_balance_hash}>
            {no_timeoff_balance}
          </p>
        ) : (
          <p className="timeoff-balance" data-cslp={time_date_hash}>
            {time_date} {formattedDate}
          </p>
        )}
        {timeOffData.length === 0
          ? " "
          : timeOffData.map((val, i) => {
              return (
                <div key={i}>
                  <div className="global_justifyspacebetween_new">
                    <div className="timeoff_statusheader_new">
                      {val.timeOffPlanName}
                    </div>
                    <div className="timeoff-balance-values">
                      <div className="timeoff-available">
                        {val.timeOffBalance}{" "}
                        <span
                          className="timeoff-available-label"
                          data-cslp={time_available_hash}
                        >
                          {time_available}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

        <div className="timeoff_line"></div>
        <p className="timeoff_disclaimer" data-cslp={time_discription_hash}>
          {time_discription}
        </p>
        <a
          className="timeoff_ptolink"
          href={props.attdata.data.links_dictionary[0].link.href}
          target="_blank"
          rel="noreferrer"
          data-cslp={
            props.attdata.data.links_dictionary[0]?.link?.$?.title[hashKey]
          }
        >
          {props.attdata.data.links_dictionary[0].link.title}
        </a>
      </div>
    </section>
  ) : (
    <section className={props.currentTimeoff}>
      <div className="col-12 padding-left-zero timeoff_leftsection_loa">
        <p className="timeoff_header_new" data-cslp={time_lefttitle_hash}>
          {time_lefttitle}
        </p>
        {data.timeOffLeaveBalance.length === 0 ? (
          <p className="no_timeoff" data-cslp={no_timeoff_balance_hash}>
            {no_timeoff_balance}
          </p>
        ) : (
          <p className="timeoff-balance" data-cslp={time_date_hash}>
            {time_date} {formattedDate}
          </p>
        )}
        {data.timeOffLeaveBalance.length === 0
          ? " "
          : data.timeOffLeaveBalance.map((val, i) => {
              // const StartDate = moment(val.balancePeriodStartDate).format(
              //   "MM/DD/YYYY"
              // );
              // const EndDate = moment(val.balancePeriodEndDate).format(
              //   "MM/DD/YYYY"
              // );
              return (
                <div key={i}>
                  <div className="global_justifyspacebetween_new">
                    <div className="timeoff_statusheader_new">
                      {val.timeOffPlanName}
                      {/* <div className="timeoff_statussubheader_new pb-2">
                        {StartDate} - {EndDate}
                      </div> */}
                    </div>
                    <div className="timeoff-balance-values">
                      <div className="timeoff-available">
                        {val.timeOffBalance}{" "}
                        <span
                          className="timeoff-available-label"
                          data-cslp={time_available_hash}
                        >
                          {time_available}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

        <div className="timeoff_line"></div>
        <p className="timeoff_disclaimer" data-cslp={time_discription_hash}>
          {time_discription}
        </p>
        <a
          className="timeoff_ptolink"
          href={props.attdata.data.links_dictionary[0].link?.href}
          target="_blank"
          rel="noreferrer"
          data-cslp={
            props.attdata.data.links_dictionary[0]?.link?.$?.title[hashKey]
          }
        >
          {props.attdata.data.links_dictionary[0].link?.title}
        </a>
      </div>
    </section>
  );
};

export default CurrentTimeoff;
