import styles from "./Quote.module.css";
import { Icon } from "@mgmresorts/mgm-ui";
import ChooseCta from "../../hooks/ChooseCta";
import InnerHtml from "../dynamic/InnerHtml";

const Quote = (props) => {
  //console.log("Quote >>", props.data);
  return (
    <section className={styles.quoteHolder}>
      {props.data?.icon && (
        <div className={styles.iconHolder}>
          <Icon
            name={props.data?.icon}
            variant="outlined"
            size="large"
            role="img"
            data-cslp={props.data?.$?.icon}
          />
        </div>
      )}
      {props.data?.description && (
        <InnerHtml
          description={props.data?.description}
          style={styles.innerHtml}
          hash={props.data?.$?.description}
        />
      )}
      {props.data?.author && (
        <div className={styles.author} data-cslp={props.data?.$?.author}>{props.data.author}</div>
      )}
      {props.data?.ctaEnabled && (
        <div className={styles.buttonHolder}>
          <ChooseCta data={props.data?.cta} size="large" type="secondary" />
        </div>
      )}
    </section>
  );
};

export default Quote;
