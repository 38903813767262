import { useState, useEffect } from "react";
import { TextInput, CenterModal, Radio } from "@mgmresorts/mgm-ui";
import Licenseplate from "./LicensePlate";
import "../../styles/profile.css";
import Autocomplete from "react-google-autocomplete";
import useScrollBlock from "../../hooks/useScrollBlock";
import ConfirmationPopup from "./ConfirmPrompt";
import  useWindowDimensions  from "../../hooks/useScreenDetails";

const EditProfile = (props) => {
  const [isMobile] = useWindowDimensions();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isContinue, setIsContinue] = useState(false);
  const [fieldEdited, setFieldEdited] = useState(false);
  const yesText = props.profData[2].data.dictionaryLables?.yes;
  const noText = props.profData[2].data.dictionaryLables?.no;
  const userLocation = props.apiData?.profile?.userLocation;

  const options = [
    {
      name: yesText,
      value: yesText,
    },
    {
      name: noText,
      value: noText,
    },
  ];
  console.log("editProfile props", props)
  useEffect(() => {
    if (isContinue) {
      props.setModal(false);
      setIsModalOpen(false);
      setIsContinue(false);
      document.body.style.overflow = "visible";
      // window.open(props.data?.link, "_blank");
    }
  }, [isContinue]);
  const [addrSelected, setAddrSelected] = useState(false);
  const [isStreet, setIsStreet] = useState(false);
  const [isAutoOn, setIsAutoOn] = useState(false);
  const[zIndex,setZindex] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();
  const handleRadio = (input) => setAddrSelected(input);
  const [valueSelected, setValueSelected] = useState(options[1].name);

  const [address, setAddress] = useState({
    addrLine1: props.apiData.personalInformation.addressLine1,
    addrLine2: props.apiData.personalInformation.addressLine2,
    city: props.apiData.personalInformation.city,
    state: props.apiData.personalInformation.state,
    postalCode: props.apiData.personalInformation.postalCode,
  });
  useEffect(() => {
    if (address.addrLine2) {
      setValueSelected(options[0].name);
    }
  }, []);
  const [isPostalCd, setIsPostalCd] = useState(false);
  const [fieldError, setFieldErrors] = useState({
    preferredFirstName: { message: "", isError: false },
    preferredLastName: { message: "", isError: false },
    phoneNumber: {
      message: "",
      isError: false,
    },
    primaryEmailAddress: { message: "", isError: false },
    secondaryEmailAddress: { message: "", isError: false },
    postalCode: { message: "", isError: false },
    addressLine1: { message: "", isError: false },
    addressLine2: { message: "", isError: false },
    radioSelected: { message: "", isError: false },
    addressNotSelected: { message: "", isError: false },
  });
  const country = "us";

  

  const closeModal = (btnName, e) => {
    if (btnName == "save") {
      props.setModal(false);
      document.body.style.overflow = "visible";
    }
    if (
      (btnName == "close" && e.currentTarget.tagName.toLowerCase() == "button")
    ){
      
      if (fieldEdited) {
        
        if(isMobile){
          setZindex(true);
        }
       setIsModalOpen(true);
        document.body.style.overflow = "visible";
      }
      else {
        setZindex(false);
        props.setModal(false);
        document.body.style.overflow = "visible";
      }
    }

  };

  const handleError = (childData) => {
    setFieldErrors(childData);
  };
  const [isCityBlank, setIsCityBlank] = useState(false);
  const subText = props.profData[2].data.dictionaryLables?.manage_description;
  const title = props.profData[2].data.dictionaryLables?.edit_personal_info;
  const fullName = props.profData[2].data.dictionaryLables?.full_name;
  const prefName = props.profData[2].data.dictionaryLables?.preferred_name;
  const prefLastName = props.profData[2].data.dictionaryLables?.preferred_last_name;
  const personalEmail = props.profData[2].data.dictionaryLables?.personal_email;
  const secondaryEmail =
    props.profData[2].data.dictionaryLables?.personal_secondary_email;
  const mobPhone = props.profData[2].data.dictionaryLables?.mobile_phone;
  const mailingAddr = props.profData[2].data.dictionaryLables?.mailing_address;
  const address_line_1 =
    props.profData[2].data.dictionaryLables?.address_line_1;
  const city = props.profData[2].data.dictionaryLables?.city;
  const postal_code = props.profData[2].data.dictionaryLables?.postal_code;
  const parking = props.profData[2].data.dictionaryLables?.parking;
  const parkingDescription =
    props.profData[2].data.dictionaryLables?.parking_description;
  const aptLabel =
    props.profData[2].data.dictionaryLables?.unit_apt_suite_number;
  const isAptQn =
    props.profData[2].data.dictionaryLables?.is_this_an_apt_suite_unit;
  const addLine1Error =
    props.profData[2].data.dictionaryLables?.enter_valid_address_line_1;
  const cityLineError =
    props.profData[2].data.dictionaryLables?.enter_valid_city;
  const postalLineError =
    props.profData[2].data.dictionaryLables?.enter_valid_postal_code;
  const validAddr =
    props.profData[2].data.dictionaryLables?.select_valid_address;

  const [editProfile, setEditProfile] = useState({
    phone: props.phoneNum,
    preffName: props.apiData.personalInformation.preferredFirstName,
    prefflastName: props.apiData.personalInformation.preferredLastName,
    email: props.apiData.personalInformation.personalEmail,
    secEmail: props.apiData.personalInformation.secondaryEmail,
    addrLine1: props.apiData.personalInformation.addressLine1,
    addrLine2: props.apiData.personalInformation.addressLine2,
    city: props.apiData.personalInformation.city,
    state: props.apiData.personalInformation.state,
    postalCode: props.apiData.personalInformation.postalCode,
  });
  
  const [placeSelected, setPlaceSelected] = useState(true);
  const [inputSelected, setInputSelected] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const onChangeValue = (event) => {
    setFieldEdited(true);
    let r = /(\D+)/g,
      npa = "",
      nxx = "",
      last4 = "";
    event = event.replace(r, "");
    npa = event.substr(0, 3);
    nxx = event.substr(3, 3);
    last4 = event.substr(6, 4);
    event = "(" + npa + ")" + " " + nxx + "-" + last4;
    setEditProfile({ ...editProfile, phone: event });
  };

  const handleSubmitClick = (childData) => {
    setSubmitClicked(childData);
  };
  const handleModal = ((input) => {
    console.log("entered handleModal", input);
    setIsModalOpen(input);
  })
  const handleLpEdit =((input)=>{
    setFieldEdited(input);
  })
  const onChangePrefName = (event) => {
    setFieldEdited(true);
    setEditProfile({ ...editProfile, preffName: event });
  };
  const onChangePrefLastName = (event) => {
    setFieldEdited(true);
    setEditProfile({ ...editProfile, prefflastName: event });
  };

  const onChangeEmail = (event) => {
    setFieldEdited(true);
    setEditProfile({ ...editProfile, email: event });
  };
  const onChangeSecEmail = (event) => {
    setFieldEdited(true);
    setEditProfile({ ...editProfile, secEmail: event });
  };
  const handleAptChange = (e) => {
    setFieldEdited(true);
    setValueSelected(e.target.value);

    setAddress({ ...address, addrLine2: "" });
  };
  const onChangeAddrLine1 = (event) => {
    setFieldEdited(true);
    setAddress({ ...address, addrLine1: event });
  };
  const handleInput = () => {
    setFieldEdited(true);
    setInputSelected(true);
    setPlaceSelected(true);
  };

  const onChangeAddrLine2 = (event) => {
    setFieldEdited(true);
    setAddress({ ...address, addrLine2: event });
  };
  const onChangeCity = (event) => {
    setFieldEdited(true);
    setAddress({ ...address, city: event });
  };
  // const onChangeState = (event) => {
  //   setEditProfile({ ...editProfile, state: event });
  // };
  const onChangePostalCode = (event) => {
    setFieldEdited(true);
    setAddress({ ...address, postalCode: event });
  };
  const onAddressAPIChange = (event) => {
    setFieldEdited(true);
    setPlaceSelected(false);
    // console.log("event is",event.target.value);
    // const seperatedArr = event.formatted_address.split(',');
    setValueSelected(options[1].name);
    setIsCityBlank(false);
    setIsPostalCd(false);
    setIsStreet(false);
    const street_code = event.address_components.filter(
      (i) => i.types[0] == "street_number"
    )[0]
      ? event.address_components.filter((i) => i.types[0] == "street_number")[0]
        .long_name
        ? event.address_components.filter(
          (i) => i.types[0] == "street_number"
        )[0].long_name
        : event.address_components.filter(
          (i) => i.types[0] == "street_number"
        )[0].short_name
      : "";
    const route = event.address_components.filter(
      (i) => i.types[0] == "route"
    )[0]
      ? event.address_components.filter((i) => i.types[0] == "route")[0]
        .long_name
        ? event.address_components.filter((i) => i.types[0] == "route")[0]
          .long_name
        : event.address_components.filter((i) => i.types[0] == "route")[0]
          .short_name
      : "";
    const addr_combined = street_code + " " + route;
    const city = event.address_components.filter(
      (i) => i.types[0] == "locality"
    )[0]
      ? event.address_components.filter((i) => i.types[0] == "locality")[0]
        .long_name
        ? event.address_components.filter((i) => i.types[0] == "locality")[0]
          .long_name
        : event.address_components.filter((i) => i.types[0] == "locality")[0]
          .short_name
      : "";

    const state = event.address_components.filter(
      (i) => i.types[0] == "administrative_area_level_1"
    )[0]?.short_name;
    const postal_code = event.address_components.filter(
      (i) => i.types[0] == "postal_code"
    )[0]
      ? event.address_components.filter((i) => i.types[0] == "postal_code")[0]
        .long_name
      : "";

    if (street_code == "" || route == "") {
      setIsStreet(true);
    }

    if ((postal_code == undefined || postal_code == "") && city == "") {
      setIsPostalCd(true);
      setIsCityBlank(true);

      setAddress({
        ...address,
        addrLine2: "",
        addrLine1: addr_combined?.trim(),
        city: "",
        state: state?.trim(),
        postalCode: "",
      });
    } else if (city == "") {
      setIsCityBlank(true);
      setAddress({
        ...address,
        addrLine2: "",
        addrLine1: addr_combined?.trim(),
        city: "",
        state: state?.trim(),
        postalCode: postal_code?.trim(),
      });
    } else if (postal_code == undefined || postal_code == "") {
      setIsPostalCd(true);
      setAddress({
        ...address,
        addrLine2: "",
        addrLine1: addr_combined?.trim(),
        city: city?.trim(),
        state: state?.trim(),
        postalCode: "",
      });
    } else {
      setAddress({
        ...address,
        addrLine2: "",
        addrLine1: addr_combined?.trim(),
        city: city?.trim(),
        state: state?.trim(),
        postalCode: postal_code?.trim(),
      });
    }
  };

  return (
    <div className={isMobile?(zIndex?"edit-popup":""):"edit-popup"}>
      <CenterModal
        open
        size="medium"
        onClose={(e) => {
          allowScroll();
          closeModal("close", e);
        }}
        className={isAutoOn ? `modal-mobile handle-scroll` : "modal-mobile"}
      >
        <div>

          <div className="modal-text">{title}</div>
        </div>
        <div className="col-12 row edit-scrollbar">
          <div
            className="edit-subheading1"
            dangerouslySetInnerHTML={{ __html: subText }}
          ></div>
          <div>
            <div className="col-12 row textbox-edit">
              <label className="google-text">{fullName}</label>
              <TextInput
                disabled
                // label={fullName}
                value={props.apiData.personalInformation.fullName}
              ></TextInput>
            </div>

            <div className="col-12 row textbox-edit">
              <label
                className={
                  fieldError.preferredFirstName?.isError
                    ? "google-text-error"
                    : "google-text"
                }
              >
                {prefName}
              </label>
              <TextInput
                error={fieldError.preferredFirstName?.isError}
                hint={fieldError.preferredFirstName?.message}
                onChange={(event) => onChangePrefName(event.target?.value)}
                // label={prefName}
                value={editProfile.preffName}
              ></TextInput>
            </div>

            <div className="col-12 row textbox-edit">
              <label
                className={
                  fieldError.preferredLastName?.isError
                    ? "google-text-error"
                    : "google-text"
                }
              >
                {prefLastName}
              </label>
              <TextInput
                error={fieldError.preferredLastName?.isError}
                hint={fieldError.preferredLastName?.message}
                onChange={(event) => onChangePrefLastName(event.target?.value)}
                // label={prefName}
                value={editProfile.prefflastName}
              ></TextInput>
            </div>

            <div className="col-12 row textbox-edit">
              <label
                className={
                  fieldError.primaryEmailAddress?.isError
                    ? "google-text-error"
                    : "google-text"
                }
              >
                {personalEmail}
              </label>
              <TextInput
                error={fieldError.primaryEmailAddress?.isError}
                hint={fieldError.primaryEmailAddress?.message}
                onChange={(event) => onChangeEmail(event.target?.value)}
                // label={personalEmail}
                value={editProfile.email}
              ></TextInput>
            </div>
            <div className="col-12 row textbox-edit">
              <label
                className={
                  fieldError.secondaryEmailAddress?.isError
                    ? "google-text-error"
                    : "google-text"
                }
              >
                {secondaryEmail}
              </label>
              <TextInput
                error={fieldError.secondaryEmailAddress?.isError}
                hint={fieldError.secondaryEmailAddress?.message}
                onChange={(event) => onChangeSecEmail(event.target?.value)}
                // label={secondaryEmail}
                value={editProfile.secEmail}
              ></TextInput>
            </div>
            <div className="col-12 row textbox-edit">
              <label
                className={
                  fieldError.phoneNumber?.isError
                    ? "google-text-error"
                    : "google-text"
                }
              >
                {mobPhone}
              </label>
              <TextInput
                error={fieldError.phoneNumber?.isError}
                hint={fieldError.phoneNumber?.message}
                // label={mobPhone}
                value={editProfile.phone}
                onChange={(event) => onChangeValue(event.target?.value)}
                variant="default"
              ></TextInput>
            </div>

            <div>
              {" "}
              <label
                className={
                  submitClicked && !!placeSelected
                    ? "google-text-error"
                    : "google-text"
                }
              >
                {mailingAddr}
              </label>
              <div>
                <Autocomplete
                  defaultValue={
                    address.addrLine1 +
                    " " +
                    address.city +
                    " " +
                    address.state +
                    " " +
                    address.postalCode
                  }
                  className={
                    submitClicked && !!placeSelected
                      ? "autocomplete-error"
                      : isAutoOn
                        ? "autocomplete-focus"
                        : "autocomplete"
                  }
                  options={{
                    types: ["geocode", "establishment"],
                    componentRestrictions: { country },
                  }}
                  onFocus={() => {
                    blockScroll();
                    setIsAutoOn(true);
                  }}
                  onBlur={() => {
                    allowScroll();
                    setIsAutoOn(false);
                  }}
                  // onChange={(place)=>handleInput(place)}
                  onPlaceSelected={(place) => {
                    setValueSelected(options[1].name);

                    onAddressAPIChange(place);
                  }}
                  onChange={(input) => handleInput(input)}
                  name={mailingAddr}
                />
              </div>
            </div>
            {submitClicked && !!placeSelected ? (
              <div className={"question-error"}> {validAddr}</div>
            ) : (
              ""
            )}
            {isStreet ? (
              <div>
                <div className="col-12 row textbox-edit">
                  <label
                    className={
                      fieldError.addressLine1?.isError || isStreet
                        ? "google-text-error"
                        : "google-text"
                    }
                  >
                    {address_line_1}
                  </label>
                  <TextInput
                    error={fieldError.addressLine1?.isError || isStreet}
                    hint={
                      fieldError.addressLine1?.isError
                        ? fieldError.addressLine1?.message
                        : addLine1Error
                    }
                    onChange={(event) => onChangeAddrLine1(event.target?.value)}
                    // label={address_line_1}
                    value={address.addrLine1}
                  />
                </div>
                {/* <div className={lineSelected?"google-label":"question-error"}> {addLine1Error}</div> */}
              </div>
            ) : (
              ""
            )}
            {isCityBlank ? (
              <div>
                <div className="col-4 row textbox-edit">
                  <label
                    className={
                      fieldError.city?.isError || isCityBlank
                        ? "google-text-error"
                        : "google-text"
                    }
                  >
                    {city}
                  </label>
                  <TextInput
                    error={fieldError.city?.isError || isCityBlank}
                    hint={
                      fieldError.city?.isError
                        ? fieldError.city?.message
                        : cityLineError
                    }
                    onChange={(event) => onChangeCity(event.target?.value)}
                    // label={city}
                    value={address.city}
                  ></TextInput>
                </div>
                {/* <div className={citySelected?"google-label":"question-error"}> {cityLineError}</div> */}
              </div>
            ) : (
              ""
            )}
            {isPostalCd ? (
              <div>
                <div className="col-4 row textbox-edit">
                  <label
                    className={
                      fieldError.postalCode?.isError || isPostalCd
                        ? "google-text-error"
                        : "google-text"
                    }
                  >
                    {postal_code}
                  </label>
                  <TextInput
                    type="number"
                    maxLength="5"
                    error={fieldError.postalCode?.isError || isPostalCd}
                    hint={
                      fieldError.postalCode?.isError
                        ? fieldError.postalCode?.message
                        : postalLineError
                    }
                    onChange={(event) => {
                      onChangePostalCode(event.currentTarget?.value);
                    }}
                    onKeyDown={(evt) =>
                      (evt.key === "e" || evt.key === "E" || evt.key === ".") &&
                      evt.preventDefault()
                    }
                    // label={postal_code}
                    value={address.postalCode}
                  ></TextInput>
                </div>
                {/* <div className={postalcdSelected?"google-label":"question-error"}> {postalLineError}</div>  */}
              </div>
            ) : (
              ""
            )}
            {
              <div>
                {" "}
                <div className="option-block">
                  <div
                    className={
                      valueSelected == options[0].name &&
                        addrSelected &&
                        address.addrLine2 == ""
                        ? "question-error"
                        : "google-label"
                    }
                  >
                    {isAptQn}{" "}
                  </div>
                  {options.map((item, index) => (
                    <div key={index} className="option-style">
                      <Radio
                        checked={valueSelected === item.value}
                        variant="small"
                        name={item.name}
                        value={item.name}
                        onChange={(e) => handleAptChange(e)}
                      />
                      <label htmlFor={item.name}>{item.name}</label>
                    </div>
                  ))}
                </div>
                {/* {valueSelected == "Yes" && addrSelected && address.addrLine2=="" && (
                    <div className="radio-required">This field is required</div>
                  )} */}
                {valueSelected === options[0].name && (
                  <div className="col-12 row textbox-edit">
                    <label
                      className={
                        fieldError.addressLine2?.isError
                          ? "google-text-error"
                          : "google-text"
                      }
                    >
                      {aptLabel}
                    </label>
                    <TextInput
                      onChange={(event) => onChangeAddrLine2(event.target?.value)}
                      error={fieldError.addressLine2?.isError}
                      hint={fieldError.addressLine2?.message}
                      // label={aptLabel}
                      value={address.addrLine2}
                    ></TextInput>
                  </div>
                )}
              </div>
            }
            {/* <div className="col-12 row textbox-edit"> */}
            {/* <TextInput
            error={fieldError.addressLine1?.isError}
            hint={fieldError.addressLine1?.message}
              onChange={(event) => onChangeAddrLine1(event.target?.value)}
             disabled
              label={address_line_1}
              value={editProfile.addrLine1}
            ></TextInput> */}
            {/* </div> */}
            {/* <div className="col-12 row textbox-edit">
            <TextInput
              onChange={(event) => onChangeAddrLine2(event.target?.value)}
              label={address_line_2}
              value={editProfile.addrLine2}
            ></TextInput>
          </div> */}
            {/* <div style={{ display: "flex", gap: "24px" }}>
          
            <div className="col-4 row textbox-edit">
              <TextInput
                onChange={(event) => onChangeCity(event.target?.value)}
                label={city}
                value={editProfile.city}
                disabled
              ></TextInput>
            </div>
            
            <div className="col-4 row textbox-edit">
              <TextInput
                onChange={(event) => onChangeState(event.target?.value)}
                label={state}
                value={editProfile.state}
                disabled
              ></TextInput>
            </div>
            <div className="col-4 row textbox-edit">
              <TextInput
                type="number"
                maxLength="5"
                error={fieldError.postalCode?.isError}
                hint={fieldError.postalCode?.message}
                onChange={(event) => {
                  onChangePostalCode(event.currentTarget?.value);
                }}
                onKeyDown={(evt) =>
                  (evt.key === "e" || evt.key === "E" || evt.key === ".") &&
                  evt.preventDefault()
                }
                disabled
                label={postal_code}
                value={editProfile.postalCode}
              ></TextInput>
            </div>
            
          </div> */}
           {userLocation != "" && (userLocation?.toLowerCase() == "las vegas, nv"||userLocation?.toLowerCase() == "vegas corporate")
            ? (
              <>
            <div>
              <div className="parking">{parking}</div>
              <div
                className="parkingDescription"
                dangerouslySetInnerHTML={{ __html: parkingDescription }}
              ></div>
            </div>
            </>
            ):""}

            <div className="paddingBottom">
              <Licenseplate
                profData={props.profData}
                apiData={props.apiData}
                setIsModalOpen={handleModal}
                handleLpEdit={handleLpEdit}
                editProfile={editProfile}
                handleRadio={handleRadio}
                onClose={() => closeModal("save")}
                fieldError={handleError}
                address={address}
                valueSelected={valueSelected}
                fieldErrors={fieldError}
                inputSelected={inputSelected}
                placeSelected={placeSelected}
                handleSnackbar={props.handleSnackbar}
                workEmail={props.workEmail}
                yesText={yesText}
                handleSubmitClick={handleSubmitClick}
              />
            </div>
            
          </div>
        </div>
      </CenterModal>
      {isModalOpen && (
        <ConfirmationPopup
          isModalOpen={isModalOpen}
          onContinue={setIsContinue}

          fieldEdited={fieldEdited}
          onClose={() => {
            setIsModalOpen(false);
            setZindex(false);
          }}
        />
      )}
    </div>
  );
};
export default EditProfile;
