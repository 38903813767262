import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Dropdown, DropdownItem } from "@mgmresorts/mgm-ui";
import React from "react";
import { useState } from "react";
import Filter from "./Filter";
import style from "./SearchResults.module.css";
import axios from "axios";

const SearchResults = (props) => {
  useEffect(() => {
    props.SearchResultPage(true);
  }, []);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let searchQuery = params.get("keyword");
  console.log("searchParams>>>", params.get("keyword"));

  const [toggleSortByDD, settoggleSortByDD] = useState(false);
  const [toggleFilterByDD, settoggleFilterByDD] = useState(false);
  const iconPath = "../../../../search/pdfIcon.svg";
  const slackedLinePath = "../../../../search/stacked-line-filter.jpg";
  const oneCircePath = "../../../../search/onecircle.jpg";
  const [isMobile, setIsMobile] = useState(false);
  let Buffer = require("buffer/").Buffer;

  const [searchResultData,setSearchResultData] = useState([{}])

  let getSearchRes = async () => {
    
      const searchRes = await axios.get(
        "https://mgm-resorts-dev.b.lucidworks.cloud/api/apps/MyMGM_Search/query-pipelines/MyMGM_Search/collections/MyMGM_Search/select?wt=json&json.nl=arrarr&sort&start=0&q=" +
          searchQuery +
          "&rows=10",

        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",

            Authorization:
              "Basic " +
              Buffer.from("lwf-dev" + ":" + "ETG@nah8art5whc7yvy").toString(
                "base64"
              ),
          },
        }
      );
      
      setSearchResultData(searchRes.data.response.docs)
      console.log('searchResultData>>>',searchResultData);
      console.log('searchRes.data.response.docs>>>',searchRes.data.response.docs);
   
  };
  
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  useEffect(()=>{
    getSearchRes();
  },[])

  const handleResize = () => {
    let nowScreenWidth = window.screen.width;
    if (nowScreenWidth <= 425) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const manageDDState = () => {
    let newState = !toggleSortByDD;
    settoggleSortByDD(newState);
  };
  const getSelection = (event, data) => {
    console.log("data >>>", data);
  };

  const manageFilterDDState = () => {
    let newState = !toggleFilterByDD;
    settoggleFilterByDD(newState);
  };
  /*const getFilterSelection = (event, data) => {
    console.log("data >>>", data);
  };*/
 
  return (
    <div className={style.filterMainC}>
      {/* section desktop filters starts */}
      {!isMobile && <Filter></Filter>}
      {/* section desktop filters ends */}

      <div className={`${style.resultH}`}>
        <div className={`${style.resultsContainer}`}>
          <div className={style.searchH}></div>
          {/* section desktop dropdowns starts */}
          {!isMobile && (
            <div className={`${style.titleH} ${style.row}`}>
              <div className={`${style.mTitle}`}>“New ID”</div>
              <div className={`${style.sTitle}`}>{searchResultData.length} {searchResultData.length>0?`results`:`result`}</div>

              <div className={`${style.ddHolder} mfeDDHolder`}>
                <Dropdown
                  id="sortby"
                  label="Sort by"
                  open={toggleSortByDD}
                  onClick={manageDDState}
                  onChange={getSelection}
                >
                  <React.Fragment key=".0">
                    <DropdownItem text="Recent" value="1" />
                    
                  </React.Fragment>
                </Dropdown>
              </div>
            </div>
          )}
          {/* section desktop dropdowns ends */}
          {/* section mobile dropdowns starts */}
          {isMobile && (
            <div className={`${style.titleH} ${style.row} row`}>
              <div className={`${style.mTitle}`}>“New ID”</div>
              <div className={`${style.sTitle}`}>{searchResultData.length} {searchResultData.length>0?`results`:`result`}</div>

              <div className={`${style.ddHolder} mfeDDHolder col-sm-12`}>
                <div
                  id="filterResults"
                  className={style.mobClip}
                  onClick={manageFilterDDState}
                >
                  <img src={slackedLinePath} width="20px" height="20px" />
                  <span className="style.mobClipTitle">Filter results</span>
                  <img src={oneCircePath} width="20px" height="20px" />
                </div>

                <Dropdown
                  id="sortby"
                  label="Sort by"
                  open={toggleSortByDD}
                  onClick={manageDDState}
                  onChange={getSelection}
                >
                  <React.Fragment key=".0">
                    <DropdownItem text="Recent" value="1" />
                    
                  </React.Fragment>
                </Dropdown>
              </div>
              {/* this will display when mobile filter DD is clicked starts*/}
              <div
                className={style.dropdownContainer}
                style={
                  toggleFilterByDD ? { display: "block" } : { display: "none" }
                }
              >
                <Filter></Filter>
              </div>
              {/* this will display when mobile filter DD is clicked ends*/}
            </div>
          )}
          {/* section mobile dropdowns ends */}
          {searchResultData.length>0 && <div className={`${style.row} row`}>

            <div className={`${style.topResults} col-sm-12 col-md-8`}>
                <div className={style.bTitle}>Top result</div>
                <div className={style.fTitle}>{searchResultData[0].heroHeading_t }</div>
                <div className={style.bTitle}>{searchResultData[0].pageTitle_t}</div>
                <div className={style.btext} dangerouslySetInnerHTML={{__html:searchResultData[0].heroDesc_t}}>
                
                </div>
                <div>
                  <a className={style.aText}>
                  {searchResultData[0].pageURL_s}

                  </a>
                </div>
              </div>
                {
                  searchResultData.map((obj,index)=>{
                    if(index>0)
                    {
                      return(<div className={`${style.iconBlock} col-sm-12 col-md-8`} key={index}>
                      <div className={style.iconHolder}>
                        <div className={style.icon}>
                          <img src={iconPath}></img>
                        </div>
                        <div>
                          <div className={style.fTitle}>{obj.heroHeading_t }</div>
                          <div className={style.bTitle}>{obj.pageTitle_t}</div>
                        </div>
                      </div>
                      <div className={style.btext} dangerouslySetInnerHTML={{__html:obj.heroDesc_t}}>
                      
                      </div>
                      <div>
                        <a className={style.aText}> {obj.pageURL_s}</a>
                      </div>
                    </div>)
                    }
                  })
                }
            
          </div>}
        </div>
      </div>
    </div>
  );
};

export default SearchResults;
