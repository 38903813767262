//import { useState } from "react";
import styles from "./LeadershipRelatedNews.module.css";
import { useSelector } from "react-redux";
import { GET_RELATED_NEWS } from "../../features/api-wrapper/apiQueries";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import Loader from "../global/Loader";
import APIError from "../../pages/ErrorHandling/APIError";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

const LeadershipRelatedNews = (props) => {
  const navigate = useNavigate();
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );

  const { loading, error, data } = useQuery(GET_RELATED_NEWS, {
    client: apiMiddlewareClient,
  });

  const newsClickHandler = (news) => {
    navigate(props.link?.mgm_new_url, {
      state: { news, isExternalNews: true },
    });
  };

  const formatedDate = (date) => {
    const dateF = new Date(date);
    return `${
      globalProperties.short_month[dateF.getMonth()]
    } ${dateF.getDate()}, ${dateF.getFullYear()}`;
  };

  if (loading) return <Loader />;
  if (error) return <APIError error={error} />;
  //console.log(data);
  const filterdedNews = data.relatedNews?.slice(0, 3);

  return (
    <section className={styles.relatedNews}>
      <div className={styles.newsHolder}>
        <h3>{props.nameValue.related_mgm_news}</h3>
        <hr></hr>
        {filterdedNews?.map((item, index) => (
          <div
            key={index}
            className={styles.news}
            onClick={() => newsClickHandler(item)}
          >
            <img src={item.images[0]?.imageURL} />
            <h5>{formatedDate(item.newsPublishedDate)}</h5>
            <h4>{item.newsTitle}</h4>
          </div>
        ))}
      </div>
    </section>
  );
};

export default LeadershipRelatedNews;
