import styles from "./PageNotFound.module.css";
import ErrorCard from "./ErrorCard";
import { useSelector } from "react-redux";
import Loader from "../../components/global/Loader";
import { useEffect, useState } from "react";

const PageNotFound = () => {
  const gqlData = useSelector((state) => state.gqlData);
  const [errorType, setErrorType] = useState()
  const globalNotification = useSelector((state) => state.globalNotification);

  useEffect(() => {
    if (globalNotification.systemErrors) {
      const { systemErrors } = globalNotification || {};
      const pageNotFound = systemErrors.filter((err) => {
        return err.type == "404";
      });
      setErrorType(pageNotFound[0])
    }
  }, [globalNotification]);

  return (
    <>
      {gqlData.profile && errorType ? (
        <div className={styles.pagenotfoundHolder}>
          <ErrorCard
            image={errorType.image?.url}
            title={errorType.heading}
            message={errorType.description}
            cta={errorType.cta}
          />
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default PageNotFound;
