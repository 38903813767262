import styles from "./InfoPopup.module.css";
//import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";
import { Tab } from "@mgmresorts/mgm-ui";


const PopoverMgm = (props) => {
  console.log("PopoverMgm");
  // console.log(props.csData);
  const { name, id, profileHander } = props;
  const navigate = useNavigate();

  return (
    <div
      className={styles.container}
      style={props.style}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <h2 className={styles.h2}>{name}</h2>
      <p>
        {props.csData?.employee_id} {id}
      </p>
      <hr />
      <Tab
        label={props.csData?.profile}
        className={styles.button}
        onClick={() => {
          navigate("/profile");
          profileHander();
        }}
      />

      <Tab
        label={props.csData?.sign_out}
        //label={"Sign out"}
        className={styles.button}
        onClick={() => {
          localStorage.removeItem('token');
          localStorage.removeItem('apigee_token');
          localStorage.setItem("MGMisAuthenticated", "false");
          sessionStorage.removeItem('cpath');
          
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('apigee_token');
          sessionStorage.setItem("MGMisAuthenticated", "false");
          window.location.replace(`${process.env.REACT_APP_BASE_URL_LOGIN}logout`);
          profileHander();
        }}
      />
    </div>
  );
};

const InfoPopup = (props) => {
  return <PopoverMgm {...props} />;
};

export default InfoPopup;
