import styles from "./PromoGroup.module.css";
import ChooseCta from "../../hooks/ChooseCta";
import InnerHtml from "./InnerHtml";
//import tempImg from "../../assets/images/arrow-new-window.svg";

const PromoGroup = (props) => {
  console.log(props.data);

  return (
    <div className={styles.promoGroup}>
      <h2>{props.data?.heading}</h2>
      <div className={styles.group}>
        {props.data.children.map((item, index) => (
          <div key={index} className={styles.card}>
            <div className={styles.image}>
              <img data-cslp={props.data?.$?.image} src={item.image} />
              {/* <img data-cslp={props.data?.$?.image} src={props.data?.image} /> */}
            </div>
            <div className={styles.content}>
              <h3 data-cslp={props.data?.$?.heading}>{item.heading}</h3>
              {/* <h3 data-cslp={props.data?.$?.heading}>{props.data?.heading}</h3> */}
              <InnerHtml
                description={item?.description}
                hash={props.data?.$?.description}
              />
              {props.data?.ctaEnabled && (
                <div className={styles.buttonHolder}>
                  <ChooseCta
                    data={props.data?.cta}
                    size="small"
                    style={styles.textLink}
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PromoGroup;
