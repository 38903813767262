import "../../styles/timeoff.css";
import LeaveRequest from "./LeaveRequest";
import styles from "./LeaveOfAbsence.module.css";
import useScreenDetails from "../../hooks/useScreenDetails";
import GetDynamicComponent from "../../hooks/GetDynamicComponent";
import GetRightRailComponent from "../../hooks/GetRightRailComponent";
import { useEffect, useMemo, useState } from "react";

const LeaveOfAbsence = (props) => {
  let { data } = props;
  console.log("LeaveOfAbsence >", data);
  const [isMobile, isSpecifiedSize] = useScreenDetails(1100);
  let [apiReady, setApiReady] = useState(false);
  let [apiIds, setApiIds] = useState([-1, -1]);
  console.log(isMobile, isSpecifiedSize);

  const pageHeaderBlockItems = useMemo(() => {
    return data.filter(
      (item) => item.block === "hero" || item.block === "in_page_alert"
    );
  }, [data]);

  let pageBlockItems = useMemo(() => {
    return data.filter((item) => item.block === "page_block");
  }, [data]);

  let rightRailBlockItems = useMemo(() => {
    return data.filter((item) => item.block === "right_rail_block");
  }, [data]);

  const pageApiBlockItems = useMemo(() => {
    return data.filter((item) => item.block === "api_blocks");
  }, [data]);

  useEffect(() => {
    if (pageApiBlockItems.length > 0) {
      const loaId = pageApiBlockItems.findIndex((item) => item.type === "loa");
      const loaLrId = pageApiBlockItems.findIndex(
        (item) => item.type === "loa_leave_request"
      );
      if (loaId !== -1 && loaLrId !== -1) {
        setApiIds([loaId, loaLrId]);
        setApiReady(true);
      }
    }
  }, [pageApiBlockItems]);

  const Mobview = () => {
    return (
      <div>
        {apiReady && (
          <div className={styles.timeoff_leftsection_loa}>
            <div className="timeoff_header global_headercontainer_tf ">
              {
                pageApiBlockItems[apiIds[0]].data.dictionaryLables
                  ?.how_to_get_started
              }
            </div>
            <div className="bHolderContainer">
              {pageApiBlockItems[apiIds[0]]?.data?.dictionary_with_icon?.map(
                (leftList, index) => (
                  <div className="c-both bulletHolder " key={`lList${index}`}>
                    <div className="f-left">
                      <img src={leftList.url}></img>
                    </div>{" "}
                    <div className="timeoff_statusheader f-left spreadOut">
                      {leftList.value}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        )}
        {apiReady && (
          <LeaveRequest
            attData={pageApiBlockItems[apiIds[1]]}
            leaveRequest={styles.leaveRequest}
          />
        )}
        {pageBlockItems.map((item, index) => (
          <section key={index}>
            <GetDynamicComponent
              comp={item.key}
              data={item.data}
              error={item.error}
            />
          </section>
        ))}
        {rightRailBlockItems.map((item, index) => (
          <section key={index}>
            <GetRightRailComponent
              comp={item.key}
              data={item.data}
              error={item.error}
            />
          </section>
        ))}
      </div>
    );
  };

  const Desktopview = () => {
    return (
      <section className={styles.pageContent}>
        <section className={styles.leftContent}>
          {apiReady && (
            <div className={styles.timeoff_leftsection_loa}>
              <div className="timeoff_header global_headercontainer_tf ">
                {
                  pageApiBlockItems[apiIds[0]].data.dictionaryLables
                    ?.how_to_get_started
                }
              </div>
              <div className="bHolderContainer">
                {pageApiBlockItems[apiIds[0]].data.dictionary_with_icon.map(
                  (leftList, index) => (
                    <div className="c-both bulletHolder " key={`lList${index}`}>
                      <div className="f-left">
                        <img src={leftList.url}></img>
                      </div>{" "}
                      <div className="timeoff_statusheader f-left spreadOut">
                        {leftList.value}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          )}
          {pageBlockItems.map((item, index) => (
            <section key={index}>
              <GetDynamicComponent
                comp={item.key}
                data={item.data}
                error={item.error}
              />
            </section>
          ))}
        </section>
        <section className={styles.rightContent}>
          {apiReady && (
            <LeaveRequest
              attData={pageApiBlockItems[apiIds[1]]}
              leaveRequest={styles.leaveRequest}
            />
          )}
          {rightRailBlockItems.map((item, index) => (
            <section key={index}>
              <GetRightRailComponent
                comp={item.key}
                data={item.data}
                error={item.error}
              />
            </section>
          ))}
        </section>
      </section>
    );
  };

  return (
    <>
      <div className={styles.loaHolder}>
      {pageHeaderBlockItems.map((item, index) => (
          <section className={styles.headerBlocks} key={index}>
            <GetDynamicComponent
              comp={item.key}
              data={item.data}
              error={item.error}
            />
          </section>
        ))}
        {isSpecifiedSize ? Mobview() : Desktopview()}
      </div>
    </>
  );
};

export default LeaveOfAbsence;
