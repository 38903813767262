import InnerHtml from "../dynamic/InnerHtml";
import styles from "./HeroSubHeading.module.css";

const HeroSubHeading = (props) => {
  //console.log(props.data);
  return (
    <section className={styles.heroSubHeading}>
      <div className={styles.titleSection}>
        <h3 data-cslp={props.data?.$?.heading}>{props.data.heading}</h3>
        {props.data?.description && (
          <InnerHtml
            description={props.data?.description}
            style={styles.innerHtml}
            hash={props.data?.$?.description}
          />
        )}
      </div>
    </section>
  );
};

export default HeroSubHeading;
