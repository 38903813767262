import styles from "./Timeoff.module.css";
import "../../styles/timeoff.css";
import CurrentTimeoff from "./CurrentTimeoff";
import TimeoffRequest from "./TimeoffRequests";
import { useEffect, useMemo, useState } from "react";
import GraphQLFTOComponent from "../../components/global/GraphQLFTOComponent";
import { useSelector } from "react-redux";
import { Tab } from "@mgmresorts/mgm-ui";
import useScreenDetails from "../../hooks/useScreenDetails";
import useLivePreview from "../../hooks/useLivePreview";
import { livePreviewInfo } from "../../utils/live-preview";
import GetDynamicComponent from "../../hooks/GetDynamicComponent";
import GetRightRailComponent from "../../hooks/GetRightRailComponent";

const Timeoff = (props) => {
  const { data } = props;
  console.log("Timeoff >>", data);
  const gqlData = useSelector((state) => state.gqlData);
  const [selectedLocationTab, setSelectedLocationTab] = useState(0);
  const [location, setLocation] = useState(" ");
  const [apiData, setApiData] = useState([]);
  const [, isSpecifiedSize] = useScreenDetails(1100);

  let [apiReady, setApiReady] = useState(false);
  let [apiIds, setApiIds] = useState([-1, -1, -1]);
  let [reloadRequestTimeoff, setReloadRequestTimeoff] = useState(true);

  const timeoffRequestCounter = useSelector((state) => state.dom.timeoffRequestCounter);

  useEffect(()=>{
    console.log("timeoffRequestCounter >>>> ", timeoffRequestCounter);
    setReloadRequestTimeoff(false);
    setTimeout(()=>{
      setReloadRequestTimeoff(true);
    }, 100);
  }, [timeoffRequestCounter]);

  const pageHeaderBlockItems = useMemo(() => {
    return data.filter(
      (item) => item.block === "hero" || item.block === "in_page_alert"
    );
  }, [data]);

  const pageBlockItems = useMemo(() => {
    return data.filter((item) => item.block === "page_block");
  }, [data]);

  const rightRailBlockItems = useMemo(() => {
    return data.filter((item) => item.block === "right_rail_block");
  }, [data]);

  const pageApiBlockItems = useMemo(() => {
    return data.filter((item) => item.block === "api_blocks");
  }, [data]);

  useEffect(() => {
    if (pageApiBlockItems.length > 0) {
      const historyId = pageApiBlockItems.findIndex(
        (item) => item.type === "time_off_history"
      );
      const requestId = pageApiBlockItems.findIndex(
        (item) => item.type === "time_off_request"
      );
      const currentId = pageApiBlockItems.findIndex(
        (item) => item.type === "current_time_off"
      );
      if (currentId !== -1 && requestId !== -1 && historyId !== -1) {
        setApiIds([currentId, requestId, historyId]);
        setApiReady(true);
      }
    }
  }, [pageApiBlockItems]);

  if (
    gqlData.profile &&
    gqlData.profile.isMultiplePropertyEmployee &&
    location === " "
  ) {
    if ((apiData && apiData.length > 0) || apiData.timeOffLeaveBalance) {
      setLocation(apiData[0].locationDescription);
    }
  }

  const handleLocationTab = (item, index) => {
    setSelectedLocationTab(index);
    setLocation(item);
  };

  const handleApiData = (childData) => {
    setApiData(childData);
  };

  const locationChange = (e) => {
    let index = apiData.findIndex(
      (item) => item.locationDescription === e.target.value
    );
    setSelectedLocationTab(index);
    setLocation(apiData[index].locationDescription);
  };

  const groupByLocation = apiData.reduce((acc, item) => {
    if (!acc[item.companyName]) {
      acc[item.companyName] = [];
    }
    acc[item.companyName].push(item);
    return acc;
  }, {});
  const locationKeys = Object.keys(groupByLocation);
  let arrayItems = [];
  Object.keys(groupByLocation).forEach((key) => {
    arrayItems.push(groupByLocation[key][0].locationDescription);
  });

  const Mobview = () => {
    return (
      <div className="col-12">
        {apiReady &&
        gqlData.profile &&
        gqlData.profile.isMultiplePropertyEmployee ? (
          <div className={styles.locationDropdown}>
            <select onChange={locationChange}>
              {apiData &&
                apiData.length > 0 &&
                arrayItems.map((item, index) => (
                  <option selected={location === item} key={index} value={item}>
                    {locationKeys[index].replace(", LLC", "")}
                  </option>
                ))}
            </select>
          </div>
        ) : (
          ""
        )}
        {gqlData.profile && gqlData.profile.isFTOEligible ? (
          <GraphQLFTOComponent />
        ) : (
          ""
        )}
        {apiReady && (
          <CurrentTimeoff
            currentTimeoff={styles.currentTimeoff}
            timeOffData={handleApiData}
            attdata={pageApiBlockItems[apiIds[0]]}
            attdataHis={pageApiBlockItems[apiIds[2]]}
            location={location}
            selectedLocationTab={selectedLocationTab}
            isMultiplePropertyEmployee={
              gqlData.profile && gqlData.profile.isMultiplePropertyEmployee
            }
          />
        )}
        {reloadRequestTimeoff && <>
        {apiReady && (
          <TimeoffRequest
            timeoffRequest={styles.timeoffRequest}
            attdata={pageApiBlockItems[apiIds[1]]}
            location={location}
            selectedLocationTab={selectedLocationTab}
            isMultiplePropertyEmployee={
              gqlData.profile && gqlData.profile.isMultiplePropertyEmployee
            }
          />
        )}</>}
        {rightRailBlockItems.map((item, index) => (
          <section key={index}>
            <GetRightRailComponent
              comp={item.key}
              data={item.data}
              error={item.error}
            />
          </section>
        ))}
        {pageBlockItems.map((item, index) => (
          <section key={index}>
            <GetDynamicComponent
              comp={item.key}
              data={item.data}
              error={item.error}
            />
          </section>
        ))}
      </div>
    );
  };

  const Desktopview = () => {
    return (
      <>
        {gqlData.profile && gqlData.profile.isMultiplePropertyEmployee ? (
          <div className="col-12asas timeoff_main_d">
            {apiReady && apiData && apiData.length > 0 && (
              <div className={styles.tabLocationHolderAtt}>
                {
                  // Object.keys(groupByLocation).forEach((key,index)=>{
                  //   console.log("key in grouplocation",groupByLocation[key]);
                  arrayItems.map((item, index) => (
                    <Tab
                      key={index}
                      label={locationKeys[index].replace(", LLC", "")}
                      className={`${styles.tabLocationItem} ${
                        selectedLocationTab === index ? styles.active : ""
                      }`}
                      onClick={() => handleLocationTab(item, index)}
                    />
                  ))
                }
              </div>
            )}
          </div>
        ) : (
          ""
        )}
        <section className={styles.pageContent}>
          <section className={styles.leftContent}>
            {gqlData.profile && gqlData.profile.isFTOEligible ? (
              <GraphQLFTOComponent />
            ) : (
              ""
            )}
            {apiReady && (
              <CurrentTimeoff
                currentTimeoff={styles.currentTimeoff}
                timeOffData={handleApiData}
                attdata={pageApiBlockItems[apiIds[0]]}
                attdataHis={pageApiBlockItems[apiIds[2]]}
                location={location}
                selectedLocationTab={selectedLocationTab}
                isMultiplePropertyEmployee={
                  gqlData.profile && gqlData.profile.isMultiplePropertyEmployee
                }
              />
            )}
            {pageBlockItems.map((item, index) => (
              <section key={index}>
                <GetDynamicComponent
                  comp={item.key}
                  data={item.data}
                  error={item.error}
                />
              </section>
            ))}
          </section>
          <section className={styles.rightContent}>
          {reloadRequestTimeoff && <>
            {apiReady && (
              <TimeoffRequest
                timeoffRequest={styles.timeoffRequest}
                attdata={pageApiBlockItems[apiIds[1]]}
                location={location}
                selectedLocationTab={selectedLocationTab}
                isMultiplePropertyEmployee={
                  gqlData.profile && gqlData.profile.isMultiplePropertyEmployee
                }
              />
            )}</>}
            {rightRailBlockItems.map((item, index) => (
              <section key={index}>
                <GetRightRailComponent
                  comp={item.key}
                  data={item.data}
                  error={item.error}
                />
              </section>
            ))}
          </section>
        </section>
      </>
    );
  };

  if (livePreviewInfo.onLivePreview) {
    useLivePreview(props.data);
  }

  return (
    <>
      <div className={styles.timeoffHolder}>
        {pageHeaderBlockItems.map((item, index) => (
          <section className={styles.headerBlocks} key={index}>
            <GetDynamicComponent
              comp={item.key}
              data={item.data}
              error={item.error}
            />
          </section>
        ))}
        {isSpecifiedSize ? Mobview() : Desktopview()}
      </div>
    </>
  );
};

export default Timeoff;
