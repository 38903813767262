import rotateLines from "../../assets/images/rotated-lines.svg";
import ExternalButton from "../../components/dynamic/ExternalButton";
import mytask from "./MyTaskStayUptoDate.module.css";

const MyTaskStayUptoDate = () => {
  return (
    <>
      <div className={`${mytask.taskcontainer}`}>
        <div className={mytask.taskfixed}>
          <div>
            <img src={rotateLines} />
          </div>
          <div className={`${mytask.taskheader} col-12 mt-3`}>
            Stay up-to-date for My Tasks
          </div>
          <div className={`${mytask.tasksubheader} col-12 mt-3`}>
            My Tasks will allow employees to filter, organise and manage their
            tasks and delegations in one location.
          </div>
          <ExternalButton
            size="small"
            style={`mt-3 ${mytask.buttonWorkday}`}
            data={{
              label: "My Tasks",
              url: "https://wd5.myworkday.com/mgmresorts/learning",
              type: 'secondary',
              openIn: 'new_window'
            }}
          />
        </div>
      </div>
    </>
  );
};

export default MyTaskStayUptoDate;
