import styles from "./LanguageSelection.module.css";
//import { useMutation } from "@apollo/client";
//import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
//import { SET_PREFERRED_LANGUAGE } from "../../features/api-wrapper/apiQueries";
//import Loader from "./Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  setPreferredLanguageTemp,
  setLangChange,
} from "../../data/gqlDataReducer";
//import APIError from "../../pages/ErrorHandling/APIError";
import InnerHtml from "../dynamic/InnerHtml";
import { Radio } from "@mgmresorts/mgm-ui";


const LanguageSelection = (props) => {
  //console.log("props>>", props.languageSection);
  const dispatch = useDispatch();
  const preferredLanguage = useSelector(
    (state) => state.gqlData?.profile?.preferredLanguage
  );
    
  /*const [mutateFunction, { loading, error }] = useMutation(
    SET_PREFERRED_LANGUAGE,
    {
      client: apiMiddlewareClient,
      fetchPolicy: "no-cache",
    }
  );*/
  

  const handleLanguageChange = (e) => {
    let langKey = e.target.value;
    dispatch(setPreferredLanguageTemp(langKey));
    dispatch(setLangChange(true));
    /*mutateFunction({
      variables: { language: langKey },
    });*/
  };

  //if (loading) return <Loader styling="inline" loadingText="Updating..." />;
  //if (error) return <APIError error={error} />;

  return (
    <>
      {props.source === "footer" && (
        <select
          name="languages"
          id="languages"
          onChange={(e) => handleLanguageChange(e)}
          value={preferredLanguage}
        >
          {props.languageSection.languages?.map((item, index) => (
            <option key={index} value={item.value} name={item.name}>
              {item.name}
            </option>
          ))}
        </select>
      )}
      {props.source === "profile" && (
        <div className={styles.profileSection}>
          <h3>{props.languageSection?.heading}</h3>
          <InnerHtml description={props.languageSection?.description} />
          {props.languageSection?.languages?.map((item, index) => (
            <div key={index} className={styles.radioButtonBlock}>
              <Radio
                className={styles.radioButton}
                checked={preferredLanguage === item.value}
                name={item.value}
                id={item.value}
                onChange={(e) => handleLanguageChange(e)}
                value={item.value}
                variant="small"
              />
              <label htmlFor={item.value}>{item.name}</label>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default LanguageSelection;
