import styles from "./OneColumn.module.css";
import ChooseCta from "../../hooks/ChooseCta";
import InnerHtml from "./InnerHtml";
import Modal from "../global/ui/Modal";
import { useState } from "react";

const OneColumn = (props) => {
  //console.log(props.data);

  let [showPopup, setShowPopup] = useState(false);
  let [selectedModal, setSelectedModal] = useState();

  const openModal = () => {
    let modal = props.data?.cta?.modalContent;
    if (modal != null && modal != undefined) {
      setSelectedModal({
        heading: modal.heading,
        body: modal.content,
      });
    } else {
      setSelectedModal({ heading: "", body: "" });
    }
    setShowPopup(true);
  };

  const onPopupClose = () => {
    setShowPopup(false);
  };

  return (
    <div className={styles.oneColumn}>
      <div>
        <h3 data-cslp={props.data?.$?.heading}>{props.data?.heading}</h3>
        <InnerHtml
          description={props.data?.description}
          hash={props.data?.$?.description}
        />
        {props.data?.ctaEnabled && (
          <div className={styles.buttonHolder}>
            <ChooseCta
              data={props.data?.cta}
              size="small"
              type="secondary"
              openModal={openModal}
            />
          </div>
        )}
      </div>
      {showPopup && (
        <Modal onClose={onPopupClose} heading={selectedModal.heading}>
          <InnerHtml description={selectedModal.body} />
        </Modal>
      )}
    </div>
  );
};

export default OneColumn;
