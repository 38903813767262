
import styles from "./SiteMaintenance.module.css";
//import { Icon } from "@mgmresorts/mgm-ui";
import InnerHtml from "../dynamic/InnerHtml";
import { useSelector } from "react-redux";

const SiteMaintenance = ({ data }) => {
  console.log('site maintenance data >>>>',data);
  const siteMaintenanceData = data[0];
  const language = useSelector(
    (state) => state.gqlData?.profile?.preferredLanguage
  );
  return (<>
    {siteMaintenanceData && language?<section className={styles.siteNotification}>
      <div className={styles.container}>
        <div className={styles.leftContent}>
          <div className={styles.heading}>
            {/*<Icon
              
              name="exclamation-point-triangle"
              variant="filled"
              size="small"
              role="img"
             
              color="#000000"
  />*/}
            <div className={styles.exclamationMark} style={{background: `url("${siteMaintenanceData.icon?.url}") no-repeat`}}></div>
            <h3>{siteMaintenanceData.heading}</h3>
          </div>
          
          <InnerHtml
            description={siteMaintenanceData.description}
            style={styles.innerHtml}
            
          />
          
        </div>
      </div>
    </section>:<></>}</>
  );
  };

export default SiteMaintenance;
