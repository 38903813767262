import styles from "./ContentAuthorNote.module.css";
import { Icon } from "@mgmresorts/mgm-ui";

const ContentAuthorNote = (props) => {
  return (
    <div className={styles.contentAuthorNote}>
      <Icon
        name="exclamation-point-triangle"
        variant="outlined"
        size="small"
        role="img"
      />
      <div>
        <h4>Note (to Content Author):</h4>
        <p>{props.message}</p>
      </div>
    </div>
  );
};

export default ContentAuthorNote;
