import styles from "./CalloutHorizontal.module.css";
import InnerHtml from "../dynamic/InnerHtml";
import ChooseCta from "../../hooks/ChooseCta";
import Modal from "../global/ui/Modal";
import useWindowDimensions from "../../hooks/useScreenDetails";
import { useState } from "react";

const CalloutHorizontal = (props) => {
  const [, isMobile] = useWindowDimensions(600);
  let [showPopup, setShowPopup] = useState(false);
  let [selectedModal, setSelectedModal] = useState();

  const openModal = (arg) => {
    let modal = props.data?.cta[arg]?.modalContent;
    if (modal != null && modal != undefined) {
      setSelectedModal({
        heading: modal.heading,
        body: modal.content,
      });
    } else {
      setSelectedModal({ heading: "", body: "" });
    }
    setShowPopup(true);
  };

  const onPopupClose = () => {
    setShowPopup(false);
  };

  return (
    <section className={styles.calloutHorizontal}>
      <div className={styles.container}>
        <div className={styles.imageHolder}>
          <img src={props.data?.image} />
        </div>
        <InnerHtml
          description={props.data?.description}
          style={styles.innerHtml}
          hash={props.data?.$?.description}
        />
        {props.data?.ctaEnabled && !isMobile && (
          <div className={styles.buttonHolder}>
            <ChooseCta
              data={props.data?.cta}
              type="auto_type"
              openModal={openModal}
            />
          </div>
        )}
      </div>
      {props.data?.ctaEnabled && isMobile && (
        <div className={styles.buttonHolderMobile}>
          <ChooseCta
            data={props.data?.cta}
            type="auto_type"
            size="small"
            fullWidth={true}
            openModal={openModal}
          />
        </div>
      )}
      {showPopup && (
        <Modal onClose={onPopupClose} heading={selectedModal.heading}>
          <InnerHtml description={selectedModal.body} />
        </Modal>
      )}
    </section>
  );
};

export default CalloutHorizontal;
