import { useState } from "react";
import newwindowlink from "../../assets/images/arrow-new-window.svg";
import chevrondownblue from "../../assets/images/chevron-down-blue.svg";
import mytask from "./MyTasks.module.css";
import {  MY_TASKS_NOTIFICATION } from "../../features/api-wrapper/apiQueries";
import { useQuery } from "@apollo/client";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import Loader from "../../components/global/Loader";
import APIError from "../ErrorHandling/APIError";
import { Link } from "react-router-dom";

const SHOW_MORE = "Show more";
const SHOW_LESS = "Show less";

const MyTasks = (props) => {
  const { dictionary } = props.data;
  const mytask_header = dictionary[0].value;
  const mytask_subheader = dictionary[1].value;
  const [readMore, setReadMore] = useState(true);
  const { loading, error, data } = useQuery(MY_TASKS_NOTIFICATION, {
    client: apiMiddlewareClient,
  });

  if (loading) return <Loader />;
  if (error) return <APIError error={error} />;

  const handleReadmore = () => {
    setReadMore(!readMore);
  };

  return (
    <div className={`${mytask.taskcontainer}`}>
      <div className={mytask.taskfixed}>
        <div className={`${mytask.taskheader} col-12`}>{mytask_header}</div>
        <div className={`${mytask.tasksubheader} col-12`}>
          {mytask_subheader}
        </div>
      </div>
      <div
        className={`${mytask.taskDetailsSection} ${
          readMore ? mytask.showless : mytask.showmore
        }`}
      >
        {data &&
          data.myTasks?.length > 0 &&
          data.myTasks.map((val) => {
            const due = val.dueOn;
            let todaySDate = new Date(new Date().toISOString().slice(0, 10));
            let currentDiff = new Date(due) - todaySDate;
            let dayMilliSeconds = 1000 * 60 * 60 * 24;
            let totalDays = currentDiff / dayMilliSeconds;
            const duedate = Math.ceil(totalDays);

            return (
              <Link
                key={val.taskMessage}
                className={`${mytask.taskwrapper}`}
                to={
                  "https://wd5-impl.workday.com/mgmresorts4/d/task/2998$44084.htmld"
                }
                target="_blank"
              >
                <div
                  className={`${mytask.taskentry} pt-3 pb-3`}
                  key={val.taskMessage}
                >
                  <div className={mytask.taskval}>{val.taskMessage}</div>
                  {duedate < 0 && (
                    <ul className={mytask.taskdate_overdue}>
                      <li>Overdue</li>
                    </ul>
                  )}
                  {duedate > 0 && duedate <= 3 && (
                    <ul className={mytask.taskdate_overdue}>
                      <li>Due in {duedate} days</li>
                    </ul>
                  )}
                  {duedate > 3 && (
                    <div className={mytask.taskdate_default}>
                      Due in {duedate} days
                    </div>
                  )}
                </div>

                <div className={mytask.tasklink}>
                  <span className={mytask.tspto_statusindicator}>
                    {val.link && <img src={newwindowlink} />}
                  </span>
                </div>
              </Link>
            );
          })}
      </div>
      <div onClick={handleReadmore}>
        <div className={mytask.readmoresection}>
          <span className={mytask.taskshowtext}>
            {readMore ? SHOW_MORE : SHOW_LESS}
          </span>
          <img
            className={readMore ? mytask.taskuparrow : mytask.taskdownarrow}
            src={chevrondownblue}
            alt={readMore ? SHOW_MORE : SHOW_LESS}
          />
        </div>
      </div>
    </div>
  );
};

export default MyTasks;
