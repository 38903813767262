import style from "./WeeklyUpdates.module.css";
import OccuStyle from "./Occupancy.module.css";
import CTATextLink from "../../../components/dynamic/CTATextLink";
import InnerHtml from "../../../components/dynamic/InnerHtml";
// import { useState } from "react";
// import Modal from "../../../components/global/ui/Modal";
import { apiMiddlewareClient } from "../../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_WEEKLYUPDATES } from "../../../features/api-wrapper/apiQueries";
import { useQuery } from "@apollo/client";
//import moment from 'moment';

import APIError from "../../ErrorHandling/APIError";
import Loader from "../../../components/global/Loader";

const WeeklyUpdates = (props) => {
  //console.log("WeeklyUpdates > ", props);
  const { loading, error, data } = useQuery(GET_WEEKLYUPDATES, {
    client: apiMiddlewareClient,
  });
 
  // const { children:  heading } = props.data;

  // let [showPopup, setShowPopup] = useState(false);
  // let [selectedModal, setSelectedModal] = useState();

  // const openModal = (args) => {
  //   let modal = weeklyUpdates[[args]]?.cta?.modalContent;
  //   if (modal != null && modal != undefined) {
  //     setSelectedModal({
  //       heading: modal.heading,
  //       body: modal.content,
  //     });
  //   } else {
  //     setSelectedModal({ heading: "", body: "" });
  //   }
  //   setShowPopup(true);
  // };

  // const onPopupClose = () => {
  //   setShowPopup(false);
  // };
  if (loading) return <Loader />;
  if (error) return <APIError error={error} />;
  return (
    <div className={style.features}>
      <div className="row">
        <div className={`col-12 ${OccuStyle.occupancyHeader}`}>
          <h3
            data-cslp={props.data?.$?.dictionaryLables?.heading}
            className={style.upcomingHeader}
          >
            {props.data?.dictionaryLables?.heading}
          </h3>
        </div>
      </div>
      {data &&
      data.weeklyUpdates &&
      data.weeklyUpdates.leoLink !== "" &&
      (data.weeklyUpdates.images.length !== 0 ||
        data.weeklyUpdates.title !== "") ? (
        <div className={`mt-3 ${style.featuresContainer}`}>
          {/* {data.weeklyUpdates.map((updateObj, index) => { */}
          {/* return ( */}
          <div className="pb-3">
            <h4 data-cslp={props.data?.$?.dictionaryLabels?.heading}>
              {data.weeklyUpdates?.title}
            </h4>
            <img
              style={{ width: "100%" }}
              src={data.weeklyUpdates?.images[0]?.imageURL}
              alt="article image"
            />

            {/* <InnerHtml
                  description={updateObj.description}
                  hash={props.data?.$?.children[index]?.description}
                /> */}
            {data &&
              data.weeklyUpdates &&
              data.weeklyUpdates.leoLink !== "" && (
                <CTATextLink
                  size="small"
                  style={OccuStyle.viewMore}
                  iconRight="arrow-right"
                  type="text_link"
                  data={{
                    label: props.data?.links_dictionary?.[0]?.link?.title,
                    url: data.weeklyUpdates?.leoLink,
                    openIn: "new_window",
                  }}
                />
              )}
          </div>
          {/* ); */}
          {/* })} */}
        </div>
      ) : (
        <InnerHtml
          description={props.data?.dictionaryLables?.no_updates_message}
          style={OccuStyle.readMore}
          // hash={props.data?.$?.children[index]?.description}
        />
      )}
      {/* {showPopup && (
        <Modal onClose={onPopupClose} heading={selectedModal.heading}>
          <InnerHtml description={selectedModal.body} />
        </Modal>
      )} */}
    </div>
  );
};

export default WeeklyUpdates;
