import { useEffect, useState } from "react";
import { livePreviewInfo } from "../utils/live-preview";
import ContentstackLivePreview from "@contentstack/live-preview-utils";
import { liveActions } from "../data/live-preview-slice";
import { useDispatch } from "react-redux";
import { chooseBlock } from "../utils/contentstack-fetch";

import Axios from "axios";

const axiosBaseURL = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

//let token =  sessionStorage.getItem("token") || process.env.REACT_APP_BEARER_TOKEN;
let token =  sessionStorage.getItem("token");
let apigee_token =  sessionStorage.getItem("apigee_token");
const headers = {
  Authorization: `Bearer ${apigee_token}`,
  jwt: token,
};

const useLivePreview = (actualData) => {
  const dispatch = useDispatch();
  //console.log("useLivePreview-----------------");
  //console.log(actualData);
  //const language = useSelector((state) => state.user.language);
  //const [url, setUrl] = useState();
  const [dynamicPageData, setDynamicPageData] = useState([]);

  const loadPage = () => {
    //console.log("LOAD PAGE!! ");
    //console.log(livePreviewInfo);
    if (!livePreviewInfo.onLivePreview) {
      dispatch(liveActions.setOnLivePreviewPage(false));
    }
    if (
      livePreviewInfo.contentTypeUid === null ||
      livePreviewInfo.entryUid === null ||
      livePreviewInfo.hash === null
    ) {
      console.log("NOT LOADED!!");
      return;
    }
    axiosBaseURL
      .get(
        `${process.env.REACT_APP_MIDDLEWARE_CONTENTSTACK_LIVE_PREVIEW}?live_preview=${livePreviewInfo.hash}&content_type_uid=${livePreviewInfo.contentTypeUid}&entry_uid=${livePreviewInfo.entryUid}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        //console.log(response.data);
        let index = findBlockIndex(response.data.uid);
        console.log("index>>>> " + index, response.data.uid);
        //console.log(actualData);
        if (index === -1) {
          return;
        } else if (!isNaN(index)) {
          //console.log("SINGLE LVEL>>>");
          console.log('checking lice preview', actualData[index].key);
          let blockInfo = chooseBlock(
            actualData[index].key,
            response.data.uid,
            response.data
          );
          if (blockInfo.data) {
            updateLiveData(blockInfo.data, blockInfo.uid);
          }
        } else if (Array.isArray(index)) {
          //console.log("MULTILVEL>>>");
          let i = 0;
          let liveObj = actualData[index[i++]].data;
          while (i < index.length) {
            liveObj = liveObj.children[index[i]];
            i++;
          }
          //console.log("liveObj.contentType>> " + liveObj.contentType);
          let blockInfo = chooseBlock(
            liveObj.contentType,
            response.data.uid,
            response.data
          );
          if (blockInfo.data) {
            updateLiveDataMultilevel(blockInfo.data, index);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateLiveDataMultilevel = (data, index) => {
    //console.log("DATA>>");
    //console.log(data);
    let liveData = [...actualData];
    let i = 0;
    let liveObj = liveData[index[i++]].data;
    while (i < index.length) {
      liveObj = liveObj.children[index[i]];
      i++;
    }
    for (let key in liveObj) {
      //console.log("updateLiveData Key>>> " + key);
      //console.log(data[key]);
      if (data[key] !== undefined && key !== "children" && key !== "$") {
        liveObj[key] = data[key];
      }
    }
    setDynamicPageData(liveData);
  };

  const updateLiveData = (data, uid) => {
    let index = actualData.findIndex((item) => item.uid === uid);
    if (index !== -1) {
      let liveData = [...actualData];
      let liveObj = liveData[index].data;
      for (let key in liveObj) {
        if (data[key] !== undefined && key !== "children" && key !== "$") {
          liveObj[key] = data[key];
        }
      }
      setDynamicPageData(liveData);
    }
  };

  const findBlockIndex = (uid) => {
    let index = actualData.findIndex((item) => item.uid === uid);
    if (index !== -1) {
      return index;
    } else {
      return findBlockIndexMultilevel(uid, actualData);
    }
  };

  const findBlockIndexMultilevel = (uid, array, result = []) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].uid) {
        //console.log(array[i].uid, uid, array[i].key);
        if (array[i].uid === uid) {
          return [...result, i];
        } else {
          let tempResult = [];
          //console.log(i, array[i].data?.children);
          if (array[i].data?.children) {
            tempResult = findBlockIndexMultilevel(uid, array[i].data.children, [
              ...result,
              i,
            ]);
            if (tempResult !== -1) return tempResult;
          } else if (array[i].children) {
            tempResult = findBlockIndexMultilevel(uid, array[i].children, [
              ...result,
              i,
            ]);
            if (tempResult !== -1) return tempResult;
          }
        }
      }
    }
    return -1;
  };

  useEffect(() => {
    ContentstackLivePreview.onEntryChange(loadPage);
  }, []);

  return { data: dynamicPageData };
};

export default useLivePreview;
