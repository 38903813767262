import styles from "./PayslipChart.module.css";

import { Accordion } from "@mgmresorts/mgm-ui";
import { useState } from "react";
import ChartItem from "./ChartItem";

const PayslipChart = (props) => {
  //console.log(props.tab);
  const currentId = props.apiData.findIndex(
    (item) => item.period === "current"
  );
  const previousId = props.apiData.findIndex(
    (item) => item.period === "previous"
  );
  const graphValues = [
    {
      title: props.tab.total_hours,
      titleHash: props.tab.total_hours_hash,
      values: [
        props.apiData[currentId].details.timesheetHours,
        props.apiData[previousId].details.timesheetHours,
      ],
    },
    {
      title: props.tab.gross_pay,
      titleHash: props.tab.gross_pay_hash,
      values: [
        props.apiData[currentId].details.grossPay,
        props.apiData[previousId].details.grossPay,
      ],
    },
    {
      title: props.tab.take_home_pay_net,
      titleHash: props.tab.take_home_pay_net_hash,
      values: [
        props.apiData[currentId].details.takeHome,
        props.apiData[previousId].details.takeHome,
      ],
    },
    {
      title: props.tab.deductions,
      titleHash: props.tab.deductions_hash,
      values: [
        Math.round(
          props.apiData[currentId].details.deductions.reduce(
            (total, item) => total + Number(item.amount),
            0
          ) * 100
        ) / 100,
        Math.round(
          props.apiData[previousId].details.deductions.reduce(
            (total, item) => total + Number(item.amount),
            0
          ) * 100
        ) / 100,
      ],
    },
  ];

  let maxPay = Math.max(...graphValues[1].values);

  let [accordionStatus, setAccordionStatus] = useState(false);
  const handleAccordionClick = () => {
    setAccordionStatus((prev) => !prev);
  };
  return (
    <>
      <div
        className={`${styles.accordionBlock} ${
          !accordionStatus ? styles.fixedHeight : ""
        }`}
      >
        <Accordion
          expand={accordionStatus}
          onClick={handleAccordionClick}
          title={props.title}
          data-cslp={props.titleHash}
          content={
            <div className={styles.accordionContent}>
              {graphValues.map((item, index) => (
                <ChartItem
                  key={index}
                  maxPay={maxPay}
                  title={item.title}
                  titleHash={item.titleHash}
                  values={item.values}
                  isHour={index === 0}
                  hideMyPay={props.hideMyPay}
                />
              ))}
              <div className={styles.abbreviations}>
                
                <div className={styles.previousAbbrev}>
                  <span></span>
                  <p data-cslp={props.tab?.current_pay_period_hash}>
                    {props.tab.current_pay_period}
                  </p>
                </div>

                <div className={styles.currentAbbrev}>
                  <span></span>
                  <p data-cslp={props.tab?.previous_pay_period_hash}>
                    {props.tab.previous_pay_period}
                  </p>
                </div>
              </div>
            </div>
          }
        />
      </div>
    </>
  );
};

export default PayslipChart;
