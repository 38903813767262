import { createSlice } from '@reduxjs/toolkit';
 
const gqlDataSlice = createSlice({
  name: 'gqlFTOData',
  initialState: [],
  reducers: {
    updateGqlFTOData: (state, action) => {
      return action.payload;
    },
  },
});
 
export const { updateGqlFTOData } = gqlDataSlice.actions;
 
export default gqlDataSlice.reducer;