import styles from "./TimesheetDayInnerRow.module.css";
import { useRef, useState } from "react";
import { useDetectOutsideClick } from "../../../pages/agentsearch/useDetectOutsideClick";
import { Icon } from "@mgmresorts/mgm-ui";

const TimesheetDayInnerRow = (props) => {
  const { dictionaryLables } = props.staticData || {};
  const missed_punch = dictionaryLables?.missed_punch
    ? dictionaryLables?.missed_punch
    : "Missed Punch";
  const dropdownRef = useRef(null);
  const timeInDate = new Date(props.timeRow.timeIn).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const timeOutDate = new Date(props.timeRow.timeOut).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const [iconHover, setIconHover] = useState(false);
  const [iconClick, setIconClick] = useDetectOutsideClick(dropdownRef, false);

  return (
    <div className={styles.innerRow}>
      <div className={styles.in}>
        {/* {props.timeRow.isTimeInEdited && props.isDateApproved ? (
          <img src={props.icons.approvedEditIcon}></img>
        ) : props.timeRow.isTimeInEdited && !props.isDateApproved ? (
          <img src={props.icons.pendingEditIcon}></img>
        ) : (
          ""
        )} */}
        {props.timeRow.timeIn ? (
          timeInDate
        ) : props.timeRow.missedInPunch ? (
          <div className={styles.missedPunchText}>{missed_punch}</div>
        ) : (
          ""
        )}
      </div>
      <div className={styles.out}>
        {/* {props.timeRow.isTimeOutEdited && props.isDateApproved ? (
          <img src={props.icons.approvedEditIcon}></img>
        ) : props.timeRow.isTimeOutEdited && !props.isDateApproved ? (
          <img src={props.icons.pendingEditIcon}></img>
        ) : (
          ""
        )} */}
        {props.timeRow.timeOut ? (
          timeOutDate
        ) : props.timeRow.missedOutPunch ? (
          <div className={styles.missedPunchText}>{missed_punch}</div>
        ) : (
          ""
        )}
      </div>
      <div
        className={
          props.timeRow.tipCompliance !== "" ? styles.tipsCursor : styles.tips
        }
        ref={dropdownRef}
      >
        <div onClick={() => setIconClick(!iconClick)} className={styles.tipAmt}>
          {" "}
          {props.timeRow.tipCompliance !== "" ? (
            <div>
              {parseFloat(props.timeRow.tipCompliance).toFixed(2)}{" "}
              <Icon
                name="symbol-i-circle"
                color={iconHover ? "#373AE5" : ""}
                onClick={() => setIconClick(!iconClick)}
                onMouseOver={() => setIconHover(true)}
                onMouseOut={() => setIconHover(false)}
                size="small"
                variant="outlined"
              />
            </div>
          ) : (
            <div className={styles.amtRow}>Amt</div>
          )}
          {iconClick && props.timeRow.tipCompliance !== "" ? (
            <div className={styles.iconContainer}>
              {props.timeRow.tipsBreakdown &&
              props.timeRow.tipsBreakdown.length > 0
                ? props.timeRow.tipsBreakdown.map((fav, key) => {
                    return (
                      <div className={styles.tipRow} key={key}>
                        <span>{fav.TipName}</span>
                        <span>${parseFloat(fav.Amount).toFixed(2)}</span>
                      </div>
                    );
                  })
                : ""}
            </div>
          ) : (
            ""
          )}
        </div>
        {/* <div className={styles.hours}>
          {props.timeRow.transferIndicator && (
            <img src={props.icons.transferredIcon}></img>
          )}
        </div> */}
        {/* {props.timeRow.tipCompliance !== "" && (
          <Icon
            name="symbol-i-circle"
            color={iconHover ? "#373AE5" : ""}
            onClick={() => setIconClick(!iconClick)}
            onMouseOver={() => setIconHover(true)}
            onMouseOut={() => setIconHover(false)}
            size="small"
            variant="outlined"
          />
        )} */}
      </div>
    </div>
  );
};

export default TimesheetDayInnerRow;
