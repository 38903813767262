import NetworkError from "./NetworkError";
import ContentNotFound from "./ContentNotFound";
//import PageNotFound from "./PageNotFound";

const ErrorHanding = (props) => {
  if (props.error?.title == "Network Error")
    return <NetworkError error={props.error} />;
  return <ContentNotFound error={props.error} />;
};

export default ErrorHanding;
