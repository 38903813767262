import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import approvedEditIcon from "../../assets/images/green-circle.svg";
import pendingEditIcon from "../../assets/images/red-triangle.svg";
import transferredIcon from "../../assets/images/t-transfer-icon.svg";
import moment from "moment";
import Loader from "../../components/global/Loader";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import {
  GET_TIMESHEET_DETAILS,
  GET_TIMESHEET_MULTIPLE_LOCATIONS,
} from "../../features/api-wrapper/apiQueries";
import {
  getRange,
  secondstoHourConvertor,
} from "../Pay/Timesheet/LocationSpecificTimesheet";
import TimesheetDayRow from "../Pay/Timesheet/TimesheetDayRow";
import myts from "./MyTimesheet.module.css";
import APIError from "../ErrorHandling/APIError";
import { useSelector } from "react-redux";

const MyTimesheet = (props) => {
  const gqlData = useSelector((state) => state.gqlData);
  const { profile } = gqlData;
  const { isMultiplePropertyEmployee: isMultiProp, primaryLocation } = profile || {};
  const { dictionary, links } = props.data || {};
  const myts_headder = dictionary && dictionary[0]?.value;
  const myts_subheadder = dictionary && dictionary[1]?.value;
  const [timesheetAPIData, setTimeSheetData] = useState();
  const [getTimesheetData, { error, loading, data }] = useLazyQuery(
    GET_TIMESHEET_DETAILS,
    {
      fetchPolicy: "no-cache",
      client: apiMiddlewareClient,
    }
  );

  const [getLocation, { data: locations }] = useLazyQuery(
    GET_TIMESHEET_MULTIPLE_LOCATIONS,
    { client: apiMiddlewareClient, fetchPolicy: "cache-first" }
  );

  const icons = {
    transferredIcon: transferredIcon,
    approvedEditIcon: approvedEditIcon,
    pendingEditIcon: pendingEditIcon,
  };

  useEffect(() => {
    if (isMultiProp) {
      getLocation({ variables: {} });
    } else {
      getTimesheetData({
        variables: {
          startDate: moment.utc().subtract(2, "d").format("YYYY-MM-DD"),
          endDate: moment.utc().format("YYYY-MM-DD"),
          locationId: "",
        },
      });
    }
  }, [isMultiProp]);

  useEffect(() => {
    if (locations && locations.timesheetMultipleLocations) {
      const primaryLoc = locations.timesheetMultipleLocations.filter((loc) => {
        return loc.Company == primaryLocation;
      });
      getTimesheetData({
        variables: {
          startDate: moment.utc().subtract(2, "d").format("YYYY-MM-DD"),
          endDate: moment.utc().format("YYYY-MM-DD"),
          locationId: primaryLoc[0]?.code,
        },
      });
    }
  }, [locations]);

  useEffect(() => {
    if (data && data.timeSheet.timesheetDateWise) {
      // create dates based on payperiod start-end date
      const dates = getRange(
        data.timeSheet.payPeriodStartDate,
        data.timeSheet.payPeriodEndDate
      );
      if (data.timeSheet.timesheetDateWise.length > 0) {
        // normalise timesheet data
        const formatDateTimeWise = data.timeSheet.timesheetDateWise.map(
          (timesheetD) => {
            return {
              ...timesheetD,
              hours: secondstoHourConvertor(timesheetD.hours),
              isPayCode: false,
              payCodeName: "",
              isNoTimeReported: false,
            };
          }
        );

        // delete date in dates array if date exist in timesheet
        formatDateTimeWise.forEach((element) => {
          dates.forEach((el, idx) => {
            if (element.timesheetDate === el.timesheetDate) {
              delete dates[idx];
            }
          });
        });
        const dateWiseConcat = formatDateTimeWise.concat(dates);
        const sortedSingleData = dateWiseConcat.sort(function compare(a, b) {
          return new Date(b.timesheetDate) - new Date(a.timesheetDate);
        });

        // if paycodes exist
        if (data.timeSheet.payCodes.length > 0) {
          const formatPaycodes = data.timeSheet.payCodes.map((item) => {
            return {
              timeSheetRow: [],
              timesheetDate: moment(item.payCodeDateTime).format("YYYY-MM-DD"),
              hours: secondstoHourConvertor(item.payCodeAmountInSeconds),
              isPayCode: true,
              payCodeName: item.payCodeName,
              isNoTimeReported: false,
            };
          });

          const paycodeConcatedData = sortedSingleData.concat(formatPaycodes);

          const clubArray = (arr) => {
            return arr.reduce((acc, val) => {
              const index = acc.findIndex(
                (el) => el.timesheetDate === val.timesheetDate
              );
              if (index !== -1) {
                const key = Object.keys(val)[1];
                acc[index][key] = val[key];
                acc[index]["isPayCode"] = true;
                // acc[index]["hours"] = val["hours"];
                acc[index]["payCodeName"] =
                val["payCodeName"] == "PAID TIME OFF"
                  ? "PTO"
                  : val["payCodeName"];
              } else {
                acc.push(val);
              }
              return acc;
            }, []);
          };

          const combinedData = clubArray(paycodeConcatedData).sort(
            function compare(a, b) {
              return new Date(b.timesheetDate) - new Date(a.timesheetDate);
            }
          );
          setTimeSheetData(combinedData);
          return;
        }
        setTimeSheetData(sortedSingleData);
      } else {
        const sortedData = dates.sort(function compare(a, b) {
          return new Date(b.timesheetDate) - new Date(a.timesheetDate);
        });
        setTimeSheetData(sortedData);
      }
    }
  }, [data]);

  if (loading) return <Loader />;
  if (error) return <APIError error={error} isRow={true} />;

  return (
    <div className={`${myts.tscontainer}`}>
      <div className={`${myts.tsheadersection} col-12`}>
        <span className={myts.tsheader}>{myts_headder}</span>
        {links && links.length > 0 && (
          <Link to={links[0].href} className={myts.tsviewall}>
            {links[0]?.title}
          </Link>
        )}
      </div>
      <div className={`${myts.tssubheader} col-12`}>{myts_subheadder}</div>
      {timesheetAPIData &&
        timesheetAPIData.length > 0 &&
        timesheetAPIData.map((datawise, i) => {
          return (
            <TimesheetDayRow
              key={i}
              weekPay={datawise}
              payPeriodStartDate={data.timeSheet.payPeriodStartDate}
              payperiodEndDate={data.timeSheet.payPeriodEndDate}
              icons={icons}
              isApproved={data.timeSheet.isApproved}
              isDashboard="true"
              isPayCode={datawise.isPayCode}
              isNoTimeReported={datawise.isNoTimeReported}
              isDateApproved={datawise.isDateApproved}
              hours={datawise.hours}
              payCodeName={datawise.payCodeName}
              staticData={props?.data}
            />
          );
        })}
    </div>
  );
};

export default MyTimesheet;
