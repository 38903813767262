import { useEffect, useState } from "react";
import styles from "./LeadershipMessagesList.module.css";
import { CardContainer } from "@mgmresorts/mgm-ui";
import { useDispatch, useSelector } from "react-redux";
import { navigationActions } from "../../data/navigation-slice";
import LeadershipMessageInner from "./LeadershipMessageInner";
import LeadershipMessage from "./LeadershipMessage";
import { useNavigationType, useSearchParams } from "react-router-dom";

const LeadershipMessagesList = (props) => {
  //console.log("props > ", props.data);
  const { children: messagesArray, nameValue, link } = props.data;
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigationType = useNavigationType();

  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );

  const getFormatedDate = (dat) => {
    let dateString = dat
      .split("-")
      .filter((item) => !item.includes(":"))
      .join("-");
    let date = new Date(dateString);
    return date == "Invalid Date"
      ? ""
      : `${
          globalProperties?.short_month[date.getMonth()]
        } ${date.getDate()}, ${date.getFullYear()}`;
  };

  const openMessage = (index) => {
    dispatch(navigationActions.setBreadcrumbForceEnable(true));
    let numIndex = Number(index);
    setSelectedIndex(numIndex);
    updateURL(numIndex);
  };

  useEffect(() => {
    const messageId = searchParams.get("message");
    if (messageId) openMessage(messageId);
  }, [searchParams]);

  const updateURL = (id, skipNavType) => {
    setTimeout(() => {
      if (navigationType == "PUSH" || skipNavType) {
        const url = new URL(window.location.href);
        if (id == -1) {
          url.searchParams.delete("message");
          window.history.pushState(null, "", url.toString());
        } else {
          url.searchParams.set("message", id);
          window.history.pushState(null, "", url.toString());
        }
      }
    }, 100);
  };

  const moveLeft = () => {
    let index =
      selectedIndex === 0 ? messagesArray.length - 1 : selectedIndex - 1;
    let numIndex = Number(index);
    setSelectedIndex(numIndex);
    updateURL(numIndex, true);
  };
  const moveRight = () => {
    let index =
      selectedIndex === messagesArray.length - 1 ? 0 : selectedIndex + 1;
    let numIndex = Number(index);
    setSelectedIndex(numIndex);
    updateURL(numIndex, true);
  };
  const loadLandingPage = () => {
    setSelectedIndex(-1);
    updateURL(-1);
  };

  return (
    <div className={styles.leadershipMessagesListHolder}>
      {selectedIndex === -1 && (
        <>
          {messagesArray.map((item, index) => (
            <div className={styles.message} key={index}>
              <CardContainer
                className={styles.cardContainer}
                style={{ borderRadius: "8px", margin: "0", padding: "0" }}
              >
                <div>
                  <LeadershipMessage
                    data={item}
                    messageIndex={index}
                    openMessage={openMessage}
                    date={getFormatedDate(item.date[index])}
                  />
                </div>
              </CardContainer>
            </div>
          ))}
        </>
      )}
      {selectedIndex !== -1 && (
        <LeadershipMessageInner
          selectedIndex={selectedIndex}
          nameValue={nameValue}
          link={link}
          moveLeft={moveLeft}
          moveRight={moveRight}
          loadLandingPage={loadLandingPage}
          data={messagesArray[selectedIndex]}
        />
      )}
    </div>
  );
};

export default LeadershipMessagesList;