import useFetchData from "../hooks/useFetchData";
import ErrorHanding from "../pages/ErrorHandling/ErrorHanding";
import Loader from "../components/global/Loader";

const WithFetchNavigation = (EmptyComponent) => {
  function PackedComponent(props) {
    //console.log("HIGHER ORDER COMP!!");
    const { loading, error, data } = useFetchData("", "navigation");
    if (loading) return <Loader />;
    if (error) return <ErrorHanding error={error} />;
    return <EmptyComponent csData={data[0]?.data?.list} csViewAll={data[0]?.data?.csViewAll} {...props} />;
  }
  return PackedComponent;
};
export default WithFetchNavigation;
