import styles from "./LayoutOne.module.css";

import { useParams } from "react-router-dom";

import useFetchData from "../../hooks/useFetchData";
import GetDynamicComponent from "../../hooks/GetDynamicComponent";
import Loader from "../../components/global/Loader";

const LayoutOne = () => {
  const { id } = useParams();

  const { loading, error, data } = useFetchData("/dynamic/" + id);
  if (loading) return <Loader />;
  if (error) return <p>Error : {error.message}</p>;

  return (
    <>
      <div className={styles.layoutOneHolder}>
        <div className={styles.layoutOne}>
          {data.map((item, index) => (
            <section key={index}>
              <GetDynamicComponent comp={item.key} data={item.data} />
            </section>
          ))}
        </div>
      </div>
    </>
  );
};

export default LayoutOne;
