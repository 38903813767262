import styles from "./Tile.module.css";
import { useEffect, useState } from "react";
import Modal from "../global/ui/Modal";
import ChooseCta from "../../hooks/ChooseCta";
import InnerHtml from "./InnerHtml";
import ConfirmationPopup from "../global/ConfirmPrompt";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Tile = (props) => {
  //console.log("Tile >", props.data);
  const clickableComponent = props?.data?.clickableComponent || false;
  const globalNotification = useSelector((state) => state.globalNotification);
  const { whiteListedDomain } = globalNotification || [];
  let url = props.data?.cta?.url;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isContinue, setIsContinue] = useState(false);
  let [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  let modalContent = props?.data?.cta?.modalContent || null;

  const openModal = () => {
    setShowPopup(true);
  };
  const onPopupClose = () => {
    setShowPopup(false);
  };

  const tileClickHandler = (e, opensIn) => {
    console.log("opensIn> ", opensIn);
    if (clickableComponent) {
      e.preventDefault();
      if (opensIn === "new_window") {
        whiteListedDomain.some((v) => url.includes(v))
          ? window.open(url, "_blank")
          : setIsModalOpen(true);
      } else if (opensIn === "same_window") {
        navigate(url);
      } else if (opensIn === "modal") {
        openModal();
      }
    }
  };

  useEffect(() => {
    if (isContinue) {
      setIsModalOpen(false);
      setIsContinue(false);
      window.open(url, "_blank");
    }
  }, [isContinue]);

  return (
    <>
      <div
        className={`${styles.tile} ${
          clickableComponent ? styles.clickable : ""
        }`}
        onClick={(e) => tileClickHandler(e, props.data?.cta?.openIn)}
      >
        <div
          className={styles.image}
          style={{ background: `url("${props.data?.image}")` }}
        >
          {/*<img data-cslp={props.data?.$?.image} src={props.data?.image} />*/}
        </div>
        <div
          className={`${styles.content} ${
            props.data?.ctaEnabled ? styles.withCta : ""
          }`}
        >
          <h3 data-cslp={props.data?.$?.heading}>{props.data?.heading}</h3>
          <InnerHtml
            description={props.data?.description}
            hash={props.data?.$?.description}
          />
          {props.data?.ctaEnabled && (
            <div className={styles.buttonHolder}>
              <ChooseCta
                data={props.data?.cta}
                size="small"
                type="text_link"
                openModal={openModal}
              />
            </div>
          )}
        </div>
      </div>
      {showPopup && (
        <Modal
          onClose={onPopupClose}
          heading={modalContent != null ? modalContent.heading : ""}
        >
          <InnerHtml
            description={modalContent != null ? modalContent.content : ""}
          />
        </Modal>
      )}
      {isModalOpen && (
        <ConfirmationPopup
          isModalOpen={isModalOpen}
          onContinue={setIsContinue}
          onClose={() => {
            setIsModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export default Tile;
