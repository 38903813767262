import InnerHtml from "../../components/dynamic/InnerHtml";
import ChooseCta from "../../hooks/ChooseCta";
import style from "../../styles/HelpInfo.module.css";

const HelpCenter = ({ helpData }) => {
  return (
    <>
      {helpData.map((help) => {
        return (
          <div className={style.helpCenterContainer} key={help.uid}>
            <div>
              <div
                aria-label={help.data.heading}
                className={style.helpCenterHeading}
              >
                {help.data.heading}
              </div>
              <InnerHtml
                description={help.data?.description}
                style={style.helpCenterSubtext}
                ariaLabel={help.data.description}
              />
            </div>
            <div className={style.helpCenterBtnHolder}>
              <ChooseCta
                style={style.helpCenterButton}
                data={
                  help?.data?.cta && help?.data?.cta.length && help.data.cta[0]
                }
                size="small"
                type="primary"
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default HelpCenter;
