import CTATextLink from "../../components/dynamic/CTATextLink";
import styles from "./Case.module.css";

const Case = (props) => {
  // const hrString = "hr_cases";
  // const selectedURL =
  // props.data[0].name?.toLowerCase() === hrString.toLowerCase()
  //   ? process.env.REACT_APP_NEOCASE_REDIRECT
  //   : process.env.REACT_APP_SNOW_REDIRECT;
  return (
    <div className={styles.caseItem}>
      {!props.isBelow && (
        <div className={styles.title}>
          <h3>{props.data[0].value}</h3>
          <p>{props.data[1].value}</p>
        </div>
      )}
      <div className={styles.information}>
        <div className={`${styles.alert} ${styles.alertGreen}`}>
          <p className={styles.count}>{props.dummyValues}</p>
          <p>{props.data[2].value}</p>
        </div>
      </div>
      {props.data[0].name === "hr_cases" && (
        <div className={`${styles.alert} ${styles.alertRed}`}>
          <p className={styles.count}>{props.noOfTask}</p>{" "}
          <p>{props.data[3].value}</p>
        </div>
      )}
      <div className={styles.buttonHolder}>
        <CTATextLink
          size="small"
          type="text_link"
          className={styles.buttonViewAll}
          data={{
            label: props.data[4].link.title,
            url: props.data[4].link.href,
            openIn: "new_window",
          }}
        />
      </div>
    </div>
  );
};

export default Case;
