import { createSlice } from "@reduxjs/toolkit";

const initialState =
{
    searchResultPageData: {
        sort: null,
        facetcounts: null,
        totalNum: null,
        assignValue: null,
        selectionDD: null,
        topresult: null,
        resultdata: null,
        page:null,
        showfilter:null,
        btnState:null,
        catQueryString:null
    }
    
};
const searchResultSlice = createSlice({
    name: "searchresult",
    initialState,
    reducers: {
        setsearchResultPageData(state, action) {
        state.searchResultPageData = action.payload;
      },
    },
  });
  

export const searchResultActions = searchResultSlice.actions;
export default searchResultSlice;