import { Link } from "react-router-dom";
import service from "./SelfService.module.css";
import InnerHtml from "../../components/dynamic/InnerHtml";
import CTATextLink from "../../components/dynamic/CTATextLink";

const SelfService = (props) => {
  const {
    heading,
    description,
    children: service_list,
    viewAll,
  } = props.selfServiceData;

  return (
    <div className={`${service.sscontainer}`}>
      <div className={`${service.ssheadersection} col-12`}>
        <span className={service.ssheader}>{heading}</span>
        <Link className={service.ssviewall} to={viewAll.href}>
          {viewAll.title}
        </Link>
      </div>
      <InnerHtml
        description={description}
        style={`${service.sssubheader} col-12`}
      />
      <div className={service.ssDetailsSection}>
        {service_list.map((val) => {
          return (
            <div key={val.uid} className={`${service.service_border}`}>
              <CTATextLink
                size="small"
                style={`${service.ssentry} pt-3 pb-3`}
                iconRight="arrow-right"
                type="text_link"
                data={{
                  label: val.cta?.label,
                  url: val.cta?.url,
                  openIn: val.cta?.openIn,
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelfService;
