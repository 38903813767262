import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import InnerHtml from "../../components/dynamic/InnerHtml";
import Loader from "../../components/global/Loader";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_CLOCKEDHOURS } from "../../features/api-wrapper/apiQueries";
import { getLocalStoreValue } from "../../utils/localStore";
import APIError from "./APIError";
import "./ErrorGlobal.css";
import styles from "./HourlyClockedOut.module.css";

const HourlyClockedOut = () => {
  const gqlData = useSelector((state) => state.gqlData);
  const path = getLocalStoreValue("path");
  let isHourlyEmp = false;
  const navigate = useNavigate();

  const [errorData, setErrorData] = useState();
  const globalNotification = useSelector((state) => state.globalNotification);

  useEffect(() => {
    if (globalNotification.systemErrors) {
      const { systemErrors } = globalNotification || {};
      const pageNotFound = systemErrors.filter((err) => {
        return err.type == "currently_unavailable";
      });
      setErrorData(pageNotFound[0]);
    }
  }, [globalNotification]);

  if (gqlData.profile?.payRateGroup === "Salary") {
    isHourlyEmp = false;
    navigate("/" + path);
  } else if (gqlData.profile?.payRateGroup) {
    isHourlyEmp = true;
  }
  const { loading, error, data } = useQuery(GET_CLOCKEDHOURS, {
    client: apiMiddlewareClient,
    skip: !isHourlyEmp,
  });

  if (loading) return <Loader />;
  if (error) return <APIError error={error} />;

  if (data && data.clockedHours && data.clockedHours.isClockedIn === false) {
    console.log("display error");
  } else if (data) {
    navigate("/" + path);
  }

  return data && errorData ? (
    <div className={styles.coutHolder}>
      <div className={styles.innerH}>
        {errorData.image && (
          <div className={styles.iconH}>
            <img src={errorData.image.url} alt={errorData.image.url}/>
          </div>
        )}
        <div className={styles.txt1}>{errorData.heading}</div>
        <InnerHtml
          className={styles.txt2}
          description={errorData.description}
        />
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default HourlyClockedOut;
