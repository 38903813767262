import style from "./EmployeeProfile.module.css";
import SearchInput from "./SearchInput";
import {  useSelector } from "react-redux";
//import { useNavigate } from "react-router-dom";
import ProfileAgentsearch from "./ProfileAgentsearch";
import {setLocalStoreValue,getLocalStoreValue} from "../../utils/localStore"
const EmployeeProfile = ()=>{
    let data;
    
    useSelector((state) => {
        data = state.userProfile.data;
        if(data && data.employeeId)
        {
            setLocalStoreValue('data',JSON.stringify(data))
        }
        else
        {
            data = JSON.parse(getLocalStoreValue('data'))
        }
    })
    console.log("data>>>",data);
    
    return(<><ProfileAgentsearch></ProfileAgentsearch>
    <div className={style.inputH}>
        <SearchInput employeeId={data.employeeId}></SearchInput>
    </div>
    </>)
}

export default EmployeeProfile;