import EmployeeProfile from "./EmployeeProfile";
import AgentSearchHeader from "./AgentSearchHeader";
import GlobalSearch from "./GlobalSearch";
import styles from "./AgentSearch.module.css";
import "./AgentsearchGlobal.css";

const AgentSearch = (props) => {
  return (
    <div className={styles.agentSearch}>
      {props.page === "search" && <GlobalSearch {...props}></GlobalSearch>}

      {props.page != "search" && (
        <>
          <AgentSearchHeader></AgentSearchHeader>
          <div className="container container_agent_search">
            <div className={styles.agentpagecontainer}>
              <EmployeeProfile></EmployeeProfile>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AgentSearch;
