import styles from "./MySchedule.module.css";

import { Fragment, useEffect, useMemo, useState } from "react";
import GetDynamicComponent from "../../../hooks/GetDynamicComponent";
import GetRightRailComponent from "../../../hooks/GetRightRailComponent";
import useLivePreview from "../../../hooks/useLivePreview";
import { livePreviewInfo } from "../../../utils/live-preview";
import MyScheduleAPI from "./MyScheduleAPI";
import MyScheduleMessage from "./MyScheduleMessage";
import { CardContainer } from "@mgmresorts/mgm-ui";
import InnerHtml from "../../../components/dynamic/InnerHtml";

const MySchedule = (props) => {
  const { data } = props;
  let [apiReady, setApiReady] = useState(false);
  const [VRNotification, setVRNotification] = useState();
  const [messageType, setMessageType] = useState();

  const pageHeaderBlockItems = useMemo(() => {
    return data.filter(
      (item) => item.block === "hero" || item.block === "in_page_alert"
    );
  }, [data]);

  const pageBlockItems = useMemo(() => {
    return data.filter((item) => item.block === "page_block");
  }, [data]);

  const pageApiBlockItems = useMemo(() => {
    return data.filter((item) => item.block === "api_blocks");
  }, [data]);

  const rightRailBlockItems = useMemo(() => {
    return data.filter((item) => item.block === "right_rail_block");
  }, [data]);

  useEffect(() => {
    if (
      pageApiBlockItems.length > 0 &&
      pageApiBlockItems[0].type === "schedule"
    ) {
      setApiReady(true);
      setVRNotification(pageApiBlockItems[0]?.data);
    }
  }, [pageApiBlockItems]);

  const setMessageBlock = (messages) => {
    setMessageType(
      messages.isAckMessageAvailable && messages.isMessageReview
        ? "acknowledge"
        : messages.isAckMessageAvailable
        ? "acknowledge"
        : "review"
    );
  };

  if (livePreviewInfo.onLivePreview) {
    useLivePreview(props.data);
  }

  return (
    <div className={styles.payslipHolder}>
      {pageHeaderBlockItems.map((item, index) => (
        <section className={styles.headerBlocks} key={index}>
          <GetDynamicComponent
            comp={item.key}
            data={item.data}
            error={item.error}
          />
        </section>
      ))}
      {VRNotification &&
        VRNotification?.dictionary_with_icon &&
        VRNotification?.dictionary_with_icon[1] && messageType && (
          <Fragment>
            <MyScheduleMessage
              data={{
                messageType: messageType,
                url: VRNotification?.dictionary_with_icon[
                  messageType == "acknowledge" ? 2 : 1
                ]?.url,
                heading:
                  VRNotification?.dictionary_with_icon[
                    messageType == "acknowledge" ? 2 : 1
                  ]?.value,
                description:
                  messageType == "acknowledge"
                    ? VRNotification.dictionaryLables
                        .VR_notification_acknowledge_description
                    : VRNotification.dictionaryLables
                        .VR_notification_review_description,
                link: {
                  label: VRNotification?.links_dictionary[0]?.link?.title,
                  url: VRNotification?.links_dictionary[0]?.link?.href,
                  openIn: VRNotification?.links_dictionary[0]?.open_in,
                },
              }}
            ></MyScheduleMessage>
          </Fragment>
        )}
      <section className={styles.pageContent}>
        <Fragment>
          <section className={styles.leftContent}>
            {messageType ? (
              VRNotification && (
                <CardContainer className={styles.noCardContainer}>
                  <div>
                    <img src={VRNotification?.dictionary_with_icon[3].url} alt="alert icon" />
                    <h6>{VRNotification?.dictionary_with_icon[3].value}</h6>
                    <InnerHtml
                      description={
                        VRNotification?.dictionaryLables
                          ?.VR_schedule_downtime_description
                      }
                    ></InnerHtml>
                  </div>
                </CardContainer>
              )
            ) : (
              <>
                {apiReady &&
                  pageApiBlockItems &&
                  pageApiBlockItems.length > 0 && (
                    <MyScheduleAPI
                      csData={pageApiBlockItems[0].data}
                      message={(messageData) => {
                        setMessageBlock(messageData);
                      }}
                    />
                  )}
                {pageBlockItems &&
                  pageBlockItems.map((item, index) => (
                    <section key={index}>
                      <GetDynamicComponent
                        comp={item.key}
                        data={item.data}
                        error={item.error}
                      />
                    </section>
                  ))}
              </>
            )}
          </section>
          <section className={styles.rightContent}>
            {rightRailBlockItems.map((item, index) => (
              <section key={index}>
                <GetRightRailComponent
                  comp={item.key}
                  data={item.data}
                  error={item.error}
                />
              </section>
            ))}
          </section>
        </Fragment>
      </section>
    </div>
  );
};

export default MySchedule;
