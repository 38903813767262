import style from "./Occupancy.module.css";
import Tabs from "../../../components/common/tabs/tabs";
import { useEffect, useRef, useState } from "react";
import useWindowDimensions from "../../../hooks/useScreenDetails";
import TabPanel from "../../../components/common/tab-panel/TabPanel";

const tabs = [
  {
    id: "Aria",
    label: "Aria",
  },
  {
    id: "Vidara",
    label: "Vidara",
  },
  {
    id: "Cosmopolitian",
    label: "Cosmopolitian",
  },
];

const occupancyData = [
  {
    day: "Su",
    occupiedRooms: 7,
    availableRooms: 2,
    OccupancyValue: "95.6%",
  },
  {
    day: "Mo",
    occupiedRooms: 7,
    availableRooms: 3,
    OccupancyValue: "98.1%",
  },
  {
    day: "Tu",
    occupiedRooms: 7,
    availableRooms: 4,
    OccupancyValue: "97.6%",
  },
  {
    day: "W",
    occupiedRooms: 7,
    availableRooms: 5,
    OccupancyValue: "82.1%",
  },
  {
    day: "Th",
    occupiedRooms: 7,
    availableRooms: 6,
    OccupancyValue: "88.9%",
  },
  {
    day: "F",
    occupiedRooms: 7,
    availableRooms: 8,
    OccupancyValue: "92.1%",
  },
  {
    day: "Sa",
    occupiedRooms: 7,
    availableRooms: 9,
    OccupancyValue: "92.8%",
  },
];

const Occupancy = (props) => {
  const [selectedType, setSelectedType] = useState("Aria");
  const [isMobile] = useWindowDimensions();
  const [isMouseDown, setMouseDown] = useState(false);
  const [scrollShift, setScrollShift] = useState(0);
  const scrollEl = useRef();
  const header = props.data.dictionary[0].value;

  useEffect(() => {
    if (isMouseDown) {
      scrollEl.current.scrollLeft += scrollShift;
    }
  }, [isMouseDown]);

  const scroll = (scrollOffset) => {
    setMouseDown(true);
    setScrollShift(scrollOffset);
  };

  const mouseUp = () => {
    setMouseDown(false);
  };

  const setSelectedArea = (e) => {
    scrollEl.current.scrollLeft = 0;
    const value = e.target.value;
    setSelectedType(value);
    setMouseDown(true);
  };

  const occupancyDetails = () => {
    return (
      <div className={style.occupanyDetails}>
        {occupancyData.map((occupancy, index) => {
          const isLastRecord = occupancyData.length - 1 !== index;
          return (
            <>
              <div key={index}>
                <p className={`mb-1 ${style.occupancyRooms}`}>
                  {occupancy.day}{" "}
                  <span>{`${occupancy.occupiedRooms}/${occupancy.availableRooms}`}</span>
                </p>
                <p className={style.occuValue}>{occupancy.OccupancyValue}</p>
              </div>
              {isLastRecord && (
                <div className={style.rightBorder} tabIndex={-1}></div>
              )}
            </>
          );
        })}
      </div>
    );
  };

  return (
    <div className="d-none">
      <div className={`${style.occupancyContainer} mt-5`}>
        <div className="row">
          <div className={`col-12 col-lg-6 ${style.occupancyHeader}`}>
            <h3 className="d-inline">{header}</h3>
            <div className="d-inline ">Updated Mon Aug 28 2023</div>
          </div>
          <div className="col-lg-6 mb-4">
            {!isMobile ? (
              <Tabs
                onClick={setSelectedType}
                selectedType={selectedType}
                tabs={tabs}
              />
            ) : (
              <div className="row mt-2">
                <div className="col-6">
                  <select
                    onChange={(e) => {
                      setSelectedArea(e);
                    }}
                    className={`${style.selectDropdown}`}
                  >
                    {tabs.map((data, index) => {
                      return <option key={index}>{data.label}</option>;
                    })}
                  </select>
                </div>
                <div className={`${style.navHolder} col-6`}>
                  <button
                    className={style.leftnavP}
                    onClick={() => scroll(-20)}
                    onMouseDown={() => scroll(-20)}
                    onMouseUp={mouseUp}
                  ></button>
                  <button
                    className={style.rightnavP}
                    onClick={() => scroll(20)}
                    onMouseDown={() => scroll(20)}
                    onMouseUp={mouseUp}
                  ></button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div ref={scrollEl} className={style.hscroll}>
          <TabPanel
            selectedType={selectedType}
            id={"Aria"}
            styles={style.scrollDiv}
          >
            {occupancyDetails()}
          </TabPanel>
          <TabPanel
            selectedType={selectedType}
            id={"Vidara"}
            styles={style.scrollDiv}
          >
            <center>No details Available</center>
          </TabPanel>
          <TabPanel
            selectedType={selectedType}
            id={"Cosmopolitian"}
            styles={style.scrollDiv}
          >
            {occupancyDetails()}
          </TabPanel>
        </div>
      </div>
    </div>
  );
};

export default Occupancy;
