import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { chooseBlock } from "../utils/contentstack-fetch";
import { livePreviewInfo } from "../utils/live-preview";
import { getLocalStoreValue } from "../utils/localStore";

import Axios from "axios";
import { setBMDescription } from "../data/bookmark-desc-slice";
import { domActions } from "../data/dom-slice";

const isElectron = getLocalStoreValue("electron") == "electron";

let data;
const axiosBaseURL = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const useFetchData = (url, contentType = "page_detail") => {


  const language = useSelector(
    (state) => state.gqlData?.profile?.preferredLanguage
  );
  /*const previousLanguage = useSelector(
    (state)=> state.dom.previouslanguage
    );
    const previousPageType = useSelector(
      (state)=> state.dom.previousPageType
      );
    
  console.log('previousLanguage $$$',previousLanguage);
  console.log('previousPageType $$$',previousPageType);*/
  let lang = language;
  /*let lang =
    language === undefined ||
      language?.toLowerCase() === "en" ||
      language?.toLowerCase() === "en-us"
      ? "en-us"
      : language;*/
  //console.log("language ----->", lang);

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [dynamicPageData, setDynamicPageData] = useState([]);
  const [internalSystemType, setInternalSystemType] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const dispatch = useDispatch();

  let pageType;

  let dataArray = [];

  const HORIZONTAL_GROUP_START = "START";
  const HORIZONTAL_GROUP_IN = "IN";
  const HORIZONTAL_GROUP_END = "END";
  let horizontalGroup = "";
  let horizontalGroupHeading = "";

  const loadPage = (url, contentType) => {

    console.log('language load page  @@@$', lang);
    console.log('url @@@$ load page', url);
    console.log('contentType @@@$ loadPage', contentType);
    
    let token = getLocalStoreValue("token");
    let apigee_token = getLocalStoreValue("apigee_token");
    let editTags = livePreviewInfo?.onLivePreview ? "&editTags=true" : "";
    data = JSON.parse(getLocalStoreValue("data"));
    let queryString =
      isElectron && data
        ? `contentType=${contentType}&url=${url}${editTags}&locale=${lang}&employeeId=${data.employeeId}`
        : `contentType=${contentType}&url=${url}${editTags}&locale=${lang}`;
    axiosBaseURL
      .get(
        `${process.env.REACT_APP_MIDDLEWARE_CONTENTSTACK}?${queryString}`, //&locale=${lang}
        {
          headers: { Authorization: `Bearer ${apigee_token}`, jwt: token },
        }
      )
      .then((response) => {
        console.log("Response >> ", response);
        dispatch(
          setBMDescription(
            response.data.bookmark_description
              ? response.data.bookmark_description
              : ""
          )
        );

        console.log(
          "response.data?.show_stock_feed ########",
          response.data.show_stock_feed
        );

        if (response.data?.show_stock_feed) {
          dispatch(domActions.setStockDom(response.data?.show_stock_feed));
        } else {
          dispatch(domActions.setStockDom(false));
        }

        if (response.data?.page_under_development === true) {
          setError({
            title: "Page under development",
            message: `'${url}' => Page under development!`,
          });
          setIsLoading(false);
        } else {
          dispatch(domActions.setAnalyticsPageTitle(response?.data?.title));
          if (response.data?.page_reference)
            loadBlocks(
              response.data?.page_reference[0]?.page_blocks,
              "page_block"
            );

          pageType = response.data?.type;
          if (pageType && response.data?.hero)
            if (
              Array.isArray(response.data?.hero) &&
              response.data?.hero.length > 0
            )
              loadHeroBlock(response.data?.hero[0]);

          if (pageType && response.data?.hero)
            if (
              Array.isArray(response.data?.in_page_alert) &&
              response.data.in_page_alert.length > 0
            )
              loadPageAlertBlock(response.data?.in_page_alert[0]);

          loadBlocks(response.data?.api_blocks, "api_blocks");
          loadBlocks(response.data?.page_blocks, "page_block");
          loadBlocks(response.data?.right_rail_blocks, "right_rail_block");
          let hasApieBlcoks = response.data?.api_blocks?.length;
          let hasPageReferencePageBlcoks = response.data?.page_reference
            ? response.data?.page_reference[0]?.page_blocks?.length
            : 0;
          let hasPageBlcoks = response.data?.page_blocks?.length;
          let hasRailBlcoks = response.data?.right_rail_blocks?.length;
          if (
            (hasPageBlcoks === 0 || hasPageBlcoks === undefined) &&
            (hasApieBlcoks === 0 || hasApieBlcoks === undefined) &&
            (hasRailBlcoks === 0 || hasRailBlcoks === undefined) &&
            (hasPageReferencePageBlcoks === 0 ||
              hasPageReferencePageBlcoks === undefined)
          ) {
            setError({
              title: "Pageblocks not available!",
              message: `'${url}' => Pageblocks are not available on Contentstack!`,
            });
            setIsLoading(false);
          }
        }
      })
      .catch((error) => {
        console.error("entered error", error);
        setError({
          title: error.response?.data ? error.response?.data : error.message,
          message: error.message ? error.message : error.response?.data,
          status: error.response?.status ? error.response?.status : null,
        });
        setIsLoading(false);
      });
  };

  const loadGlobalElement = (contentType) => {
    console.log('language global element @@@$', lang);
    console.log('url global element @@@$', url);
    console.log('contentType global element @@@$', contentType);
    //console.log('previousPageType global element @@@$', previousPageType);
   
    /*if(previousPageType.indexOf(contentType) > 0)
    {
      return;
    }*/
    if(typeof(lang) == 'undefined')
    {
      return;
    }
    console.log('contentType global element after filter @@@$', contentType);
    let token = getLocalStoreValue("token");
    let apigee_token = getLocalStoreValue("apigee_token");
    let editTags = "";
    if (contentType === "navigation")
      editTags = livePreviewInfo?.onLivePreview ? "&editTags=true" : "";
    data = JSON.parse(getLocalStoreValue("data"));
    let queryString =
      isElectron && data
        ? `contentType=${contentType}${editTags}&locale=${lang}&employeeId=${data.employeeId}`
        : `contentType=${contentType}${editTags}&locale=${lang}`;
    axiosBaseURL
      .get(
        `${process.env.REACT_APP_MIDDLEWARE_CONTENTSTACK}?${queryString}`, //&locale=${lang}
        {
          headers: { Authorization: `Bearer ${apigee_token}`, jwt: token },
        }
      )
      .then((response) => {
        let uid = response?.data?.uid;
        dataArray.push({
          uid,
          data: {},
          isDataLoaded: null,
        });
        if (contentType === "navigation") loadNavigation(response?.data, uid);
        else if (contentType === "header") loadHeader(response?.data, uid);
        else if (contentType === "footer") loadFooter(response?.data, uid);
        else if (contentType === "globals")
          loadSystemErrors(response?.data, uid);
        else if (contentType === "forms" || contentType === "form")
          loadFeedbackForm(response?.data, uid);
      })
      .catch((error) => {
        console.error("entered error", error);
        setError({
          title: error.response?.data ? error.response?.data : error.message,
          message: error.message ? error.message : error.response?.data,
          status: error.response?.status ? error.response?.status : null,
        });
        setIsLoading(false);
      });
  };

  const loadBadge = (contentType) => {
    console.log('language load badge @@@$', lang);
    console.log('url load badge @@@$', url);
    console.log('contentType load badge @@@$', contentType);
    let token = getLocalStoreValue("token");
    let apigee_token = getLocalStoreValue("apigee_token");
    data = JSON.parse(getLocalStoreValue("data"));

    // let queryString = `contentType=${contentType}&include_count=true&environment=dev`; //locale
    let queryString = `contentType=${contentType}&include_count=true&locale=${lang}&environment=qa`; //locale

    axiosBaseURL
      .get(`${process.env.REACT_APP_MIDDLEWARE_CONTENTSTACK}?${queryString}`, {
        headers: { Authorization: `Bearer ${apigee_token}`, jwt: token },
      })

      .then((response) => {
        dataArray.push({
          uid: response?.data?.uid,
          data: {},
          isDataLoaded: null,
        });

        setPageBlockData(response?.data, response?.data?.uid);
        onLoadCompete();
      });
  };
  const loadForm = (url, contentType) => {
    console.log('language load form  @@@$', lang);
    console.log('url load form @@@$', url);
    console.log('contentType load form  @@@$', contentType);
    let token = getLocalStoreValue("token");
    let apigee_token = getLocalStoreValue("apigee_token");
    let editTags = livePreviewInfo?.onLivePreview ? "&editTags=true" : "";
    axiosBaseURL
      .get(
        `${process.env.REACT_APP_MIDDLEWARE_CONTENTSTACK}?contentType=${contentType}&url=${url}&locale=${lang}${editTags}`, //&locale=${lang}
        {
          headers: { Authorization: `Bearer ${apigee_token}`, jwt: token },
        }
      )
      .then((response) => {
        let uid = response?.data?.uid;
        dataArray.push({
          uid,
          data: {},
          isDataLoaded: null,
        });
        console.log("response >> ", response);
        let formContent = {};
        const formContentArray =
          response?.data?.form_content &&
          response?.data?.form_content[0]?.form_attribute?.reference;
        for (let i = 0; i < formContentArray.length; i++) {
          formContent[formContentArray[i].attribute_id] = {
            attributeName: formContentArray[i].attribute_name,
            attributeType: formContentArray[i].attribute_type,
            label: formContentArray[i].label,
            optionValues: formContentArray[i].option_values,
            title: formContentArray[i].title,
            required_error_message: formContentArray[i].required_error_message,
            date_compare_error_message:
              formContentArray[i].date_compare_error_message,
            place_holder_text: formContentArray[i].place_holder_text,
          };
        }
        if (response?.data) {
          console.log("response.data in conentstack", response.data);
          let data = {
            uid,
            title: response?.data?.title,
            formContent:
              response?.data?.form_content.length > 1
                ? response?.data?.form_content
                : formContent,
            // response?.data?.form_content[0]?.form_attribute?.reference,
            formDescription: response?.data?.form_description,
            formId: response?.data?.form_id,
            formLayout: response?.data?.form_layout,
            formTitle: response?.data?.form_title,
            nameValue: response?.data?.name_value,
          };
          setPageBlockData(data, uid);
          onLoadCompete();
        } else {
          setError({
            title: "Form not available!",
            message: `'${url}' => Form is not available on Contentstack!`,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("entered error", error);
        setError({
          title: error.response?.data ? error.response?.data : error.message,
          message: error.message ? error.message : error.response?.data,
          status: error.response?.status ? error.response?.status : null,
        });
        setIsLoading(false);
      });
  };

  const loadHeroBlock = (obj) => {
    const uid = obj?.uid;
    const contentType = obj?._content_type_uid;
    const type = obj?.type;
    const index = dataArray.findIndex((item) => item.uid === uid);
    const key = "hero";
    if (index === -1) {
      let dataObj = {
        key,
        uid,
        type,
        contentType,
        block: key,
        data: {},
        isDataLoaded: null,
      };
      dataArray.push(dataObj);
    }
    dispatch(domActions.setPageTitle(obj.title));
    dispatch(domActions.setAnalyticsPageTitle(obj.title));
    let blockInfo = chooseBlock(key, uid, obj);
    if (blockInfo.data !== null) {
      setPageBlockData(blockInfo.data, blockInfo.uid);
    } else {
      //console.log("BLOCK DATA NOT AVAILABLE!!");
    }
    if (blockInfo.error !== null) {
      setPageBlockFailedToLoad(blockInfo.error, blockInfo.uid);
    }
  };

  const loadPageAlertBlock = (obj) => {
    const uid = obj?.uid;
    const contentType = obj?._content_type_uid;
    const type = obj?.type;
    const index = dataArray.findIndex((item) => item.uid === uid);
    const key = "in_page_alert";
    if (index === -1) {
      let dataObj = {
        key,
        uid,
        type,
        contentType,
        block: key,
        data: {},
        isDataLoaded: null,
      };
      dataArray.push(dataObj);
    }
    let blockInfo = chooseBlock(key, uid, obj);
    if (blockInfo.data !== null) {
      setPageBlockData(blockInfo.data, blockInfo.uid);
    } else {
      //console.log("BLOCK DATA NOT AVAILABLE!!");
    }
    if (blockInfo.error !== null) {
      setPageBlockFailedToLoad(blockInfo.error, blockInfo.uid);
    }
  };

  const loadNavigation = (data, uid) => {
    let list = data?.site_nav?.Children;
    let csViewAll = data?.ca_view_all;
    if (list?.length > 0) {
      let data = {
        list,
        csViewAll,
      };
      setPageBlockData(data, uid);
      onLoadCompete();
    } else {
      setError({
        title: "Navigation not available!",
        message: `'${url}' => Navigation list is not available on Contentstack!`,
      });
      setIsLoading(false);
    }
  };

  const loadHeader = (responseData, uid) => {
    let blockInfo = chooseBlock("header", uid, responseData);
    if (blockInfo.data !== null) {
      setPageBlockData(blockInfo.data, blockInfo.uid);
      onLoadCompete();
    } else {
      setError({
        title: "Header not available!",
        message: `'${url}' => Header content is not available on Contentstack!`,
      });
      setIsLoading(false);
    }
  };

  const loadFooter = (responseData, uid) => {
    let blockInfo = chooseBlock("footer", uid, responseData);
    if (blockInfo.data !== null) {
      setPageBlockData(blockInfo.data, blockInfo.uid);
      onLoadCompete();
    } else {
      setError({
        title: "Footer not available!",
        message: `'${url}' => Footer content is not available on Contentstack!`,
      });
      setIsLoading(false);
    }
  };
  const loadSystemErrors = (responseData, uid) => {
    let blockInfo = chooseBlock("globals", uid, responseData);
    if (blockInfo.data !== null) {
      const dataObj = { data: blockInfo.data, isDataLoaded: true };
      setDynamicPageData(dataObj);
      setIsLoading(false);
    } else {
      setError({
        title: "System Error is not available!",
        message: `'${url}' => System Error content is not available on Contentstack!`,
      });
      setIsLoading(false);
    }
  };

  const loadFeedbackForm = (responseData, uid) => {
    console.log("loadFeedbackForm >>");
    let blockInfo = chooseBlock("feedbackForm", uid, responseData);
    if (blockInfo.data !== null) {
      const dataObj = { data: blockInfo.data, isDataLoaded: true };
      setDynamicPageData(dataObj);
      setIsLoading(false);
    } else {
      setError({
        title: "System Error is not available!",
        message: `'${url}' => System Error content is not available on Contentstack!`,
      });
      setIsLoading(false);
    }
  };

  const loadBlocks = (blocks, block) => {
    if (blocks?.length > 0) {
      for (let i = 0; i < blocks.length; i++) {
        let key = Object.keys(blocks[i])[0];
        let blockName = blocks[i][key];
        if (key === "languages") {
          let uid = "lang";
          dataArray.push({
            uid,
            key,
            data: {},
            isDataLoaded: null,
          });
          let blockInfo = chooseBlock(key, uid, blockName);
          if (blockInfo.data !== null) {
            setPageBlockData(blockInfo.data, blockInfo.uid);
          } else {
            //console.log("BLOCK DATA NOT AVAILABLE!!");
          }
          if (blockInfo.error !== null) {
            setPageBlockFailedToLoad(blockInfo.error, blockInfo.uid);
          }
        } else {
          if (key == "horizontal_group") {
            //console.log("horizontal_flag >>>> ", blocks[i][key]);
            if (blocks[i][key]?.horizontal_flag === "start") {
              horizontalGroup = HORIZONTAL_GROUP_START;
              horizontalGroupHeading = blocks[i][key]?.heading;
            } else if (blocks[i][key]?.horizontal_flag === "end") {
              if (
                dataArray[dataArray.length - 1].horizontalGroup ===
                HORIZONTAL_GROUP_START
              ) {
                dataArray[dataArray.length - 1].horizontalGroup +=
                  HORIZONTAL_GROUP_END;
              } else {
                dataArray[dataArray.length - 1].horizontalGroup =
                  HORIZONTAL_GROUP_END;
              }
              horizontalGroup = "";
            }
          } else if (blocks[i][key]?.reference) {
            let uid = blocks[i][key]?.reference[0]?.uid;
            for (let j = 0; j < blockName.reference.length; j++) {
              if (blockName.reference[j] !== null) {
                if (
                  blockName.reference[j]?._content_type_uid !== "accordion_card"
                ) {
                  uid = blockName.reference[j].uid;
                  let contentType = blockName.reference[j]?._content_type_uid;
                  let type = blockName.reference[j]?.type;
                  let description = blockName.reference[j]?.description;
                  const heading = blockName.reference[j]?.heading;
                  const icon = blockName.reference[j]?.icon;
                  const link = blockName.reference[j]?.link;
                  const url = blockName.reference[j]?.link;
                  const locale = blockName.reference[j]?.locale;
                  const title = blockName.reference[j]?.title;
                  // for 401k layout_2 only- not for other
                  const left_col = blockName.reference[j]?.left_col;
                  const right_col = blockName.reference[j]?.right_col;
                  let index = dataArray.findIndex((item) => item.uid === uid);
                  if (index === -1) {
                    let dataObj = {
                      key,
                      uid,
                      type,
                      contentType,
                      block,
                      description,
                      heading,
                      icon,
                      link,
                      url,
                      locale,
                      title,
                      left_col,
                      right_col,
                      data: {},
                      isDataLoaded: null,
                      horizontalGroup,
                      horizontalGroupHeading,
                    };
                    if (horizontalGroup === HORIZONTAL_GROUP_START) {
                      horizontalGroup = HORIZONTAL_GROUP_IN;
                      horizontalGroupHeading = "";
                    }
                    dataArray.push(dataObj);
                  }
                  if (key == "hero") {
                    dispatch(domActions.setPageTitle(title));
                    dispatch(domActions.setAnalyticsPageTitle(title));
                  }
                  let blockInfo = chooseBlock(key, uid, blockName.reference[j]);
                  if (blockInfo.data !== null) {
                    setPageBlockData(blockInfo.data, blockInfo.uid);
                  } else {
                    //console.log("BLOCK DATA NOT AVAILABLE!!");
                  }
                  if (blockInfo.error !== null) {
                    setPageBlockFailedToLoad(blockInfo.error, blockInfo.uid);
                  }
                }
              } else {
                setError({
                  title: "Pageblock not received properly!",
                  message: `'${key}' => Pageblock not received properly from Contentstack!`,
                });
                setIsLoading(false);
              }
            }
          }
        }
      }
      onLoadCompete();
    }
  };

  const setPageBlockData = (data, uid) => {
    let index = dataArray.findIndex((item) => item.uid === uid);

    dataArray[index].data = data;
    dataArray[index].isDataLoaded = true;
    //checkLoadCompete();
  };

  const setPageBlockFailedToLoad = (error, uid) => {
    let index = dataArray.findIndex((item) => item.uid === uid);
    dataArray[index].error = error;
    dataArray[index].isDataLoaded = false;
    //checkLoadCompete();
  };

  const onLoadCompete = () => {
    setInternalSystemType(pageType);
    setDynamicPageData([...dataArray]);
    setIsLoading(false);
  };

  const loadUnauthenticatedNavigation = () => {
    let uid = "dummy";
    dataArray.push({
      uid,
      data: {},
      isDataLoaded: null,
    });
    let data = {
      list: [],
    };
    setPageBlockData(data, uid);
    onLoadCompete();
  };

  useEffect(() => {
      if (url === "") {
        if (contentType == "recognition_badges") {
          loadBadge(contentType);
        } else if (isAuthenticated) loadGlobalElement(contentType);
        else loadUnauthenticatedNavigation();
      } else if (contentType == "form") {
        loadForm(url, contentType);
      } else {
        loadPage(url, contentType);
      }
      return () => {
        setDynamicPageData([]);
        setIsLoading(true);
        setError(null);
      };
  }, [url, language]);

  return {
    data: dynamicPageData,
    loading: isLoading,
    error,
    internalSystemType,
  };
};

export default useFetchData;
