import styles from "./PayslipDetails.module.css";

import { TextLink, Accordion } from "@mgmresorts/mgm-ui";
import React, { useState } from "react";
// import { useSelector } from "react-redux";
import ShowHidePay from "./ShowHidePay";
import AccordionContent from "./AccordionContent";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
//import { setHidePayslip } from "../../../data/gqlDataReducer";
import ChooseCta from "../../../hooks/ChooseCta";

const PayslipDetails = (props) => {
  //const dispatch = useDispatch();
  //console.log("payslip CS data",props);
  const gqlData = useSelector((state) => state.gqlData);
  //console.log("gqlData>", gqlData);
  //console.log("gqlData>", gqlData?.profile?.payRateGroup);
  const navigate = useNavigate();
  let apiData = props.apiData[0].details;

  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );

  let hide = props.hideMyPay;
  let [accordionList, setAccordionList] = useState([
    false,
    false,
    false,
    false,
  ]);
  /*
  const handleHideMyPay = (bool) => {
    dispatch(setHidePayslip(bool));
  };
  */
  const handleAccordionChange = (index) => {
    let tempAcc = [...accordionList];
    tempAcc[index] = !tempAcc[index];
    setAccordionList(tempAcc);
  };

  /*
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  */

  const accordionTitles = [
    { label: props.chartItems.gross, hash: props.chartItems.gross_hash },
    {
      label: props.tab.deductions,
      hash: props.tab.deductions_hash,
    },
    {
      label: props.chartItems.take_home_pay,
      hash: props.chartItems.take_home_pay_hash,
    },
  ];

  const moneySymbol = [{ label: "USD", symbol: "$" }];

  const getCurrencyCode = () => {
    let defaultCode = "$";
    let code = moneySymbol.filter(
      (item) => item.label === apiData.currencyCode
    );
    return code.length === 0 ? defaultCode : code[0].symbol;
  };

  const calculateHours = () => {
    return String(apiData.timesheetHours).split(".")[0];
  };

  const calculateMinutes = () => {
    let splitted = String(apiData.timesheetHours).split(".");
    if (splitted.length == 2) {
      return Math.floor(Number("." + splitted[1]) * 60);
    } else {
      return "0";
    }
  };

  const packedData = {
    date: props.tab.week_of,
    money_symbol: getCurrencyCode(),
    hours: calculateHours(),
    hours_text: props.tab.hrs,
    minutes: calculateMinutes(),
    minutes_text: props.tab.min,
  };

  const formingDate = () => {
    let startYear =
      apiData.startDate.getUTCFullYear() === apiData.endDate.getUTCFullYear()
        ? ""
        : `, ${apiData.startDate.getUTCFullYear()}`;

    let startDate = `${
      globalProperties?.full_month[apiData.startDate.getUTCMonth()]
    } ${apiData.startDate.getUTCDate()} ${startYear}`;
    let endDate = `${
      globalProperties?.full_month[apiData.endDate.getUTCMonth()]
    } ${apiData.endDate.getUTCDate()}, ${apiData.endDate.getUTCFullYear()}`;

    return `${packedData.date} ${startDate} - ${endDate}`;
  };

  const formingIssuedDate = () => {
    let paymentDate = `${
      globalProperties?.full_month[apiData.paymentDate.getUTCMonth()]
    } ${apiData.paymentDate.getUTCDate()}, ${apiData.paymentDate.getUTCFullYear()}`;
    return `${props.tab.issued} ${paymentDate}`;
  };

  const clickHandler = (e, link) => {
    e.preventDefault();
    navigate(link, {
      state: {
        selectedDate: {
          startDate: apiData.startDate,
          endDate: apiData.endDate,
        },
      },
    });
  };

  return (
    <div className={styles.payslipDetails}>
      <div>
        <div className={styles.dateHolder}>
          <p data-cslp={props.tab.week_of_hash}>{formingDate()}</p>
          <div className={styles.downloadIcon}>
            <ChooseCta
              type="text_link"
              data={{
                label: props.externalLinks?.view_all_payslips?.title,
                url: props.externalLinks?.view_all_payslips?.href,
                openIn: "new_window",
                leadingIcon: "arrow-new-window",
                trailingIcon: "",
              }}
              size="large"
            />
          </div>
        </div>
        <div className={styles.issuedDate}>
          <p data-cslp={props.tab.issued_hash}>{formingIssuedDate()}</p>
        </div>
      </div>
      <div className={styles.salary}>
        {
          <label data-cslp={props.tab?.take_home_pay_net_hash}>
            {props.tab?.take_home_pay_net}:
          </label>
        }
        <p>
          {packedData.money_symbol}
          {hide
            ? "x,xxx.xx"
            : (Math.round(apiData.takeHome * 100) / 100).toFixed(2)}
        </p>
      </div>
      {gqlData?.profile?.payRateGroup === "Hourly" && (
        <div className={styles.timesheet}>
          {
            <label data-cslp={props.tab?.timesheet_hash}>
              {props.tab?.timesheet}
            </label>
          }
          <div className={styles.timesheetInner}>
            <div>
              <div>
                <span>{hide ? "xx" : packedData.hours}</span>
                <label data-cslp={props.tab.hrs_hash}>
                  {packedData.hours_text}
                </label>
              </div>
              <div>
                <span>{hide ? "xx" : packedData.minutes}</span>
                <label data-cslp={props.tab.min_hash}>
                  {packedData.minutes_text}
                </label>
              </div>
            </div>
            <TextLink
              href={props.externalLinks?.view_timecard?.href}
              onClick={(e) => clickHandler(e, props.externalLinks?.view_timecard?.href)}
              iconLeft="timer-stopwatch"
              variant="large"
            >
              {props.externalLinks?.view_timecard?.title}
            </TextLink>
          </div>
        </div>
      )}
      <div className={styles.hideMyPay}>
        <ShowHidePay
          hideMyPay={props.hideMyPay}
          handleHideMyPay={props.handleHideMyPay}
          hash={props.hash}
          label={props.hidelabel}
        />
      </div>
      <div className={styles.accordion}>
        {accordionTitles.map((item, index) => (
          <React.Fragment key={index}>
            {index !== 5 && (
              <div
                key={index}
                className={`${styles.accordionBlock} ${
                  !accordionList[index] ? styles.fixedHeight : ""
                }`}
              >
                <Accordion
                  expand={accordionList[index]}
                  onClick={() => handleAccordionChange(index)}
                  title={item.label}
                  data-cslp={item.hash}
                  content={
                    <div className={styles.accordionContent}>
                      {index === 0 &&
                        apiData.gross.map((list, index) => (
                          <AccordionContent
                            key={index}
                            list={list}
                            hide={hide}
                            grossPay={apiData.grossPay}
                            symbol={packedData.money_symbol}
                          />
                        ))}
                      {index === 1 &&
                        apiData.deductions.map((list, index) => (
                          <AccordionContent
                            key={index}
                            list={list}
                            hide={hide}
                            grossPay={apiData.grossPay}
                            symbol={packedData.money_symbol}
                          />
                        ))}
                      {index === 2 &&
                        apiData.takeHomePay.map((list, index) => (
                          <AccordionContent
                            key={index}
                            list={list}
                            hide={hide}
                            grossPay={apiData.grossPay}
                            symbol={packedData.money_symbol}
                          />
                        ))}
                    </div>
                  }
                />
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default PayslipDetails;
