import moment from "moment";
import useWindowDimensions from "../../../hooks/useScreenDetails";
import TimesheetDayInnerRow from "./TimesheetDayInnerRow";

import styles from "./TimesheetDayRow.module.css";
import { useSelector } from "react-redux";

const restrictto2Decimal = (number) => {
  return number !== "" ? parseFloat(number).toFixed(2) : "";
};

const TimesheetDayRow = (props) => {
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );
  const noTimeReported = globalProperties?.no_time_reported
    ? globalProperties?.no_time_reported
    : "No Time Reported";
  const date = moment(props.weekPay.timesheetDate).format("ddd, MMM D, YYYY");

  const [isMobile] = useWindowDimensions();
  return (
    <div>
      <div>
        {(props.isDashboard || isMobile) && (
          <div className={styles.dateDashboard}>{date}</div>
        )}
      </div>
      <div
        className={
          props.isDashboard || isMobile
            ? styles.timesheetDashboard
            : styles.timesheetRow
        }
      >
        {!props.isDashboard && !isMobile && (
          <div className={styles.date}>{date}</div>
        )}
        <div className={styles.innerRowContainer}>
          {props.isNoTimeReported && !props.isPayCode && (
            <div className={`pl-2 pt-1 ${styles.leftValue}`}>
              {noTimeReported}
            </div>
          )}

          {props?.weekPay?.timeSheetRow &&
            props.weekPay.timeSheetRow.map((row, i) => {
              return (
                <TimesheetDayInnerRow
                  key={i}
                  timeRow={row}
                  icons={props.icons}
                  isApproved={props.isApproved}
                  payCodes={props.payCodes}
                  isDateApproved={props.isDateApproved}
                  hours={props.hours}
                  staticData={props?.staticData}
                />
              );
            })}
          {props.isPayCode && (
            <div className={styles.pto}>
              <div className={`pl-2 pt-1 ${styles.leftValue}`}>
                {props.payCodeName}{" "}
              </div>
            </div>
          )}
        </div>
        <div
          className={
            props.weekPay.timeSheetRow.length > 1 || props.isPayCode
              ? styles.totalHours
              : styles.totalHoursMulti
          }
        >
          {restrictto2Decimal(props.hours)}
        </div>
      </div>
    </div>
  );
};

export default TimesheetDayRow;
