import styles from "./SupportLogin.module.css";

import { useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown, DropdownItem, Button } from "@mgmresorts/mgm-ui";

import HeaderLogin from "../../components/global/HeaderLogin";
import FooterLogin from "../../components/global/FooterLogin";
import { useNavigate } from "react-router-dom";
//import axios from "axios";

const SupportLogin = () => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const userTypes = [
    { text: "Hourly", value: "Hourly" },
    { text: "HourlyVR", value: "HourlyVR" },
    { text: "SalaryFTO", value: "SalaryFTO" },
    { text: "Salary", value: "Salary" },
  ];

  let [selectedType, setSelectedType] = useState(null);
  let [dropdownState, setDropdownState] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    if (selectedType != null) {
      sessionStorage.setItem("supportLoginUserType", selectedType.value);
      window.location.href = `${process.env.REACT_APP_BASE_URL}support-login?prefUser=${selectedType.value}`;
      /*
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}support-login?prefUser=${selectedType.value}`
        )
        .catch((error) => {
          console.error("error>>>", error);
        });
        */
    }
  };

  if (isAuthenticated) {
    navigate("/dashboard");
  }

  const openHandler = () => {
    //console.log("openHandler");
    setDropdownState(true);
  };
  const closeHandler = () => {
    //console.log("closeHandler");
    setDropdownState(false);
  };
  const changeHandler = (e, d) => {
    //console.log("changeHandler> ", e, d);
    const inedx = userTypes.findIndex((item) => item.value === d.value);
    setSelectedType(userTypes[inedx]);
    setDropdownState(false);
  };

  return (
    <>
      {!isAuthenticated && (
        <>
          <HeaderLogin />
          <main className={styles.mainBox}>
            <form className={styles.supportLoginForm} onSubmit={onSubmit}>
              <h2>Welcome to My MGM</h2>
              <Dropdown
                hint="Select user type"
                id="dropdown-example"
                label="User type"
                open={dropdownState}
                onChange={(e, d) => {
                  changeHandler(e, d);
                }}
                onClose={(e) => {
                  closeHandler(e);
                }}
                onOpen={(e) => {
                  openHandler(e);
                }}
                selected={selectedType}
              >
                {userTypes.map((item, index) => (
                  <DropdownItem
                    text={item.text}
                    value={item.value}
                    key={index}
                  />
                ))}
              </Dropdown>
              <Button
                className={styles.submitBtn}
                label="Proceed to Login"
                size="large"
                fullWidth={true}
                variant="primary"
                type="submit"
              />
            </form>
          </main>
          <FooterLogin />
        </>
      )}
    </>
  );
};

export default SupportLogin;
