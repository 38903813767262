// import useFetchData from "../../hooks/useFetchData";
import { useMutation } from "@apollo/client";
import { Snackbar } from "@mgmresorts/mgm-ui";
import { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useSelector } from "react-redux";
// import {useParams} from "react-router-dom";
// import { setLaunchContest } from "../../data/gqlDataReducer";

import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
// import { useQuery } from "@apollo/client";
import ErrorHanding from "../../pages/ErrorHandling/ErrorHanding";
import Loader from "../../components/global/Loader";
// import CTATextLink from "./CTATextLink";
import CTATextLink from "../dynamic/CTATextLink";
import { Button } from "@mgmresorts/mgm-ui";
// import GET_USER_ENTERED_CONTEST from "../../features/api-wrapper/apiQueries";
import {
  //   GET_USER_ENTERED_CONTEST,
  SET_USER_ENTERED_CONTEST,
} from "../../features/api-wrapper/apiQueries";
import InnerHtml from "../../components/dynamic/InnerHtml";
// import { TextLink } from "@mgmresorts/mgm-ui";
// import CTAButton from "../dynamic/CTAButton";
import styles from "./ProfileLaunchContest.module.css";

function ProfileLaunchContest(props) {
  const [snackbar, setSnackbar] = useState(false);
  const [enteredContest, setEnteredContest] = useState(false);
  const [toasterMessage, setToasterMessage] = useState("");

  // console.log('props!@', props.data.data[0][3])

  const [mutateEnteredContest, { data: data1, loadin, error: errorSet }] =
    useMutation(SET_USER_ENTERED_CONTEST, { client: apiMiddlewareClient });

  // console.log('data1 testing!!!', data1)

  const description = props.data
    ? props.data.data[0][0].html_value
      ? props.data.data[0][0].html_value
      : props.data.data[0][0].value
    : "";
  const enterButton = props.data
    ? props.data.data[0][1].value
      ? props.data.data[0][1].value
      : props.data.data[0][1].html_value
    : "";

  const setToasterMessageFunc = (hasNotEntered) => {
    if (hasNotEntered === false) {
      setToasterMessage(props.data
        ? props.data.data[0][3].value
          ? props.data.data[0][3].value
          : props.data.data[0][3].html_value
        : "")
    } else {
      setToasterMessage(props.data
        ? props.data.data[0][2].value
          ? props.data.data[0][2].value
          : props.data.data[0][2].html_value
        : "")
    }
  };


  useEffect(() => {
    if (snackbar) {
      const timer = setTimeout(() => {
        setSnackbar(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [snackbar]);

  const date = new Date();
  let currentDay = String(date.getDate()).padStart(2, "0");
  let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
  let currentYear = date.getFullYear();

  // we will display the date as DD-MM-YYYY
  let currentDate = `${currentDay}/${currentMonth}/${currentYear}`;

  const enterBtnClicked = (e) => {
    e.preventDefault();
    setEnteredContest(true);
    mutateEnteredContest({
      variables: {
        systemDate: currentDate,
      },
    }).then((res) => {
      setToasterMessageFunc(res.data.contestLaunchEvent.userContestParticipatedStatus);
      // console.log("res", res);  
      setSnackbar(true);
    });
    console.log("data1 testing!!! in btn", data1);
  };
  if (loadin) return <Loader />;
  if (errorSet) return <ErrorHanding error={errorSet} />;

  return (
    <>
      <div className={styles.launchContestWrapper}>
        {snackbar ? (
          <Snackbar
            className="snackbar-box-launch-contest"
            open
            message={toasterMessage}
            variant="two-line"
          />
        ) : (
          ""
        )}
        {enteredContest === false && (
          <div className={styles.launchContestContainer}>
            <div className={styles.headerWrapper}>
              <img
                className="col-1"
                src={props.data?.headerIcon}
                alt="promotional icon"
              />
              <h2 className="col-12">{props.data?.headerTitle}</h2>
            </div>

            <div className={styles.description}>
              <InnerHtml description={description} style={styles.innerHtml} />
            </div>
            <div className={styles.linkContainer}>
              <CTATextLink
                size="small"
                iconRight="arrow-right"
                type="text_link"
                style={styles.readRulesLink}
                data={{
                  label: props.data?.link1[0]?.link?.title,
                  url: props.data?.link1[0]?.link?.href,
                  type: "secondary",
                  openIn: props.data?.link1[0]?.open_in,
                  trailingIcon: props.data?.link1[0]?.trailing_icon?.icon
                    ? props.data.link1[0]?.trailing_icon?.icon
                    : "a",
                }}
              />
              <Button
                className={styles.link2}
                label={enterButton}
                variant="primary"
                inverted={true}
                onClick={(e) => {
                  enterBtnClicked(e);
                }}
              ></Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ProfileLaunchContest;
