import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CStyle from "./CustomCarousal.module.css";
import "./CustomStyleParentCarousal.css";
import { useState } from "react";
import ChooseCta from "../../../hooks/ChooseCta";
import moment from "moment";
import { useSelector } from "react-redux";

const CustomCarousal = ({ carouselData }) => {
  const { children: carousalData } = carouselData;
  const [isToggle, setIsToggle] = useState(false);

  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );

  return (
    <div className={CStyle.cholder}>
      {!isToggle ? (
        <Carousel
          showArrows={false}
          autoPlay={true}
          infiniteLoop={true}
          showStatus={false}
          showThumbs={false}
          interval={10000}
        >
          {carousalData &&
            carousalData.length > 0 &&
            carousalData.map((obj) => {
              let dateNum = (new Date(obj.date)).getMonth(); 
              const dateValue = `${globalProperties?.short_month[dateNum]} ${moment(obj.date).format("D, YYYY")}`;
              return (
                <div className="row" key={obj.uid}>
                  <div className={`col-12 col-md-6 ${CStyle.noRightPadding}`}>
                    <img src={obj.image} alt={`carouse-image-${obj.uid}`} />
                  </div>
                  <div className="col-12 col-md-6 p-l-16">
                    <div className="row row-h pb-1">
                      <div className="col-8 text-left">{dateValue}</div>
                      <div className="col-4">
                        <div className={CStyle.hideHolder}>
                          <button onClick={() => setIsToggle(!isToggle)}>
                            <span className={CStyle.textUnderline}>{globalProperties?.hide}</span>
                            &nbsp;
                            <span className={CStyle.eyeHolder}></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-8">
                      <h3 className="title">{obj.heading}</h3>
                    </div>

                    <div className={CStyle.slideBody}>
                      {obj.shortDescription}
                    </div>
                    {obj.ctaEnabled && (
                      <div className={CStyle.learnMoreHolder}>
                        <ChooseCta
                          style={CStyle.global_learnmorebtn_s}
                          data={obj.cta}
                          size="small"
                          type="secondary"
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </Carousel>
      ) : (
        <div className={CStyle.hideWrapper}>
          <div className="col-12">
            <div className="row row-h pb-1" style={{ alignItems: "center" }}>
              <h3 className={`col-8 text-left ${CStyle.title}`}>
                {carousalData[0].heading}
              </h3>
              <div className="col-4">
                <div className={`${CStyle.showHolder} text-right`}>
                  <button onClick={() => setIsToggle(!isToggle)}>
                    <span className={CStyle.textUnderline}>{globalProperties?.show}</span>
                    &nbsp;<span className={CStyle.eyeHolder}></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomCarousal;
