import styles from './TabPanel.module.css';

// This component should be used along the Tabs component,
// it uses the same pattern for `id` and `aria-labelledby`
const TabPanel = ({ selectedType, id, children, ...rest }) => {

  const isSelected = selectedType === id;

  return (
    <div
      role="tabpanel"
      id={`tabpanel-${id}`}
      aria-labelledby={`tab-${id}`}
      tabIndex={isSelected ? 0 : -1}
      className={`${isSelected ? styles.tabPanel : styles.displayNonePanel} ${rest.styles}`}
    >
      {children}
    </div>
  );
};

export default TabPanel;
