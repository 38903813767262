import styles from "./Loader.module.css";
import loader from "../../assets/images/progress-circle.svg";
import { useSelector } from "react-redux";
import { Fragment } from "react";

const Loader = (props) => {
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );
  
  return (
    <Fragment>
      {globalProperties && (
        <div
          className={`${styles.loaderHolder} ${
            props.styling === "inline" ? styles.inline : ""
          }`}
        >
          <div>
            <img src={loader} />
            <p>
              {props.loadingText !== undefined
                ? props.loadingText
                : globalProperties?.loading
                ? `${globalProperties?.loading}...`
                : "Loading..."}
            </p>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Loader;
