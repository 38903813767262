import { useMutation } from "@apollo/client";
import { Button,  } from "@mgmresorts/mgm-ui";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFavourites } from "../../data/gqlDataReducer";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import {
  ADD_FAVOURITES,
  REMOVE_FAVOURITES,
} from "../../features/api-wrapper/apiQueries";
import styles from "./Bookmark.module.css";
// import Loader from "./Loader";

const Bookmark = () => {
  const selectedNavigation = useSelector(
    (state) => state.navigation.selectedNavigation
  );
  const bookmarkDescription = useSelector(
    (state) => state.bookmarkDescription
  );
  const gqlData = useSelector((state) => state.gqlData);
  const { profile = {} } = gqlData;
  const { favourites } = profile;
  const [isBMSelected, setIsBMSelected] = useState(false);
  // const [mutateFunctionAdd, { data: addFavouriteInfo, loading, error }] =
  //   useMutation(ADD_FAVOURITES, { client: apiMiddlewareClient });
  const [mutateFunctionAdd, { data: addFavouriteInfo, error }] = useMutation(
    ADD_FAVOURITES,
    { client: apiMiddlewareClient }
  );
  // const [
  //   mutateFunctionRemove,
  //   { data: removeBookMarkInfo, loading: loadingRemove, error:errorRemove },
  // ] = useMutation(REMOVE_FAVOURITES, { client: apiMiddlewareClient });
  // const dispatch = useDispatch();
  const [
    mutateFunctionRemove,
    { data: removeBookMarkInfo, error: errorRemove },
  ] = useMutation(REMOVE_FAVOURITES, { client: apiMiddlewareClient });
  const dispatch = useDispatch();

  // get last record
  const selectedLink = selectedNavigation[selectedNavigation.length - 1];

  useEffect(() => {
    if (selectedNavigation && selectedNavigation.length > 0 && favourites) {
      const isSelected = favourites.filter((fav) => {
        return fav.url == selectedLink.absoluteLink;
      });
      setIsBMSelected(isSelected.length > 0 ? true : false);
    }
  }, [selectedNavigation, favourites]);

  useEffect(() => {
    if (addFavouriteInfo && addFavouriteInfo.addFavourite.status == 200) {
      const { data } = addFavouriteInfo.addFavourite;
      dispatch(setFavourites([...gqlData.profile.favourites, data]));
    }
  }, [addFavouriteInfo]);

  useEffect(() => {
    if (removeBookMarkInfo && favourites) {
      const remainingRecords = favourites.filter((fav) => {
        return fav.url !== selectedLink.absoluteLink;
      });
      dispatch(setFavourites(remainingRecords));
    }
  }, [removeBookMarkInfo]);

  const bookMarkClicked = () => {
    if (isBMSelected) {
      mutateFunctionRemove({
        variables: {
          pageUrl: selectedLink.absoluteLink,
        },
      });
    } else {
      mutateFunctionAdd({
        variables: {
          pageUrl: selectedLink.absoluteLink,
          title: selectedLink.label,
          description: bookmarkDescription,
          subTitle:
            selectedNavigation.length >= 3
              ? `${selectedNavigation[0].label} > ${selectedNavigation[1].label}`
              : `${selectedNavigation[0].label}`,
        },
      });
    }
  };

  
  if (error || errorRemove) return <p>Error : {error.message}</p>;

  return (
    <>
      <Button
        className={isBMSelected ? styles.selectedBookmark : styles.bookmark}
        onClick={() => {
          bookMarkClicked();
        }}
        variant="icon-outlined"
        icon="heart"
        inverted={isBMSelected ?true:false}
        // iconProps={{size:"large"}}
      >
        {/* <Icon
          name="heart"
          variant="outlined"
          size="small"
          role="img"
          color={isBMSelected ? "#FFFFFF" : ""}
          alt="heart"
        /> */}
      </Button>
    </>
  );
};

export default Bookmark;
