import ChooseCta from "../../hooks/ChooseCta";
import quickaction from "./QuickActions.module.css";

const QuickActions = ({ quickActionData = {} }) => {
  const { children: quickActionTabs, heading } = quickActionData || {};

  return (
    <>
      <div className={`${quickaction.actionscontainer} row`}>
        <h3 className={quickaction.actionheader}>
          {heading || "Quick actions"}
        </h3>
        <div className={quickaction.actionsection}>
          {quickActionTabs.map((val, index) => {
            return (
              <ChooseCta
                key={index}
                style={quickaction.actiontab}
                data={val.cta}
                type="text_link"
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default QuickActions;
