import styles from "./ErrorCard.module.css";
import { Button } from "@mgmresorts/mgm-ui";
import { useNavigate } from "react-router-dom";
import { navigationActions } from "../../data/navigation-slice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import CTAButton from "../../components/dynamic/CTAButton";

const ErrorCard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pageNotFound = true;
  useEffect(() => {
    dispatch(navigationActions.setBreadcrumbVisibility(false));
    return () => {
      // Clean up the side effect here...
      dispatch(navigationActions.setBreadcrumbVisibility(true));
    };
  }, []);

  return (
    <div className={styles.container}>
      {props.image && <img src={props.image} />}
      <h2>{props.title}</h2>
      <p dangerouslySetInnerHTML={{ __html: props.message }}></p>

      <div className={styles.buttonHolder}>
        {props.cta ? (
          <CTAButton
            pageNotFound={pageNotFound}
            data={props.cta}
            size="small"
            type="primary"
          ></CTAButton>
        ) : (
          <Button
            label="Go to Dashboard"
            size="small"
            variant="primary"
            onClick={() => navigate("/dashboard")}
          />
        )}
      </div>
    </div>
  );
};

export default ErrorCard;
