import { useEffect, useMemo, useState } from "react";
import AttendanceCard from "../../components/common/AttendanceCard";
import "../../styles/attendance.css";

import { Tab } from "@mgmresorts/mgm-ui";
import { useSelector } from "react-redux";
import AttendanceHistory from "../../components/common/AttendanceHistory";
import GetDynamicComponent from "../../hooks/GetDynamicComponent";
import GetRightRailComponent from "../../hooks/GetRightRailComponent";
import useScreenDetails from "../../hooks/useScreenDetails";
import styles from "./Attendance.module.css";
import moment from "moment";
import 'moment/locale/es';

const Attendance = (props) => {
  const data = props.data;
  const gqlData = useSelector((state) => state.gqlData);
  const [selectedLocationTab, setSelectedLocationTab] = useState(0);
  const [location, setLocation] = useState(" ");
  const [, isSpecifiedSize] = useScreenDetails(1100);
  const [apiData, setApiData] = useState([]);

  const language = useSelector(
    (state) => state.gqlData?.profile?.preferredLanguage
  );
  if(language)
  {
    moment.locale(language);
    
  }
  
  let [apiReady, setApiReady] = useState(false);
  let [apiIds, setApiIds] = useState([-1, -1, -1]);
  console.log("apiIds >> ", apiIds);

  const pageHeaderBlockItems = useMemo(() => {
    return data.filter(
      (item) => item.block === "hero" || item.block === "in_page_alert"
    );
  }, [data]);

  const pageBlockItems = useMemo(() => {
    return data.filter((item) => item.block === "page_block");
  }, [data]);

  const rightRailBlockItems = useMemo(() => {
    return data.filter((item) => item.block === "right_rail_block");
  }, [data]);

  const pageApiBlockItems = useMemo(() => {
    return data.filter((item) => item.block === "api_blocks");
  }, [data]);

  useEffect(() => {
    console.log("pageApiBlockItems>>>>> ", pageApiBlockItems);
    if (pageApiBlockItems.length > 0) {
      const attenId = pageApiBlockItems.findIndex(
        (item) => item.type === "attendance"
      );
      const historyId = pageApiBlockItems.findIndex(
        (item) => item.type === "attendance_history"
      );
      if (attenId !== -1 && historyId !== -1) {
        console.log();
        setApiIds([attenId, historyId]);
        setApiReady(true);
      }
    }
  }, [pageApiBlockItems]);

  useEffect(()=>{
    console.log("pageApiBlockItems[apiIds[1]] >>>>> ", pageApiBlockItems[apiIds[1]]);
  }, [apiIds])

  const handleCallback = (childData) => {
    setLocation(childData);
  };

  const handleLocationTab = (item, index) => {
    setSelectedLocationTab(index);
    setLocation(item);
  };
  const handleApiData = (childData) => {
    setApiData(childData);
  };
  if (
    gqlData.profile &&
    gqlData.profile.isMultiplePropertyEmployee &&
    location === " "
  ) {
    if (apiData[0]) {
      setLocation(apiData[0].locationDescription);
    }
  }

  const locationChange = (e) => {
    const index = apiData.findIndex(
      (item) => item.locationDescription === e.target.value
    );

    setSelectedLocationTab(index);
    setLocation(apiData[index].locationDescription);
  };

  const Mobview = () => {
    return (
      <div>
        {gqlData.profile && gqlData.profile.isMultiplePropertyEmployee ? (
          <div className={styles.locationDropdown}>
            <select onChange={locationChange}>
              {apiData.map((item, index) => (
                <option
                  selected={location == item.locationDescription}
                  key={index}
                  value={item.locationDescription}
                >
                  {item.companyName.replace(", LLC", "")}
                </option>
              ))}
            </select>
          </div>
        ) : (
          ""
        )}
        {apiReady && (
          <AttendanceCard
            attendanceCard={styles.attendanceCard}
            attdata={pageApiBlockItems[apiIds[0]]}
            apiData={handleApiData}
            selectedLocationTab={selectedLocationTab}
            setLocation={handleCallback}
            location={location}
            setMobLocation={handleCallback}
            mobLocation={location}
            isMultiplePropertyEmployee={
              gqlData.profile ? gqlData.profile.isMultiplePropertyEmployee : ""
            }
          />
        )}
        {apiReady && (
          <AttendanceHistory
            attendanceHistory={styles.attendanceHistory}
            attData={pageApiBlockItems[apiIds[1]]}
            location={location}
            isMultiplePropertyEmployee={
              gqlData.profile ? gqlData.profile.isMultiplePropertyEmployee : ""
            }
          />
        )}
        {rightRailBlockItems.map((item, index) => (
          <section key={index}>
            <GetRightRailComponent
              comp={item.key}
              data={item.data}
              error={item.error}
            />
          </section>
        ))}
        {pageBlockItems.map((item, index) => (
          <section key={index}>
            <GetDynamicComponent
              comp={item.key}
              data={item.data}
              error={item.error}
            />
          </section>
        ))}
      </div>
    );
  };

  const Desktopview = () => {
    return (
      <>
        {gqlData.profile && gqlData.profile.isMultiplePropertyEmployee ? (
          <div>
            <div
              className={
                apiData[0] && apiData[0].locationDescription
                  ? styles.tabLocationHolderAtt
                  : ""
              }
            >
              {apiData.map((item, index) => (
                <Tab
                  key={index}
                  label={item.companyName.replace(", LLC", "")}
                  className={`${styles.tabLocationItem} ${
                    selectedLocationTab === index ? styles.active : ""
                  }`}
                  onClick={() =>
                    handleLocationTab(item.locationDescription, index)
                  }
                />
              ))}
            </div>
          </div>
        ) : (
          ""
        )}
        <section className={styles.pageContent}>
          <section className={styles.leftContent}>
            <div>
              {apiReady && (
                <AttendanceCard
                  attendanceCard={styles.attendanceCard}
                  attdata={pageApiBlockItems[apiIds[0]]}
                  apiData={handleApiData}
                  selectedLocationTab={selectedLocationTab}
                  setLocation={handleCallback}
                  location={location}
                  isMultiplePropertyEmployee={
                    gqlData.profile
                      ? gqlData.profile.isMultiplePropertyEmployee
                      : ""
                  }
                />
              )}
              {pageBlockItems.map((item, index) => (
                <section key={index}>
                  <GetDynamicComponent
                    comp={item.key}
                    data={item.data}
                    error={item.error}
                  />
                </section>
              ))}
            </div>
          </section>
          <section className={styles.rightContent}>
            {apiReady && (
              <AttendanceHistory
                attendanceHistory={styles.attendanceHistory}
                attData={pageApiBlockItems[apiIds[1]]}
                location={location}
                isMultiplePropertyEmployee={
                  gqlData.profile
                    ? gqlData.profile.isMultiplePropertyEmployee
                    : ""
                }
              />
            )}
            {rightRailBlockItems.map((item, index) => (
              <section key={index}>
                <GetRightRailComponent
                  comp={item.key}
                  data={item.data}
                  error={item.error}
                />
              </section>
            ))}
          </section>
        </section>
      </>
    );
  };

  return (
    <>
      <div className={styles.attendanceHolder}>
      {pageHeaderBlockItems.map((item, index) => (
          <section className={styles.headerBlocks} key={index}>
            <GetDynamicComponent
              comp={item.key}
              data={item.data}
              error={item.error}
            />
          </section>
        ))}
        {isSpecifiedSize ? Mobview() : Desktopview()}
      </div>
    </>
  );
};

export default Attendance;
