import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import imageUnderDevelopment from "../../assets/images/icon_coming_soon.svg";
import ErrorCard from "./ErrorCard";
import styles from "./PageUnderDevelopment.module.css";

const PageUnderDevelopment = () => {
  const [errorData, setErrorData] = useState();
  const globalNotification = useSelector((state) => state.globalNotification);

  useEffect(() => {
    if (globalNotification.systemErrors) {
      const { systemErrors } = globalNotification || {};
      const pageNotFound = systemErrors.filter((err) => {
        return err.type == "coming_soon";
      });
      setErrorData(pageNotFound[0]);
    }
  }, [globalNotification]);

  return (
    <div className={styles.pageUnderDevelopmentHolder}>
      {errorData && (
        <ErrorCard
          image={errorData.image?.url || imageUnderDevelopment}
          title={errorData.heading}
          message={errorData.description}
          cta={errorData.cta}
        />
      )}
    </div>
  );
};

export default PageUnderDevelopment;
