import styles from "./Payslip.module.css";

import LocationSelection from "./LocationSelection";
import useLivePreview from "../../../hooks/useLivePreview";
import { livePreviewInfo } from "../../../utils/live-preview";
import GetDynamicComponent from "../../../hooks/GetDynamicComponent";
import GetRightRailComponent from "../../../hooks/GetRightRailComponent";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

const Payslip = (props) => {
  const { data } = props;
  //console.log("data>>> ", data);
  let [apiReady, setApiReady] = useState(false);
  let [apiData, setApiData] = useState();
  let tabs, chartItems, externalLinks;
  const gqlData = useSelector((state) => state.gqlData);
  const isMultiplePropertyEmployee =
    gqlData?.profile?.isMultiplePropertyEmployee;

  const pageHeaderBlockItems = useMemo(() => {
    return data.filter(
      (item) => item.block === "hero" || item.block === "in_page_alert"
    );
  }, [data]);

  const pageBlockItems = useMemo(() => {
    return data.filter((item) => item.block === "page_block");
  }, [data]);

  const pageApiBlockItems = useMemo(() => {
    return data.filter((item) => item.block === "api_blocks");
  }, [data]);

  const rightRailBlockItems = useMemo(() => {
    return data.filter((item) => item.block === "right_rail_block");
  }, [data]);

  useEffect(() => {
    if (
      pageApiBlockItems.length > 0 &&
      pageApiBlockItems[0].type === "payslip"
    ) {
      setApiValues();
      setApiReady(true);
    }
  }, [pageApiBlockItems]);

  const getTab = (array) => {
    let obj = {};
    for (let i = 0; i < array.length; i++) {
      obj[array[i].name] = array[i].value;
      if (array[i].hash) obj[array[i].name + "_hash"] = array[i].hash;
      if (array[i].$?.value["data-cslp"])
        obj[array[i].name + "_hash"] = array[i].$?.value["data-cslp"];
    }
    return obj;
  };

  const getTabs = () => {
    return [
      getTab(pageApiBlockItems[0]?.data?.dictionary),
      getTab(pageApiBlockItems[0]?.data?.dictionary),
    ];
  };

  const getChartItems = () => {
    return getTab(pageApiBlockItems[0]?.data?.dictionary_with_icon);
  };

  const getExternalLinks = (array) => {
    let obj = {};
    for (let i = 0; i < array.length; i++) {
      obj[array[i].name] = array[i].link;
    }
    return obj;
  };

  const setApiValues = () => {
    tabs = getTabs();
    chartItems = getChartItems();
    externalLinks = getExternalLinks(
      pageApiBlockItems[0]?.data?.links_dictionary
    );
    //console.log("externalLinks >>>>>", externalLinks);
    setApiData({
      tabs,
      chartItems,
      externalLinks,
    });
  };

  if (livePreviewInfo.onLivePreview) {
    useLivePreview(props.data);
  }

  return (
    <div className={styles.payslipHolder}>
      {pageHeaderBlockItems.map((item, index) => (
        <section className={styles.headerBlocks} key={index}>
          <GetDynamicComponent
            comp={item.key}
            data={item.data}
            error={item.error}
          />
        </section>
      ))}
      <section className={styles.pageContent}>
        <section className={styles.leftContent}>
          {apiReady && (
            <LocationSelection
              tabs={apiData.tabs}
              chartItems={apiData.chartItems}
              externalLinks={apiData.externalLinks}
              isMultiplePropertyEmployee={isMultiplePropertyEmployee}
            />
          )}
          {pageBlockItems &&
            pageBlockItems.map((item, index) => (
              <section key={index}>
                <GetDynamicComponent
                  comp={item.key}
                  data={item.data}
                  error={item.error}
                />
              </section>
            ))}
        </section>
        <section className={styles.rightContent}>
          {rightRailBlockItems.map((item, index) => (
            <section key={index}>
              <GetRightRailComponent
                comp={item.key}
                data={item.data}
                error={item.error}
              />
            </section>
          ))}
        </section>
      </section>
    </div>
  );
};

export default Payslip;
