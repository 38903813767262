export let livePreviewInfo = {
  onLivePreview: false,
  contentTypeUid: null,
  entryUid: null,
  hash: null,
};

const getMessage = (e) => {
  console.log(e.data.type);
  if (e.data.type === "init-ack") {
    livePreviewInfo.onLivePreview = true;
    livePreviewInfo.contentTypeUid = e.data.data.contentTypeUid;
    livePreviewInfo.entryUid = e.data.data.entryUid;
  } else if (e.data.type === "client-data-send") {
    livePreviewInfo.hash = e.data.data.hash;
  } else if (e.data.type === "check-entry-page") {
    livePreviewInfo.onLivePreview = false;
    window.removeEventListener("message", getMessage, true);
  }
};

window.addEventListener("message", getMessage, true);