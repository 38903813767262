// import moment from "moment";
import { Button } from "@mgmresorts/mgm-ui";
import styles from "./OffersEmpCard.module.css";

const OffersEmpCard = (props) => {
  return (
    <>
      <div className={styles.tile} onClick={() => props.onClick(props.data)}>
        <div
          className={styles.image}
          style={{
            background: `url("${props.data?.image?.url}")`,
            position: "relative",
          }}
        >
          {props.data?.limited_time_offer && (
            <>
              <p className={styles.limitedTimeOffer}>
                {
                  props.name_value.filter(
                    (d) => d.name == "limited_time_offers"
                  )[0].value
                }
              </p>
            </>
          )}
          {/*<img data-cslp={props.data?.$?.image} src={props.data?.image?.url} />*/}
        </div>

        <div
          className={`${styles.content} ${props.data?.ctaEnabled ? styles.withCta : ""
            }`}
        >
          {/* <p>
            {" "}
            {props.data.publish_details ? (
              moment(props.data.publish_details.time).format("MMMM D, YYYY")
            ) : (
              <></>
            )}
          </p> */}
          <h3 data-cslp={props.data?.$?.heading}>{props.data?.heading}</h3>
          <div className={styles.description}>
            {props.data?.short_description}
          </div>
          {props.data?.ctaEnabled && <div><Button
            label={
              props.data.ctaEnabled
            }
            className={styles.cardBtn}
            variant="secondary"
            onClick={() => props.onClick(props.data)}
          /></div>}
        </div>
      </div>
    </>
  );
};

export default OffersEmpCard;
