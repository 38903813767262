import { CardContainer } from "@mgmresorts/mgm-ui";
import styles from "./MyScheduleCard.module.css";
import moment from "moment";
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";

const MyScheduleCard = (props) => {
  const { heading, data, notes } = props.apiData || {};
  let [isHide, setIsHide] = useState(true);

  const language = useSelector(
    (state) => state.gqlData?.profile?.preferredLanguage
  );

  const locationDetails = (row) => {
    return (
      <Fragment>
        {(row.location || row.site || row.role) && (
          <div className={styles.locationWrapper}>
            {row.location && <div className={styles.Loc}>{row.location}</div>}
            {(row.site || row.role) && (
              <div className={styles.Floor}>
                {row.site}{" "}
                {row.role && row.site
                  ? `| ${row.role}`
                  : row.role
                  ? row.role
                  : ""}
              </div>
            )}
          </div>
        )}
        {row.isWaitingTime && (
          <div className={styles.waitConfirm}>
            {props.csData?.dictionary_with_icon[0]?.value}
          </div>
        )}
      </Fragment>
    );
  };

  if (language) {
    moment.locale(language);
  }

  const clickAccordion = () => {
    setIsHide(!isHide);
  };

  return (
    <>
      <CardContainer className={styles.cardContainer}>
        <div className={styles.cardContainerInner}>
          <div className={styles.profileContainer}>
            <div className={styles.profileIcon}>
              {heading && (
                <button className={styles.ScheduleDay}>
                  {heading.toUpperCase()}
                </button>
              )}
            </div>
            <div className={styles.myScheduleBlock}>
              {data &&
                data.length > 0 &&
                data.map((row, index) => {
                  return (
                    <div key={index}>
                      {!row.overnight ? (
                        <Fragment>
                          <div className={styles.Time}>
                            {row.leaves
                              ? row.leaves
                              : props.is24Hour
                              ? `${row.startTime24} to ${row.endTime24}`
                              : `${row.startTime12} to ${row.endTime12}`}
                          </div>
                          <div className={styles.Date}>
                            {moment.utc(row.startDate).format("MMM D, YYYY")}
                          </div>
                          {locationDetails(row)}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <div className={styles.Time}>
                            {props.is24Hour
                              ? `${row.startTime24} ${props.csData?.dictionaryLables?.start}`
                              : `${row.startTime12} ${props.csData?.dictionaryLables?.start}`}{" "}
                            <span className={styles.pipe}>| </span>
                            <span className={styles.overnight}>
                              {" "}
                              {props.csData?.dictionaryLables.overnight}
                            </span>
                          </div>
                          <div className={styles.Date}>
                            {moment.utc(row.startDate).format("MMM D, YYYY")}
                          </div>
                          <div className={styles.Time}>
                            {props.is24Hour
                              ? `${row.endTime24} ${props.csData?.dictionaryLables?.end}`
                              : `${row.endTime12} ${props.csData?.dictionaryLables?.end}`}
                          </div>
                          {locationDetails(row)}
                        </Fragment>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
          {notes && (
            <div className={styles.scheduleNotesSection}>
              <div
                className={styles.scheduleNotes}
                aria-expanded={!isHide}
                aria-controls="ID"
                type="button"
                onClick={clickAccordion}
              >
                {props.csData?.dictionaryLables.notes}
              </div>
              {!isHide && (
                <div
                  id="sect1"
                  role="region"
                  aria-labelledby="accordion1id"
                  className={styles.accordionPanel}
                >
                  {notes}
                </div>
              )}
            </div>
          )}
        </div>
      </CardContainer>
    </>
  );
};

export default MyScheduleCard;
