import styles from "./LocationWise.module.css";

import { useEffect, useState } from "react";
import { Tab } from "@mgmresorts/mgm-ui";

import PayslipDetails from "./PayslipDetails";
import PayslipChart from "./PayslipChart";

const LocationWise = (props) => {
  let previousData = props.apiData.filter((item) => item.period === "previous");
  let currentData = props.apiData.filter((item) => item.period === "current");
  let hasPreviousData = previousData.length === 0 ? false : true;
  let hasCurrentData = currentData.length === 0 ? false : true;
  let tabs = props.tabs;
  let [selectedTab, setSelectedTab] = useState(0);

  

  useEffect(() => {
    // setSelectedTab(hasCurrentData ? 0 : 1);
  }, [props]);

  const handleTab = (index) => {
    setSelectedTab(index);
  };

  return (
    <div>
      <div className={styles.tabHolder}>
        <Tab
          disabled={!hasCurrentData}
          data-cslp={tabs[0]?.most_recent_hash}
          label={tabs[0]?.most_recent}
          className={`${styles.tabItem} ${
            selectedTab === 0 ? styles.active : ""
          }`}
          onClick={() => handleTab(0)}
        />{" "}
        <Tab
          disabled={!hasPreviousData}
          data-cslp={tabs[1]?.previous_hash}
          label={tabs[1]?.previous}
          className={`${styles.tabItem} ${
            selectedTab === 1 ? styles.active : ""
          }`}
          onClick={() => handleTab(1)}
        />
        <Tab />
      </div>
      {hasCurrentData && (
        <div
          className={`${styles.detailsHolder} ${
            selectedTab === 1 ? styles.hideDetails : ""
          }`}
        >
          <PayslipDetails
            hideMyPay={props.hideMyPay}
            apiData={currentData}
            tab={tabs[0]}
            handleHideMyPay={props.handleHideMyPay}
            hidelabel={props.hidelabel}
            hash={props.hash}
            externalLinks ={props.externalLinks}
            chartItems={props.chartItems}
          />
        </div>
      )}
      {hasPreviousData && (
        <div
          className={`${styles.detailsHolder} ${
            selectedTab === 0 ? styles.hideDetails : ""
          }`}
        >
          <PayslipDetails
            hideMyPay={props.hideMyPay}
            handleHideMyPay={props.handleHideMyPay}
            hidelabel={props.hidelabel}
            hash={props.hash}
            apiData={previousData}
            tab={tabs[1]}
            externalLinks ={props.externalLinks}
            chartItems={props.chartItems}
          />
        </div>
      )}
      {hasPreviousData && hasCurrentData && (
        <PayslipChart
          tab={tabs[0]}
          hideMyPay={props.hideMyPay}
          apiData={props.apiData}
          title={props.chartItems.compare_your_pay}
          titleHash={props.chartItems.compare_your_pay_hash}
        />
      )}
    </div>
  );
};

export default LocationWise;
