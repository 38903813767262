//import { useMutation } from "@apollo/client";
//import { apiMiddlewareClient } from "../../../features/api-wrapper/apiMiddlewareWrapper";
//import { SET_HIDE_PAY } from "../../../features/api-wrapper/apiQueries";
//import Loader from "../../../components/global/Loader";
//import APIError from "../../ErrorHandling/APIError";
import { Toggle } from "@mgmresorts/mgm-ui";


const ShowHidePay = (props) => {
  /*
  const [mutateFunction, { loading, error }] = useMutation(SET_HIDE_PAY, {
    client: apiMiddlewareClient,
    fetchPolicy: "no-cache",
  });
  */

  const handleHideMyPay = () => {
    let bool = !props.hideMyPay;
    props.handleHideMyPay(bool);
    /*
    mutateFunction({
      variables: { status: bool },
    });
    */
  };

  /*
  if (loading) return <Loader styling="inline" loadingText="Updating..." />;
  if (error) return <APIError error={error}/>;
  //console.log("data", data);
  */

  return (
    <div onClick={handleHideMyPay}>
      <div>
        <Toggle checked={props.hideMyPay} />
      </div>
      <span data-cslp={props.hash}>{props.label}</span>
    </div>
  );
};

export default ShowHidePay;
