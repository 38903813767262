import { Icon } from "@mgmresorts/mgm-ui";
import ChooseCta from "../../hooks/ChooseCta";
import InnerHtml from "./InnerHtml";
import styles from "./TimelineMobile.module.css";

const TimelineMobile = (props) => {
  const compData = props.data ? props.data : {};

  const newArray = compData.timelines.map((data) => {
    if (data.date) {
      let d = new Date(data.date);
      data.year = d.getFullYear();
    }
    return data;
  });

  return (
    <div className={styles.mainContainer}>
      <div className={styles.topHeader}>
        <div className={styles.headerL}>
          <div className={styles.infoIcon}>
            <Icon
              name={compData.icon}
              variant="outlined"
              size="large"
              role="img"
            />
          </div>
          <div className={styles.title}>{compData.heading}</div>
          <InnerHtml
            description={compData.description}
            style={styles.subTitle}
          />
        </div>
      </div>
      <div>
        {newArray.map((data, index) => (
          <div className={styles.commonBlock} key={index}>
            {data.year ? (
              <>
                <div className={styles.flag}>{data.year}</div>
                <div className={styles.bPole}></div>
              </>
            ) : (
              <></>
            )}
            <div className={styles.bTitle}>{data.heading}</div>
            <InnerHtml description={data.description} style={styles.sTitle} />
            <div className={styles.sTitle}></div>
            <ChooseCta
              data={data?.cta}
              size="small"
              type="text_link"
              iconRight="arrow-right"
            />
            {/*
            <div>
              <a className={styles.anc}>{data.cta.label}</a>
              <div className={styles.arrowR}></div>
            </div>
            */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TimelineMobile;
