const setLocalStoreValue = (key, val) => {
  if (isElectron()) {
    localStorage.setItem(key, val);
  } else {
    sessionStorage.setItem(key, val);
  }
};
const clearSpecificLocalStoreVal = (key) =>{
  if(isElectron())
  {
    localStorage.removeItem(key);
  }
  else{
    sessionStorage.removeItem(key)
  }
}
const getLocalStoreValue = (key) => {
  if (isElectron()) {
    return localStorage.getItem(key);
  } else {
    return sessionStorage.getItem(key);
  }
};

const isElectron = () => {
  return localStorage.getItem("electron") == "electron";
}

const setCookie = (cname, cvalue, exdays)=> {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires="+d.toUTCString();
  if(exdays == 0)
  {
    d.setTime(d.getTime() + (3* 60 * 1000));
    expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  else{
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  
 
}

const getCookie = (cname)=> {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export { setLocalStoreValue, getLocalStoreValue, isElectron,setCookie, getCookie,clearSpecificLocalStoreVal};
