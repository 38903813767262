import React from "react";
import styles from "./Breadcrumb.module.css";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../hooks/useScreenDetails";
import { navigationActions } from "../../data/navigation-slice";

import leftArrow from "../../assets/images/chevron-left-aligned-left.svg";

const Breadcrumb = () => {
  const dispatch = useDispatch();
  const [isMobile] = useWindowDimensions();
  const selectedNavigation = useSelector(
    (state) => state.navigation.selectedNavigation
  );
  const breadcrumbForceEnable = useSelector(
    (state) => state.navigation.breadcrumbForceEnable
  );
  const selectedRoutes = useSelector(
    (state) => state.navigation.selectedRoutes
  );
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );

  const clickHandler = (e) => {
    e.preventDefault();
    dispatch(navigationActions.setBreadcrumbForceEnable(false));
  };

  const home = {
    label: globalProperties?.home,
    link: "dashboard",
  };

  if (!selectedNavigation && !selectedRoutes) return;
  let breadcrumbLinks =
    selectedNavigation.length !== 0 ? selectedNavigation : selectedRoutes;

  return (
    <section className={styles.breadcrumb}>
      {isMobile && (
        <div className={styles.mobileLink}>
          <img src={leftArrow} />
          {breadcrumbLinks.length === 3 ? (
            <>
              {breadcrumbLinks[1]?.hasPage ? (
                <Link
                  to={breadcrumbLinks[1].absoluteLink}
                  className={styles.link}
                >
                  {breadcrumbLinks[1].label}
                </Link>
              ) : (
                <Link to={home.link} className={styles.link}>
                  {home.label}
                </Link>
              )}
            </>
          ) : (
            <Link to={home.link} className={styles.link}>
              {home.label}
            </Link>
          )}
        </div>
      )}
      {!isMobile && (
        <>
          <Link to={home.link} className={styles.link}>
            {home.label}
          </Link>
          {breadcrumbLinks.map((item, index) => (
            <React.Fragment key={index}>
              {!(index === 0 && !item.hasPage) && (
                <>
                  {breadcrumbForceEnable ? (
                    <>
                      <span>&#62;</span>
                      <a
                        onClick={(e) => clickHandler(e)}
                        className={styles.link}
                      >
                        {item.label}
                      </a>
                    </>
                  ) : (
                    <>
                      {item.hasPage && index !== breadcrumbLinks.length - 1 ? (
                        <>
                          <span>&#62;</span>
                          <Link to={item.absoluteLink} className={styles.link}>
                            {item.label}
                          </Link>
                        </>
                      ) : (
                        <>
                          <span>&#62;</span>
                          <span>{item.label}</span>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </React.Fragment>
          ))}
        </>
      )}
    </section>
  );
};

export default Breadcrumb;
