import style from "./FilterAs.module.css";
import { Checkbox, Button, TextLink } from "@mgmresorts/mgm-ui";
import { useState, useEffect } from "react";
import { customEventTracking } from "../../appInsights";
//import {  useNavigate} from "react-router-dom";

const Filter = (props) => {
  //const navigate = useNavigate();
  //const categoryData = { category: [{ name: "Help centre 1", checked: false }, { name: "Help centre 2", checked: false }] };
  //let categoryData = {};
  //const [data, setData] = useState();
  const [btnDisable, setbtnDisable] = useState(true);
  
  //console.log(data, "<<<data");
  /*const handleClick = (e) => {
    let selName = e.target.name;

    console.log("selName>>>", selName);

    //navigate('/employee-resources/centers-of-excellence/facilities');
  };*/

  useEffect(() => {
    setbtnDisable(props.btnState);
  }, [props.btnState]);

  /*useEffect(() => {
    console.log('props.facetcounts', props.facetcounts);

    categoryData.category = props.facetcounts;
    setData(categoryData);
  }, [props.facetcounts])*/
  const ctrlBtnState = (e) => {
    if (e.target.checked) {
      setbtnDisable(false);
      props.setbtnState(false);
    } else {
      let isatleastonechecked = false;
      props.facetcounts.map((d) => {
        if (d.checked == true) {
          isatleastonechecked = true;
          return;
        }
      });
      if (isatleastonechecked) {
        setbtnDisable(false);
        props.setbtnState(false);
      } else {
        setbtnDisable(true);
        props.setbtnState(true);
      }
    }
  };
  const getVal = (e) => {
    console.log(e.target.name);
    if(props.profile && e.target.checked){
      const profileInfo ={userid:props.profile.employeeId,employeeType:props.profile.payRateGroup,businessLocation:props.profile.primaryLocation,searchterm:e.target.name,searchLocation:"Search Page", searchType:"Category",role: props.profile.businessTitle};
     customEventTracking("Search",profileInfo)
     }
    let clickedname = e.target.name;
    //e.target.setAttribute("checked", "checked");
    let returndata;
    let obj = props.facetcounts.map((d) => {
      return d;
    });
    returndata = obj.map((d) => {
      let newObj = {};
      if (d.name === clickedname) {
        //d.checked = !d.checked;
        newObj.checked = !d.checked;
        newObj.name = d.name;
      } else {
        newObj.checked = d.checked;
        newObj.name = d.name;
      }
      return newObj;
    });
    props.setfacetcounts(returndata);
  };
  const clearFilter = () => {
    let returndata;
    returndata = props.facetcounts.map((d) => {
      let newObj = {};
      //d.checked = false;
      newObj.name = d.name;
      newObj.checked = false;
      return newObj;
    });
    props.setfacetcounts(returndata);
    props.clearfilter();
    setbtnDisable(true);
  };
  const applyFilter = () => {
    // if(props.profile){
    //   let profileInfo ={userid:props.profile.employeeId,employeeType:props.profile.payRateGroup,businessLocation:props.profile.primaryLocation,searchterm:props.facetcounts,searchLocation:"Search Page", searchType:"Category",role: props.profile.businessTitle};
    //  customEventTracking("Search",profileInfo)
    //  }
    props.filterResult(props.facetcounts,true);
  };
  return (
    <div className={style.filterContainer}>
      <div>
        <div className={style.filterTitle}>
          {props.csData
            ? props.csData.filter(
                (d) => d.name?.toLowerCase() == "filter results"
              )[0]?.value
            : "Filter results"}
        </div>
        <div className={style.filterSubTitle}>
          {props.csData
            ? props.csData.filter((d) => d.name?.toLowerCase() == "category")[0]
                .value
            : "Category"}
        </div>
        <div className={style.mholder}>
          <div className={style.inputH}>
            {props.facetcounts &&
              props.facetcounts.map((c, index) => (
                <div key={index} className={style.checkHolder}>
                  <Checkbox
                    testId=""
                    key={index}
                    id={index}
                    variant="small"
                    onClick={getVal}
                    name={c.name}
                    onChange={ctrlBtnState}
                    checked={c.checked}
                  />
                  <label htmlFor={index} className={`${style.cLable}`}>
                    {c.name}
                  </label>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className={style.filterControlH}>
        <div className={style.filterApply}>
          <Button
            onClick={applyFilter}
            label={
              props.csData
                ? props.csData.filter(
                    (d) => d.name?.toLowerCase() == "apply filters"
                  )[0].value
                : "Apply filters"
            }
            size="small"
            variant="primary"
            disabled={btnDisable}
          />
        </div>
        <div className={style.filterClear} onClick={clearFilter}>
          <TextLink variant="small">
            {props.csData
              ? props.csData.filter(
                  (d) => d.name?.toLowerCase() == "clear filters"
                )[0].value
              : "Clear filters"}
          </TextLink>
        </div>
      </div>
    </div>
  );
};
export default Filter;
