
import { memo } from "react";
import styles from "./SessionTimeout.module.css"
import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import { useIdleTimer } from 'react-idle-timer'
import { useSelector } from "react-redux";
import { getLocalStoreValue } from "./../../utils/localStore";

function sessionTimeout() {
    const [count, setCount] = useState(0);
    const [remaining, setRemaining] = useState(0);
    const [open, setOpen] = useState(false)
    const timeout = 1000 * 60 * Number(process.env.REACT_APP_TIMEOUT);
    const promptBeforeIdle = 1000 * 60 * Number(process.env.REACT_APP_PROMPT_BEFORE_IDLE);
    const globalNotification = useSelector((state) => state.globalNotification);
    const { sessionTimeout } = globalNotification || [];
    const isElectron = getLocalStoreValue("electron") == "electron";
    useEffect(() => {

        const interval = setInterval(() => {
            //setRemaining(convertStoMs(getRemainingTime() / (1000)))
            setRemaining(Math.ceil(getRemainingTime() / 1000))
        }, 500)

        return () => {
            clearInterval(interval)
        }
    });

    const onIdle = () => {
        console.log("remaining----", remaining);
        if (process.env.REACT_APP_OKTA_LOGIN == "true" && !isElectron) {
            localStorage.removeItem('token');
            localStorage.removeItem('apigee_token');
            localStorage.setItem("MGMisAuthenticated", "false");
            sessionStorage.removeItem('cpath');
            
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('apigee_token');
            sessionStorage.setItem("MGMisAuthenticated", "false");
            window.location.replace(`${process.env.REACT_APP_BASE_URL_LOGIN}logout`);
        }

    }

    const onActive = () => {
        setOpen(false);
    }
    const onPrompt = () => {
        setOpen(true);
    }

    const onAction = () => {
        setCount(count + 1)
    }
    const { getRemainingTime, activate } = useIdleTimer({
        onIdle,
        onActive,
        onAction,
        onPrompt,
        timeout,
        promptBeforeIdle,
        throttle: 500

    })
    const convertStoMs = (seconds) => {
        let minutes = Math.floor(seconds / 60);
        let extraSeconds = Math.floor((seconds - (minutes * 60)));
        minutes = minutes < 10 ? "0" + minutes : minutes;
        extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
        return minutes + " : " + extraSeconds;

    }
    const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0)
    const timeTillPromptDis = convertStoMs(remaining);
    const portalElement = document.getElementById("overlays");
    const handleStillHere = () => {
        activate()
    }
    return (<>{!isElectron && timeTillPrompt == 0 && open == true && (ReactDOM.createPortal(
        <div className={styles.sessionTimeoutHolder}>
            {sessionTimeout && sessionTimeout.length > 0 && (<div className={styles.wBlock}>
                <div className={styles.iconH}>
                    <img src={sessionTimeout[0].icon.url} />
                </div>
                <div className={styles.heading}>{sessionTimeout[0].heading}</div>
                <div>{sessionTimeout[0].notification_text}</div>
                <div className={styles.subheading}>
                    <span>{sessionTimeout[0].warning_text}</span><span className={styles.timer}>&nbsp;{timeTillPromptDis}</span>
                </div>

                <div className={styles.sBtn} onClick={handleStillHere}>{sessionTimeout[0].button_continue_label}</div>
            </div>)}
        </div>,
        portalElement
    ))}

    </>)
}
export default memo(sessionTimeout);