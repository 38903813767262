import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  headerHeight: 0,
  updateCounter: 0,
  stockCompState: false,
  pageTitle: "",
  currentPageURL: "",
  timeoffRequestCounter: 0,
  previouslanguage:"",
  previousPageType:[]
};

const domSlice = createSlice({
  name: "dom",
  initialState,
  reducers: {
    setHeaderHeight(state, action) {
      state.headerHeight = action.payload;
    },
    setUpdateCounter(state) {
      state.updateCounter += 1;
    },
    setCurrentPageURL(state, action) {
      state.currentPageURL = action.payload;
    },
    setStockDom(state, action) {
      state.stockCompState = action.payload;
    },
    setAnalyticsPageTitle(state, action)
    {
      state.pageAnalyticsTitle = action.payload;
    },
    setPageTitle(state, action) {
      state.pageTitle = action.payload;
    },
    setTimeoffRequestCounter(state) {
      state.timeoffRequestCounter += 1;
    },
    setPreviouslanguage(state,action)
    {
      state.previouslanguage = action.payload;
    },
    setPreviousPageType(state,action)
    {
      state.previousPageType.push(action.payload);
    }
  },
});

export const domActions = domSlice.actions;
export default domSlice;
