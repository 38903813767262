import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = (props) => {
  const { pathname } = useLocation();
  const { force } = props;
  let randomNumber;
  if (force) randomNumber = Math.random();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, randomNumber]);
};

export default ScrollToTop;
