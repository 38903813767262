import styles from "./JobOpportunitiesCard.module.css";
import { useState, useEffect } from "react";

import {
  Button,
  TextLink,
  CenterModal,
  Icon,
  FullScreenModal,
} from "@mgmresorts/mgm-ui";
// we can remove this modal js page if we don't need it for api purposes
// import JobOpportunitiesQuickViewModal from "./JobOpportunitiesQuickViewModal";
import InnerHtml from "../../components/dynamic/InnerHtml";
const JobOpportunitiesCard = (props) => {
  // console.log("2o", props.data?.dictionaryLables?.job_id);
const job_id_label = props?.data?.dictionaryLables?.job_id;
  const [isQuickViewModalOpen, setIsQuickViewModalOpen] = useState(false);
  const [deviceSize, changeDeviceSize] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(deviceSize);

  const apply_now_label = props?.data?.dictionaryLables?.apply_now_label;
  const quick_view_label = props?.data?.dictionaryLables?.quick_view_label;
  const close_label = props?.data?.dictionaryLables?.close_label;

  const convertDateString = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  };
  const openQuickViewModal = () => {
    // setSelectedModal(args[0]);
    setIsQuickViewModalOpen(true);
  };
  const closeQuickViewModal = () => {
    setIsQuickViewModalOpen(false);
  };

  useEffect(() => {
    if (isQuickViewModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [isQuickViewModalOpen]);
  const modalHeaderArea = (
    <div>
      <div className={styles.title}>{props?.item?.jobTitle}</div>
      <div className={styles.secondLine}>
        <span>{convertDateString(props?.item?.jobPostingDate)} </span>
        <span>
          {props?.item?.jobLocation && <><Icon name="pin-location" size="small"></Icon></>}
          {props?.item?.jobLocation}
        </span>
      </div>
      <div className={styles.thirdLine}>
        <span>{job_id_label} - {props?.item?.jobId} |</span> <span> {props?.item?.jobType} |</span>
        <span> {props?.item?.jobExemptType}</span>
      </div>
    </div>
  );
  const modalFooterArea = (
    <div className={styles.modalFooterContainer}>
      <TextLink onClick={closeQuickViewModal} className={styles.leftBtn}>
        {close_label}
      </TextLink>
      <Button
        className={"globalDarkBlueBtn"}
        variant="primary"
        size="large"
        onClick={() => {
          window.open(props?.item?.jobInternalURL, "_blank");
        }}
      >
        {apply_now_label}
      </Button>
    </div>
  );

  useEffect(() => {
    const resizeW = () => changeDeviceSize(window.innerWidth);
    window.addEventListener("resize", resizeW); // Update the width on resize
    // if (deviceSize < 1224) {
    if (deviceSize < 690) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    return () => window.removeEventListener("resize", resizeW);
  }, [deviceSize]);

  // console.log("asdf", props.data.isDataLoaded);
  return (
    <div className={styles.jobCardContainer}>
      <div>
        <div className={styles.title}>{props?.item?.jobTitle}</div>
        <div className={styles.secondLine}>
          <span>{convertDateString(props?.item?.jobPostingDate)} </span>
          <span>
            {props?.item?.jobLocation && <><Icon name="pin-location" size="small"></Icon></>}
            {props?.item?.jobLocation?props?.item?.jobLocation?.includes(";") && (props?.item?.jobLocation?.slice(0, 11) === "Home Office") ? "Multiple Remote Locations" : props?.item?.jobLocation:''}
          </span>
        </div>
        <div className={styles.thirdLine}>
          <span>{job_id_label} - {props?.item?.jobId} |</span> <span> {props?.item?.jobType} |</span>
          <span> {props?.item?.jobExemptType}</span>
        </div>
      </div>

      <div className={styles.btnContainer}>
        <Button
          className={styles.leftBtn}
          variant="secondary"
          onClick={() => window.open(props?.item?.jobInternalURL, "_blank")}
        >
          {apply_now_label}
        </Button>
        <TextLink
          className={styles.quickViewBtn}
          onClick={() => openQuickViewModal()}
        >
          {quick_view_label}
        </TextLink>
      </div>
      {isQuickViewModalOpen && !isMobile && (
        <CenterModal
          title={modalHeaderArea}
          open={isQuickViewModalOpen}
          size="medium"
          overflow={true}
          topOverflow={true}
          bottomOverflow={true}
          onClose={closeQuickViewModal}
          actions={modalFooterArea}
          footer={true}
        >
          <InnerHtml
            description={props?.item?.jobDescription}
            style={styles.innerHtml}
          />
        </CenterModal>
      )}
      {isQuickViewModalOpen && isMobile && (
        <FullScreenModal
          title={modalHeaderArea}
          open={isQuickViewModalOpen}
          size="medium"
          overflow={true}
          topOverflow={true}
          bottomOverflow={true}
          onClose={closeQuickViewModal}
          actions={modalFooterArea}
          footer={true}
        >
          <InnerHtml
            description={props?.item?.jobDescription}
            style={styles.innerHtml}
          />
        </FullScreenModal>
      )}
    </div>
  );
};

export default JobOpportunitiesCard;
