//import { useState } from "react";
import styles from "./LeadershipMessageInner.module.css";
import { Icon, TextLink } from "@mgmresorts/mgm-ui";
import InnerHtml from "./InnerHtml";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "../global/ScrollToTop";
import LeadershipRelatedNews from "./LeadershipRelatedNews";
import { useLocation } from "react-router-dom";
import { domActions } from "../../data/dom-slice";

const LeadershipMessageInner = (props) => {
  const dispatch = useDispatch();
  const breadcrumbForceEnable = useSelector(
    (state) => state.navigation.breadcrumbForceEnable
  );
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );
  const currentPageURL = useSelector((state) => state.dom.currentPageURL);

  const location = useLocation();
  let currentLocation;
  useEffect(() => {
    currentLocation = { ...location };
    dispatch(domActions.setCurrentPageURL(currentLocation.pathname));
  }, []);

  const moveLeft = (e) => {
    e.preventDefault();
    props.moveLeft();
  };

  const moveRight = (e) => {
    e.preventDefault();
    props.moveRight();
  };

  useEffect(() => {
    if (!breadcrumbForceEnable && currentPageURL === location.pathname) {
      props.loadLandingPage();
    }
  }, [breadcrumbForceEnable]);

  return (
    <>
      <h2 className={styles.heading}>{props.data?.heading}</h2>
      <section className={styles.messageHolder}>
        <ScrollToTop force={true} />
        <section className={styles.message}>
          <div className={styles.imageHolder}>
            <img src={props.data?.image} />
            <div className={styles.leftArrow} onClick={(e) => moveLeft(e)}>
              <Icon
                name="chevron-left"
                variant="outlined"
                size="small"
                role="img"
              />
            </div>
            <div className={styles.rightArrow} onClick={(e) => moveRight(e)}>
              <Icon
                name="chevron-right"
                variant="outlined"
                size="small"
                role="img"
              />
            </div>
          </div>
          <InnerHtml
            description={props.data?.description}
            style={styles.innerHtml}
          />
          <hr></hr>
          <div className={styles.buttonHolder}>
            <TextLink
              href="#"
              iconLeft="arrow-left"
              variant="large"
              onClick={(e) => moveLeft(e)}
            >
              {globalProperties.previous}
            </TextLink>
            <TextLink
              href="#"
              iconRight="arrow-right"
              variant="large"
              onClick={(e) => moveRight(e)}
            >
              {globalProperties.next}
            </TextLink>
          </div>
        </section>
        <LeadershipRelatedNews nameValue={props.nameValue} link={props.link} />
      </section>
    </>
  );
};

export default LeadershipMessageInner;