import styles from "./TwoColumn.module.css";
import ChooseCta from "../../hooks/ChooseCta";
import InnerHtml from "./InnerHtml";
import { useState } from "react";
import Modal from "../global/ui/Modal";

const TwoColumn = (props) => {
  let typeStyle =
    "type_" + props.data?.type?.split(",").join("").split("-").join("_");

  let [showPopup, setShowPopup] = useState(false);
  let [selectedModal, setSelectedModal] = useState();

  const openModal = (arg) => {
    let modal =
      arg == "left"
        ? props.data?.leftCta?.modalContent
        : props.data?.rightCta?.modalContent;
    if (modal != null && modal != undefined) {
      setSelectedModal({
        heading: modal.heading,
        body: modal.content,
      });
    } else {
      setSelectedModal({ heading: "", body: "" });
    }
    setShowPopup(true);
  };

  const onPopupClose = () => {
    setShowPopup(false);
  };

  return (
    <div className={`${styles.twoColumn} ${styles[typeStyle]}`}>
      <div>
        {props.data?.leftType === "text" && (
          <>
            <h3 data-cslp={props.data?.$?.leftHeading}>
              {props.data?.leftHeading}
            </h3>
            <InnerHtml
              description={props.data?.leftDescription}
              hash={props.data?.$?.leftDescription}
            />
            {props.data?.leftCtaEnabled && (
              <div className={styles.buttonHolder}>
                <ChooseCta
                  data={props.data?.leftCta}
                  size="small"
                  type="secondary"
                  args="left"
                  openModal={openModal}
                />
              </div>
            )}
          </>
        )}
        {props.data?.leftType === "image" && (
          <div className={styles.imageHolder}>
            <img src={props.data?.leftImage} />
          </div>
        )}
      </div>
      <div>
        {props.data?.rightType === "text" && (
          <>
            <h3 data-cslp={props.data?.$?.rightHeading}>
              {props.data?.rightHeading}
            </h3>
            <InnerHtml
              description={props.data.rightDescription}
              hash={props.data?.$?.rightDescription}
            />
            {props.data?.rightCtaEnabled && (
              <div className={styles.buttonHolder}>
                <ChooseCta
                  data={props.data?.rightCta}
                  size="small"
                  type="secondary"
                  args="right"
                  openModal={openModal}
                />
              </div>
            )}
          </>
        )}
        {props.data?.rightType === "image" && (
          <div className={styles.imageHolder}>
            <img src={props.data?.rightImage} />
          </div>
        )}
      </div>
      {showPopup && (
        <Modal onClose={onPopupClose} heading={selectedModal.heading}>
          <InnerHtml description={selectedModal.body} />
        </Modal>
      )}
    </div>
  );
};

export default TwoColumn;
