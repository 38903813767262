import { useEffect, useState } from "react";
import updates from "./UpdatesandEvents.module.css";
import { GET_PROPERTY_CALENDER } from "../../features/api-wrapper/apiQueries";
import { useQuery } from "@apollo/client";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import APIError from "../ErrorHandling/APIError";
import Loader from "../../components/global/Loader";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

const occupationDetails = [
  {
    event: "James Taylor",
    day: "Wednesday June 7 | Aria 8 PM",
    location: "Aria",
    occupancy: "95.6%",
  },
  {
    event: "LV Aces vs. Chicago Sky",
    day: "Sunday, June 12 | Vdara, 8PM",
    location: "Vdara",
    occupancy: "98.1%",
  },
  {
    event: "VGK vs. Florida Panthers",
    day: "Sunday, June 13 | Cosmopolitan, 5:00 PM",
    location: "Cosmopolitan",
    occupancy: "97.6%",
  },
];

const userLocArray = ["Corporate", "Vegas Corporate", "Regional Corporate"].map(
  (v) => v.toLowerCase()
);

const UpdatesandEvents = (props) => {
  
  const { links, dictionaryLables } = props.data;

  const {
    upcoming_events,
    property_updates,
    view_all,
    todays_occupancy,
    no_events_message,
  } = dictionaryLables || {};

  const [eventdetails, setEventdetails] = useState();
  const { loading, error, data } = useQuery(GET_PROPERTY_CALENDER, {
    client: apiMiddlewareClient,
  });
  const userLocation = useSelector(
    (state) => state.gqlData?.profile?.userLocation
  );

  useEffect(() => {
    if (
      data &&
      data.filteredPropertyCalendar &&
      data.filteredPropertyCalendar.body &&
      data.filteredPropertyCalendar.body.length > 0
    ) {
      const myArray =
        data.filteredPropertyCalendar && data.filteredPropertyCalendar.body;
      const arrayLimit =
        myArray && myArray.length > 6 ? myArray.slice(0, 3) : myArray;
      const upcomingDataArray = [];
      arrayLimit.filter((property) => {
        const eventData = JSON.stringify(property).split("|");
        const eveTime =
          eventData.length >= 4
            ? eventData[3].indexOf("href")
              ? eventData[3].replace(/\\"/g, '"')
              : eventData[3]
            : "";
        upcomingDataArray.push({
          eventName: eventData[2],
          eventMonth: eventData[0].replace(/"(<([^>]+)>)/gi, " "),
          eventLocation: eventData[1],
          eventTime: eveTime !== "" ? eveTime.replace(/['"]+/g, "") : "",
        });
      });
      setEventdetails(upcomingDataArray);
    }
  }, [data]);

  if (loading) return <Loader />;
  if (error) return <APIError error={error} />;

  return (
    <div className={`${updates.taskcontainer} col-12`}>
      <div className={updates.headersection}>
        <span className={updates.header}>
          {userLocation != "" &&
          userLocation != undefined &&
          userLocArray.includes(userLocation.toLowerCase())
            ? upcoming_events
            : property_updates}
        </span>
        <Link to={links[0].href} className={updates.viewall}>
          {view_all}
        </Link>
      </div>
      {/* <div className={`${updates.taskheader} col-12`}>{updates_taskheader}</div> */}
      {eventdetails && eventdetails.length > 0 ? (
        eventdetails.map((data, i) => {
          return (
            <div key={i} className={updates.deailsSection}>
              <div className={updates.eventContainer}>
                {" "}
                <div className={updates.eventDayN}>
                  {moment(data.eventMonth).format("MMM")}
                </div>
                <div className={updates.eventDate}>
                  {" "}
                  {moment(data.eventMonth).format("DD")}
                </div>
              </div>
              <div key={i} className={updates.eventWrapper}>
                <div
                  className={updates.eventname}
                  dangerouslySetInnerHTML={{ __html: data.eventName }}
                ></div>
                <span
                  className={`pl-1 pr-1 d-inline ${updates.eventday}`}
                  dangerouslySetInnerHTML={{ __html: data.eventMonth }}
                ></span>
                &nbsp; | &nbsp;
                <span
                  className={`pl-1 d-inline ${updates.eventday}`}
                  dangerouslySetInnerHTML={{ __html: data.eventLocation }}
                ></span>
                ,
                <span
                  className={`pl-1 d-inline ${updates.eventday}`}
                  dangerouslySetInnerHTML={{ __html: data.eventTime }}
                ></span>
              </div>
            </div>
          );
        })
      ) : (
        <div className={updates.noEvents}>{no_events_message}</div>
      )}
      <div className={updates.occupyheader}>{todays_occupancy}</div>
      <div className={updates.occupysection}>
        {occupationDetails.map((val, i) => {
          return (
            <>
              <div key={i} className={updates.occupydetails}>
                <div className={updates.location}>{val.location}</div>
                <div className={updates.occupancy}>{val.occupancy}</div>
              </div>
              <div className={updates.divider}></div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default UpdatesandEvents;
