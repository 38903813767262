import { Button } from "@mgmresorts/mgm-ui";
import { useNavigate } from "react-router-dom";
import styles from "./OffersCard.module.css";

import tempImg from "../../assets/images/FacilityImage.png";
import InnerHtml from "./InnerHtml";

const OffersCard = (props) => {
  //console.log(props.data.cta);
  const navigate = useNavigate();

  const clickHandler = (e) => {
    e.preventDefault();
    let cta = props.data?.cta;
    if (cta.openIn === "new_window") {
      window.open(cta.url, "_blank");
    } else if (cta.openIn === "same_window") {
      navigate(cta.url);
    }
  };

  return (
    <div className={styles.offersCard}>
      <div className={styles.image}>
        <img data-cslp={props.data?.$?.image} src={tempImg} />
        {/* <img data-cslp={props.data?.$?.image} src={props.data?.image} /> */}
      </div>
      <div className={styles.content}>
        <h2 data-cslp={props.data?.$?.offerDate}>July 30, 2023</h2>
        {/* <h2 data-cslp={props.data?.$?.offerDate}>{props.data?.offerDate}</h2> */}
        <h3 data-cslp={props.data?.$?.heading}>{props.data?.heading}</h3>
        <InnerHtml
          description={props.data?.description}
          hash={props.data?.$?.description}
        />
        {props.data?.cta?.label && (
          <Button
            data-cslp={props.data?.$?.cta}
            label={props.data.cta?.label}
            size="small"
            variant={
              props.data.cta?.type
                ? props.data.cta?.type?.toLowerCase()
                : "tertiary"
            }
            onClick={(e) => clickHandler(e)}
          />
        )}
      </div>
    </div>
  );
};

export default OffersCard;
