import styles from "./BioDetailsModal.module.css";
import closeimg from "../../assets/images/close-button.svg";
import linkedin from "../../assets/images/linkedin.svg";
import InnerHtml from "../dynamic/InnerHtml";

const BioDetailsModal = ({ isOpen, onClose, modalContent }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.modal}>
      <div className={styles.container}>
        <div className={styles.right}>
          <img src={closeimg} className={styles.closebtn} onClick={onClose} />
        </div>
        <div className={`${styles.body} col-12`}>
          <div className={`${styles.topsection} col-12 row`}>
            <img
              className={`${styles.displaypic} col-12 col-md-5`}
              src={modalContent.image}
            />
            <div className={`${styles.titlesection} col-12 col-md-7`}>
              <div>
                <div className={styles.title}>{modalContent.name}</div>
                <div className={styles.designation}>
                  {modalContent.designation}
                </div>
              </div>
              <div className={styles.link}>
                <img src={linkedin} className={styles.linkimg} />
              </div>
            </div>
          </div>
          <div className={`${styles.bottomsection} col-12 row`}>
          <InnerHtml
                    description={modalContent.description}
                    hash={modalContent?.$?.description}
                  />
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default BioDetailsModal;
