import styles from "./MyScheduleMessage.module.css";

import CTATextLink from "../../../components/dynamic/CTATextLink";
import InnerHtml from "../../../components/dynamic/InnerHtml";

const MyScheduleMessage = (props) => {
  const { url, heading, link, messageType, description } = props.data;

  return (
    <div>
      <div
        className={`${styles.scheduleMessageContainer} ${
          messageType == "acknowledge"
            ? styles.scheduleAckowledge
            : styles.scheduleWarning
        }`}
      >
        <div className={styles.reviewHeader}>
          <img src={url} alt="danger icon" />
        </div>
        <div className={styles.msgContent}>
          <h3>{heading}</h3>
          <InnerHtml description={description}></InnerHtml>
          <CTATextLink
            size="small"
            style={styles.reviewNow}
            type="text_link"
            data={{
              label: link.label,
              url: link.url,
              openIn: link.openIn,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default MyScheduleMessage;
