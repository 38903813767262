import styles from "./InitSearch.module.css"
import { useNavigate } from "react-router-dom";
import AgentSearchHeader from "./AgentSearchHeader";
import { useEffect,useState } from "react";
import axios from "axios";
import { getLocalStoreValue } from "../../utils/localStore";
import ErrorCardRestricted from "./../../pages/ErrorHandling/ErrorCardRestricted"
const { api } = window;
const InitSearch = (props) => {
    const [error,setError] = useState();
    let token = getLocalStoreValue('token');
    let apigee_token = getLocalStoreValue("apigee_token");
    const headers = {
      Authorization: `Bearer ${apigee_token}`,
      jwt: token,
    };
    const baseURL = process.env.REACT_APP_BASE_URL;
    let oktaLogin = process.env.REACT_APP_OKTA_LOGIN;
    useEffect(() => {
        //sessionStorage.setItem("electron", "");
        try {
            // ipcRenderer.on becomes api.recieve
            api.recieve("electron-renderer", (args) => {

                console.log("electron-renderer called---", args);

                localStorage.setItem("electron", "electron");

            });
        } catch (error) {
            console.log(error);
        }
        axios
            .get(
                `${baseURL}api/check-agent-access`,
                {
                    headers: headers,
                }
            )
            .then((response) => {
                console.log('response>>>>', response.status);
            }).catch((error) => {
                console.log("error>>>>>", error.response.status);
                if (error.response && error.response.status && error.response.status == 401) {
                    //props.resize();
                    if (oktaLogin == "true") {
                        window.location.replace(`${process.env.REACT_APP_BASE_URL_LOGIN}login`)
                        try {
                            api.send("resizeWindow", "resizeWindow");
                        } catch (e) {
                            //console.log("error occured");
                        }
                    }

                }
                else if (error.response && error.response.status && error.response.status == 403) {
                    setError({title:"Error 403: Access Restricted",message:"You do not have access to the application. Please contact the administrator."});
                }
            });

    }, [])




    props.electronBgColor('#00012b');
    const navigate = useNavigate();
    const goToEmployee = () => {
        navigate("/employeeId");
    }
    const cancelSearch = () => {
        try {
            api.send("close-window", "close-window");
        }
        catch (e) {
            console.log("error occured");
        }
    }

    return (<><AgentSearchHeader></AgentSearchHeader>{error?<ErrorCardRestricted
        title={error.title}
        message={error.message}
      />:<div className={styles.initSearchH}>
        <div className={styles.msg}>Would you like to search My MGM?</div>
        <div className={styles.btnHolder}>
            <div className={styles.cancel} onClick={cancelSearch}>Cancel</div>
            <div className={styles.search} onClick={goToEmployee}><div className={styles.searhIcon}></div>Search My MGM</div>

        </div>
    </div>}</>)
}

export default InitSearch;