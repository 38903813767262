import useFetchData from "../hooks/useFetchData";
import ErrorHanding from "../pages/ErrorHandling/ErrorHanding";
import Loader from "../components/global/Loader";

const WithFetchGlobal = (EmptyComponent) => {
  function PackedComponent(props) {
    //console.log("HIGHER ORDER COMP!!");
    const { loading, error, data } = useFetchData("", props.pageType);
    if (loading) return <Loader />;
    if (error) return <ErrorHanding error={error} />;
    return <EmptyComponent data={data} />;
  }
  return PackedComponent;
};
export default WithFetchGlobal;
