import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ErrorCard from "./ErrorCard";
import styles from "./NetworkError.module.css";

const NetworkError = () => {
  const [errorType, setErrorType] = useState();
  const globalNotification = useSelector((state) => state.globalNotification);

  useEffect(() => {
    if (globalNotification.systemErrors) {
      const { systemErrors } = globalNotification || {};
      const pageNotFound = systemErrors.filter((err) => {
        return err.type == "500";
      });
      setErrorType(pageNotFound[0]);
    }
  }, [globalNotification]);

  return (
    <>
      {errorType && (
        <div className={styles.networkErrorHolder}>
          <ErrorCard
            image={errorType.image.url}
            title={errorType.heading}
            message={errorType.description}
            cta={errorType.cta}
          />
        </div>
      )}
    </>
  );
};

export default NetworkError;
