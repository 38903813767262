import BioDetailsModal from "../common/BioDetailsModal";
import styles from "./Bio.module.css";
import { useState } from "react";
import ChooseCta from "../../hooks/ChooseCta";
import InnerHtml from "./InnerHtml";

const Bio = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={styles.bio}>
      <div className={styles.image}>
        <img data-cslp={props.data?.$?.image} src={props.data.image} />
      </div>
      <div className={styles.content}>
        <h3 data-cslp={props.data?.$?.name}>{props.data.name}</h3>
        {props.data?.designation && (
          <h4 data-cslp={props.data?.$?.designation}>
            {props.data.designation}
          </h4>
        )}
        {props.data?.description && (
          <InnerHtml
            description={props.data?.description}
            style={styles.description}
            hash={props.data?.$?.description}
          />
        )}
        {props.data?.ctaEnabled && (
          <div className={styles.buttonHolder}>
            <ChooseCta
              data={props.data?.cta}
              size="small"
              type="text_link"
              openModal={openModal}
              style={styles.textLink}
            />
          </div>
        )}
      </div>
      <BioDetailsModal
        isOpen={isModalOpen}
        onClose={closeModal}
        modalContent={props.data}
      />
    </div>
  );
};

export default Bio;
