//import { useEffect, useState } from "react";
import styles from "./Timeline.module.css";

import TimelineDesktop from "./TimelineDesktop";
import TimelineMobile from "./TimelineMobile";
import useScreenDetails from "../../hooks/useScreenDetails";

const Timeline = (props) => {
  const [isMobile, isSpecifiedSize] = useScreenDetails(426);
  console.log(isMobile);

  return (
    <div className={styles.timeline}>
      <div className={styles.mapHolder}>
        {isSpecifiedSize ? (
          <TimelineMobile data={props.data} />
        ) : (
          <TimelineDesktop data={props.data} />
        )}
      </div>
    </div>
  );
};

export default Timeline;
