import style from "./Filter.module.css";
import {  useNavigate} from "react-router-dom";

const Filter = () => {
  const navigate = useNavigate();
  const handleClick = (e) => {
    let selName = e.target.name;

    console.log("selName>>>", selName);
    navigate('/employee-resources/centers-of-excellence/facilities');
  };
  return (
    <div className={style.filterContainer}>
      <div>
        <div className={style.filterTitle}>Filter results</div>
        <div className={style.filterSubTitle}>Category</div>
        <div>
          <div className={style.inputH}>
            <div className={style.checkHolder}>
              <label className={`${style.cLable} Ccontainer`}>
                Help center
                <input type="checkbox" onChange={(e) => handleClick(e)} name="Help center"/>
                <span className="checkmark"></span>
              </label>
            </div>
            <div className={style.checkHolder}>
              <label className={`${style.cLable} Ccontainer`}>
                Benefits & policies
                <input type="checkbox" onChange={(e) => handleClick(e)} name="Benefits & policies"/>
                <span className="checkmark"></span>
              </label>
            </div>
            <div  className={style.checkHolder}>
              <label className={`${style.cLable} Ccontainer`}>
                Employee resources
                <input type="checkbox" onChange={(e) => handleClick(e)} name="Employee resources"/>
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className={style.fLowerBlock}>
        <div className={style.filterTitle}>File type</div>
        <div>
          <div className={style.checkHolder}>
          <label className={`${style.cLable} Ccontainer`}>
            Documents
            <input type="checkbox" onChange={(e) => handleClick(e)} name="Documents"/>
            <span className="checkmark"></span>
          </label>
          </div>
          <div  className={style.checkHolder}>
          <label className={`${style.cLable} Ccontainer`}>
            Videos
            <input type="checkbox" onChange={(e) => handleClick(e)} name="Videos"/>
            <span className="checkmark"></span>
          </label>
          </div>
          
        </div>
      </div>

      <div className={style.filterControlH}>
        <div className={style.filterApply}>Apply filters</div>
        <div className={style.filterClear}>Clear filters</div>
      </div>
    </div>
  );
};
export default Filter;
