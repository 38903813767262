import styles from "./Timesheet.module.css";

import { useSelector } from "react-redux";
import LocationSelectionTimesheet from "./LocationSelectionTimesheet";
import { useLocation } from "react-router";
import GetRightRailComponent from "../../../hooks/GetRightRailComponent";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import 'moment/locale/es';
import GetDynamicComponent from "../../../hooks/GetDynamicComponent";

const Timesheet = (props) => {
  const { data } = props;
  const { state } = useLocation();
  let [apiReady, setApiReady] = useState(false);
  let [apiData, setApiData] = useState();
  const isMultipProp = useSelector((state) => state?.gqlData?.profile);

  const language = useSelector(
    (state) => state.gqlData?.profile?.preferredLanguage
  );
  if(language)
  {
    moment.locale(language);
    
  }

  const pageHeaderBlockItems = useMemo(() => {
    return data.filter(
      (item) => item.block === "hero" || item.block === "in_page_alert"
    );
  }, [data]);

  let rightRailBlockItems = useMemo(() => {
    return data.filter((item) => item.block === "right_rail_block");
  }, [data]);

  const pageApiBlockItems = useMemo(() => {
    return data.filter((item) => item.block === "api_blocks");
  }, [data]);

  useEffect(() => {
    if (
      pageApiBlockItems.length > 0 &&
      pageApiBlockItems[0].type === "timecard"
    ) {
      setApiValues();
      setApiReady(true);
    }
  }, [pageApiBlockItems]);

  const setApiValues = () => {
    setApiData({
      staticData: pageApiBlockItems[0]?.data,
      viewAllTimecards: pageApiBlockItems[0]?.data?.links_dictionary,
    });
  };

  return (
    <div className={styles.timesheetHolder}>
      {pageHeaderBlockItems.map((item, index) => (
        <section className={styles.headerBlocks} key={index}>
          <GetDynamicComponent
            comp={item.key}
            data={item.data}
            error={item.error}
          />
        </section>
      ))}
      <section className={styles.pageContent}>
        <section className={styles.leftContent}>
          {apiReady && (
            <LocationSelectionTimesheet
              staticData={apiData.staticData}
              multiProp={isMultipProp}
              viewAllTimecards={apiData.viewAllTimecards}
              selectedDropdownState={state?.selectedDate}
            />
          )}
        </section>
        <section className={styles.rightContent}>
          {rightRailBlockItems.map((item, index) => (
            <section key={index}>
              <GetRightRailComponent
                comp={item.key}
                data={item.data}
                error={item.error}
              />
            </section>
          ))}
        </section>
      </section>
    </div>
  );
};

export default Timesheet;
