import { Button } from "@mgmresorts/mgm-ui";
import { useNavigate } from "react-router-dom";
import ConfirmationPopup from "../global/ConfirmPrompt";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ExternalButton = (props) => {
  let size = props.size ? props.size : "";
  let fullWidth = props.fullWidth ? props.fullWidth : false;

  if (props.data === null) return;
  if (props.data.length === 0) return;

  return (
    <>
      {props.data && (
        <>
          {Array.isArray(props.data) &&
            props.data.length !== 0 &&
            props.data.map((item, index) => (
              <CTAButtonSingle
                key={index}
                data={item}
                size={size}
                fullWidth={fullWidth}
                style={props.style}
                args={props.args}
                openModal={props.openModal}
                url={props.url}
              />
            ))}
          {!Array.isArray(props.data) && (
            <CTAButtonSingle
              data={props.data}
              size={size}
              fullWidth={fullWidth}
              style={props.style}
              args={props.args}
              openModal={props.openModal}
              url={props.url}
            />
          )}
        </>
      )}
    </>
  );
};

export default ExternalButton;

const CTAButtonSingle = (props) => {
  const globalNotification = useSelector((state) => state.globalNotification);
  const { whiteListedDomain } = globalNotification || [];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isContinue, setIsContinue] = useState(false);

  useEffect(() => {
    if (isContinue) {
      setIsModalOpen(false);
      setIsContinue(false);
      window.open(props.data.url, "_blank");
    }
  }, [isContinue]);

  const navigate = useNavigate();
  const clickHandler = (url, opensIn) => {
    if (opensIn === "new_window") {
      whiteListedDomain.some((v) => url.includes(v))
      ? window.open(url, "_blank")
      : setIsModalOpen(true);
    } else if (opensIn === "same_window") {
      navigate(url);
    } else if (opensIn === "modal") {
      props.openModal(props.args);
    }
  };

  if (props.data === null) return;
  if (props.data.length === 0) return;

  return (
    <>
      {props.data?.label !== "" && (
        <>
          <Button
            icon={props.data?.leadingIcon}
            data-cslp={props.data?.labelHash ? props.data?.labelHash : null}
            label={props.data?.label}
            size={props.data?.buttonSize || props.size}
            fullWidth={props.fullWidth}
            variant={props.data?.type?props.data.type:"primary"}
            className={props.style}
            onClick={() => clickHandler(props.data?.url, props.data?.openIn)}
          />

          {isModalOpen && (
            <ConfirmationPopup
              isModalOpen={isModalOpen}
              onContinue={setIsContinue}
              onClose={() => {
                setIsModalOpen(false);
              }}
            />
          )}
        </>
      )}
    </>
  );
};
