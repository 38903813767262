import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   data:{} 
};

const profileSlice = createSlice({
  name:"userProfile",
  initialState,
  reducers: {
    setProfileUser(state, action) {
        console.log('action.payload>>>>>>',action.payload);
        state.data = action.payload;
      /*state.__typename = action.payload.__typename;
      state.name = action.payload.name;
      state.employeeId = action.payload.employeeId;*/
    },
  },
});

export const profileUserActions = profileSlice.actions;
export default profileSlice;
