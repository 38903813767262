import  {  useState } from "react";
import InnerHtml from "./InnerHtml";
import Modal from "../global/ui/Modal";
import { TextLink } from "@mgmresorts/mgm-ui";
import styles from "./TrendingTopic.module.css";


const TrendingTopic = (props) => {

  let [showPopup, setShowPopup] = useState(false);
  let [selectedModal, setSelectedModal] = useState();

  const handleClick = (event) => {
    let clickVarient = props.data?.cta?.openIn
    event.preventDefault();
    switch (clickVarient) {
      case "new_window":
        window.open(props.data.cta.url, "_blank")
        break;
      case "same_window":
        window.location.href = props.data.cta.url;
        break
      case "modal":
        openModal();
        break;
    }
  }

  const openModal = () => {
    let modal = props.data?.cta?.modalContent;
    if (modal != null && modal != undefined) {
      setSelectedModal({
        heading: modal.heading,
        body: modal.content,
      });
    } else {
      setSelectedModal({ heading: "", body: "" });
    }
    setShowPopup(true);
  };

  const onPopupClose = () => {
    setShowPopup(false);
  };

  return (
    <>
      {props.data?<div className={styles.slideCardContainer}>
        <img
          className={styles.slideImage}
          src={props.data.image}
          alt={props.data.header}
        />
        <div className={styles.slideDescriptionContainer}>
          <h3 className={styles.slideHeader}>{props.data.header}</h3>
          <div>{props.data.description}</div>
          {props.data.ctaEnabled && (
            <TextLink
              href={props.data.cta.url}
              iconLeft={props.data.cta?.leadingIcon}
              iconRight={props.data.cta?.trailingIcon}
              onClick={handleClick}
              variant="large"
            >
              {props.data.cta.label}
            </TextLink>
          )}
        </div>
        {showPopup && (
        <Modal onClose={onPopupClose} heading={selectedModal.heading}>
          <InnerHtml description={selectedModal.body} />
        </Modal>
      )}{" "}
      </div>:<></>}
    </>
  );
};

export default TrendingTopic;
