import moment from "moment";
import { useQuery } from "@apollo/client";
import APIError from "../../pages/ErrorHandling/APIError";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_ATTENDANCE_DETAILS } from "../../features/api-wrapper/apiQueries";
import Loader from "../global/Loader";

const AttendanceHistory = (props) => {
  const { loading, error, data } = useQuery(GET_ATTENDANCE_DETAILS, {
    client: apiMiddlewareClient,
  });
  if (loading) return <Loader />;
  //if (error) return <p>Error : {error.message}</p>;
  if (error)
    return (
      <div className="api-error">
        <APIError error={error} />
      </div>
    );

  const attHistorySort = data.attendanceHistory
    .map((d) => d)
    .sort(function compare(a, b) {
      return new Date(b.date) - new Date(a.date);
    });

  const attHistory = attHistorySort.filter(
    (item) => item.location === props.location
  );

  const attHistory_single = attHistorySort;

  //console.log(props?.attData?.data?.dictionary);
  //console.log(props?.attData?.data?.dictionaryLables);

  const time_righttitle = props?.attData?.data?.dictionaryLables?.history;
  const time_righttitle_subtitle =
    props?.attData?.data?.dictionaryLables?.previous_6_months;
  const time_noAbsence =
    props?.attData?.data?.dictionaryLables?.no_reported_absences_yet;
  const attendance_unit = props?.attData?.data?.dictionaryLables?.day;

  let count = 0;
  const sixMonthDate = moment().subtract(6, "months").format("M/DD/YYYY");

  return (
    <section className={props.attendanceHistory}>
      <div className="global_nopadding attendance-history">
        <p className="history_header">{time_righttitle}</p>
        <p className="history_subheader">{time_righttitle_subtitle}</p>
        {data.attendanceHistory.length > 0 ? (
          data.attendanceLeaveBalance[0].multiplePropertyIndicator ? (
            (attHistory.length == 0 ?(
              <div>
                <p className="attendance-border"></p>
                <div className="attendance_nopending">
                  {time_noAbsence}
                </div>
                <p className="attendance-border"></p>
              </div>
            ):
             
            
            attHistory.map((val, i) => {
              if (moment(val.date) > moment(sixMonthDate)) {
                count++;
                const date = moment(val.date).format("dddd MMM D, Y");
                return (
                  <div key={i} className="timeoff_status">
                    <div className="global_justifyspacebetween pt-3 ">
                      <span className="timeoff_statusheader">{date}</span>
                      <div className="attendance_history">
                        <p className="timeoff_history_qty">{val.quantity}</p>
                        <p className="timeoff_history_unit">
                          {attendance_unit}
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                );
              } else {
                if (i === attHistory.length - 1 && count === 0) {
                  return (
                    <div key={i}>
                      <p className="attendance-border"></p>
                      <div className="attendance_nopending">
                        {time_noAbsence}
                      </div>
                      <p className="attendance-border"></p>
                    </div>
                  );
                }
              }
            }))
          ) : (
            attHistory_single.map((val, i) => {
              if (moment(val.date) > moment(sixMonthDate)) {
                count++;
                const date = moment(val.date).format("dddd MMM D, Y");
                return (
                  <div key={i} className="timeoff_status">
                    <div className="global_justifyspacebetween pt-3 ">
                      <span className="timeoff_statusheader">{date}</span>
                      <div className="attendance_history">
                        <p className="timeoff_history_qty">{val.quantity}</p>
                        <p className="timeoff_history_unit">
                          {attendance_unit}
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                );
              } else {
                if (i === data.attendanceHistory.length - 1 && count === 0) {
                  return (
                    <div key={i}>
                      <p className="attendance-border"></p>
                      <div className="attendance_nopending">
                        {time_noAbsence}
                      </div>
                      <p className="attendance-border"></p>
                    </div>
                  );
                }
              }
            })
          )
        ) : (
          <div>
            <p className="attendance-border"></p>
            <div className="attendance_nopending">{time_noAbsence}</div>
            <p className="attendance-border"></p>
          </div>
        )}
      </div>
    </section>
  );
};

export default AttendanceHistory;
