import styles from "./ChartItem.module.css";

const ChartItem = (props) => {
  let max = props.isHour ? Math.max(...props.values) : props.maxPay;
  let currentWidth = (props.values[0] / max) * 100;
  let previousWidth = (props.values[1] / max) * 100;
  return (
    <section className={styles.chart}>
      <p data-cslp={props.titleHash}>{props.title}</p>
      <div
        style={{ width: `${currentWidth}%` }}
        className={styles.currentPeriod}
      >
        <p>
          {props.isHour ? "" : "$"}
          {props.hideMyPay ? "xx.xx" : (Math.round(props.values[0]*100)/100).toFixed(2)}
        </p>
      </div>
      <div
        style={{ width: `${previousWidth}%` }}
        className={styles.previousPeriod}
      >
        <p>
          {props.isHour ? "" : "$"}
          {props.hideMyPay ? "xx.xx" : (Math.round(props.values[1]*100)/100).toFixed(2)}
        </p>
      </div>
    </section>
  );
};

export default ChartItem;
