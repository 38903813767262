import React, { useEffect, useState } from "react";
//import { useNavigate } from "react-router-dom";
import styles from "./Accordion.module.css";
import arrow16Down from "../../assets/images/Chevron-down-16.svg";
import arrowDown from "../../assets/images/chevron-down-24.svg";
import arrowUp from "../../assets/images/chevron-up-24.svg";
import { useSelector } from "react-redux";
import { CardContainer,Icon } from "@mgmresorts/mgm-ui";
import Modal from "../global/ui/Modal";
import ChooseCta from "../../hooks/ChooseCta";
// import useFetchData from "../../hooks/useFetchData";
import InnerHtml from "./InnerHtml";

const Accordion = (props) => {
  //console.log(props.data);
  //const navigate = useNavigate();
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );

  
  let [showPopup, setShowPopup] = useState(false);
  let [selectedModal, setSelectedModal] = useState();
  let [accordionStatus, setAccordionStatus] = useState(false);
  let [accordionLinks, setAccordionLinks] = useState([]);

  useEffect(() => {
    if (props.data.children?.length > 0) {
      seperateAllLinks();
    }
  }, []);

  const seperateAllLinks = () => {
    let combinedLinks = [];
    if (props.data.children?.length > 0) {
      combinedLinks.push(seperateLinks(props.data.children));
    }
    setAccordionLinks(...combinedLinks);
  };

  const seperateLinks = (arr) => {
    return [
      arr.filter((_, index) => index < Math.ceil(arr.length / 2)),
      arr.filter((_, index) => index >= Math.ceil(arr.length / 2)),
    ];
  };

  const handleAccordionClick = () => {
    setAccordionStatus(!accordionStatus);
  };

  const openModal = (args) => {
    let modal = accordionLinks[args[0]][args[0]]?.modalContent;
    if (modal != null && modal != undefined) {
      setSelectedModal({
        heading: modal.heading,
        body: modal.content,
      });
    } else {
      setSelectedModal({ heading: "", body: "" });
    }
    setShowPopup(true);

    /*
    let cta = accordionLinks[args[0]][args[0]];
    setSelectedModal({
      heading: cta.modal[0].heading,
      body: cta.modal[0].content,
    });
    */
  };

  const onPopupClose = () => {
    setShowPopup(false);
  };

  return (
    <>
      <div className={styles.accordionCard} >
        <CardContainer
          className={styles.cardContainer}
          style={{ borderRadius: "8px", margin: "0", padding: "0" }}
        >
          <div
            className={`${props.data?.image !== null?styles.cardContainerInner:styles.cardContainerIconInner} ${props.data?.image !== null ||props.data.icon !== null ? styles.setFlex : ""
              }`}
          >
            {props.data.image !== null && (
              <div className={styles.imageHolder}>
                <img data-cslp={props.data?.$?.image} src={props.data.image} />
              </div>
            )}
            {props.data.image==null && props.data.icon !== null && (props.data.icon.url && props.data.icon.url!=="" ?(
              <div className={styles.imageIconHolder}>
                <img data-cslp={props.data?.$?.icon.url} src={props.data.icon.url} />
                {/* <Icon data-cslp={props.data?.$?.icon} name={props.data.icon} /> */}
              </div>
            ):<div className={styles.imageIconHolder} >
            {/* <img data-cslp={props.data?.$?.icon.url} src={props.data.icon.url} /> */}
            <Icon data-cslp={props.data?.$?.icon} name={props.data.icon} size="small"/>
          </div>)}
             
            <div>
              <div
                className={`${styles.accordionHeader} ${accordionStatus ? styles.open : ""}`}
                onClick={() => handleAccordionClick()}
              >
                <h3 data-cslp={props.data?.$?.heading}>{props.data.heading}</h3>
                <div>
                  {accordionStatus && <img src={arrowUp} />}
                  {!accordionStatus && <img src={arrowDown} />}
                </div>
              </div>
              <div>
                <div
                  className={accordionStatus?styles.accordionBodyExpand:styles.accordionBody}
                >
                  <InnerHtml
                    description={props.data?.description}
                    hash={props.data?.$?.description}
                  />
                </div>
                {!accordionStatus && <div className={styles.arrowdown} onClick={() => setAccordionStatus(!accordionStatus)}><p className={styles.readMore} 


                >{globalProperties?.read_more}</p> <img src={arrow16Down} /></div>



                }
                {accordionStatus && accordionLinks.length != 0 && (
                  <div className={styles.links}>
                    {accordionLinks.map((sec, secIndex) => (
                      <React.Fragment key={secIndex}>
                        {sec.length !== 0 && (
                          <div>
                            {sec.map((item, subIndex) => (
                              <ChooseCta
                                key={subIndex}
                                data={item?.cta}
                                type="text_link"
                                size="large"
                                args={[secIndex, subIndex]}
                                openModal={openModal}
                                style={styles.textLink}
                              />
                            ))}
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </CardContainer>
      </div>
      {showPopup && (
        <Modal onClose={onPopupClose} heading={selectedModal.heading}>
          <InnerHtml description={selectedModal.body} />
        </Modal>
      )}
    </>
  );
};

export default Accordion;
