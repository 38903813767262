import { Icon } from "@mgmresorts/mgm-ui";
import ChooseCta from "../../hooks/ChooseCta";
import InnerHtml from "./InnerHtml";
import styles from "./TimelineDesktop.module.css";
import { useEffect, useRef, useState } from "react";

const TimelineDesktop = (props) => {
  //console.log('props.data',props.data);
  const compData = props.data ? props.data : {};
  const blockHolderElement = useRef();
  const lineHolderElement = useRef();
  const scrollEl = useRef();
  const [isMouseDown, setMouseDown] = useState(false);
  const [scrollShift, setScrollShift] = useState(0);
  let dynamicWidth = 0;
  const newArray = compData.timelines.map((data) => {
    if (data.date) {
      let d = new Date(data.date);
      data.year = d.getFullYear();
    }
    dynamicWidth += 200;
    return data;
  });

  let timer = null;

  useEffect(() => {
    dynamicWidth = dynamicWidth + 48 * 2;
    blockHolderElement.current.style.width = `${dynamicWidth}px`;
    lineHolderElement.current.style.width = `${dynamicWidth}px`;
  }, []);
  useEffect(() => {
    if (isMouseDown) {
      timer = setInterval(() => {
        scrollEl.current.scrollLeft += scrollShift;
      }, 100);
    }
  }, [isMouseDown]);

  const mouseUp = () => {
    clearInterval(timer);
    setMouseDown(false);
  };
  const scroll = (scrollOffset) => {
    setMouseDown(true);
    setScrollShift(scrollOffset);
  };
  return (
    <div className={styles.mainContainer}>
      <div>
        <div className={styles.topHeader}>
          <div className={styles.headerL}>
            <div className={styles.infoIcon}>
              <Icon
                name={compData.icon}
                variant="outlined"
                size="large"
                role="img"
              />
            </div>
            <div className={styles.title}>{compData.heading}</div>
            <InnerHtml
              description={compData.description}
              style={styles.subTitle}
            />
          </div>
          <div className={styles.navHolder}>
            <div
              className={styles.leftnavP}
              onMouseDown={() => scroll(-20)}
              onMouseUp={mouseUp}
            >
              <div className={styles.leftnav}></div>
            </div>
            <div
              className={styles.rightnavP}
              onMouseDown={() => scroll(20)}
              onMouseUp={mouseUp}
            >
              <div className={styles.rightnav}></div>
            </div>
          </div>
        </div>
        <div className={styles.scrollHolder} ref={scrollEl}>
          <div className={styles.mapHolder} ref={lineHolderElement}>
            {newArray.map((data, index) => (
              <div className={styles.commonLineBlock} key={index}>
                <div className={styles.flagHolder}>
                  {data.year ? (
                    <>
                      <div className={styles.flag}>{data.year}</div>
                      <div className={styles.pole}></div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div className={styles.bPole}></div>
                </div>
                <div className={styles.hLine}></div>
              </div>
            ))}
          </div>
          <div className={styles.commonBlockH} ref={blockHolderElement}>
            {compData.timelines.map((data, index) => (
              <div className={styles.commonBlock} key={index}>
                <div className={styles.bTitle}>{data.heading}</div>
                <InnerHtml
                  description={data.description}
                  style={styles.sTitle}
                />
                {data?.ctaEnabled && <ChooseCta
                  data={data?.cta}
                  size="small"
                  type="text_link"
                  iconRight="arrow-right"
                />}
                {/*
                <div>
                  <a className={styles.anc}>{data.cta?.label}</a>
                  <div className={styles.arrowR}></div>
                </div>
            */}
              </div>
            ))}
          </div>
        </div>

        {/*<div className={styles.scrollHolder} ref={scrollEl}>
                <div className={styles.mapHolder} ref={lineHolderElement}>
                    <div className={styles.commonLineBlock}>
                        <div className={styles.flagHolder}>
                            <div className={styles.flag}>2019</div>
                            <div className={styles.pole}></div>
                            <div className={styles.bPole}></div>
                        </div>
                        <div className={styles.hLine}></div>

                    </div>
                    <div className={styles.commonLineBlock}>
                        <div className={styles.flagHolder}>
                            <div className={styles.bPole}></div>
                        </div>
                        <div className={styles.hLine}></div>

                    </div>
                    <div className={styles.commonLineBlock}>
                        <div className={styles.flagHolder}>
                            <div className={styles.bPole}></div>
                        </div>
                        <div className={styles.hLine}></div>

                    </div>
                    <div className={styles.commonLineBlock}>
                        <div className={styles.flagHolder}>
                            <div className={styles.flag}>2020</div>
                            <div className={styles.pole}></div>
                            <div className={styles.bPole}></div>
                        </div>
                        <div className={styles.hLine}></div>

                    </div>
                    <div className={styles.commonLineBlock}>
                        <div className={styles.flagHolder}>
                            <div className={styles.bPole}></div>
                        </div>
                        <div className={styles.hLine}></div>

                    </div>

                    
                    <div className={styles.commonLineBlock}>
                        <div className={styles.flagHolder}>
                            <div className={styles.flagl}>2021</div>
                            <div className={styles.polel}></div>
                            <div className={styles.bPole}></div>
                        </div>
                        <div className={styles.hLine}></div>

                    </div>

                </div>
                <div className={styles.commonBlockH} ref={blockHolderElement}>
                    <div className={styles.commonBlock}>
                        <div className={styles.bTitle}>Q2 establish CoE</div>
                        <div className={styles.sTitle} >Lorem ipsum dolor sit amer this is sub head Lorem ipsum dolor sit amer this.</div>
                        <div><a className={styles.anc}>Read more</a><div className={styles.arrowR}></div></div>
                    </div>
                    <div className={styles.commonBlock}>
                        <div className={styles.bTitle}>Q3 announcement</div>
                        <div className={styles.sTitle} >Lorem ipsum dolor sit amer this is sub head Lorem ipsum dolor sit amer this.</div>
                        <div><a className={styles.anc}>Read more</a><div className={styles.arrowR}></div></div>
                    </div>
                    <div className={styles.commonBlock}>
                        <div className={styles.bTitle}>Q4 SOP implementation</div>
                        <div className={styles.sTitle} >Lorem ipsum dolor sit amer this is sub head Lorem ipsum dolor sit amer this.</div>
                        <div><a className={styles.anc}>Read more</a><div className={styles.arrowR}></div></div>
                    </div>
                    <div className={styles.commonBlock}>
                        <div className={styles.bTitle}>Q1 operations playbook</div>
                        <div className={styles.sTitle} >Lorem ipsum dolor sit amer this is sub head Lorem ipsum dolor sit amer this.</div>
                        <div><a className={styles.anc}>Read more</a><div className={styles.arrowR}></div></div>
                    </div>
                    <div className={styles.commonBlock}>
                        <div className={styles.bTitle}>Q2 fire radio system</div>
                        <div className={styles.sTitle} >Lorem ipsum dolor sit amer this is sub head Lorem ipsum dolor sit amer this.</div>
                        <div><a className={styles.anc}>Read more</a><div className={styles.arrowR}></div></div>
                    </div>
                    <div className={styles.commonBlock}>
                        <div className={styles.bTitle}>Q3 Bet MGM</div>
                        <div className={styles.sTitle} >Lorem ipsum dolor sit amer this is sub head Lorem ipsum dolor sit amer this.</div>
                        <div><a className={styles.anc}>Read more</a><div className={styles.arrowR}></div></div>
                    </div>
                </div>
                </div>*/}
      </div>
    </div>
  );
};

export default TimelineDesktop;
