import { Icon, Snackbar } from "@mgmresorts/mgm-ui";
import styles from "./Favourites.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setFavourites } from "../../data/gqlDataReducer";
import { REMOVE_FAVOURITES } from "../../features/api-wrapper/apiQueries";
import { useMutation } from "@apollo/client";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import Loader from "./Loader";
import APIError from "../../pages/ErrorHandling/APIError";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../hooks/useScreenDetails";

const Favourites = (props) => {
  const gqlData = useSelector((state) => state.gqlData);
  const { profile } = gqlData;
  const { favourites } = profile || {};
  const [isMobile] = useWindowDimensions();
  const dispatch = useDispatch();
  const [snackbar, setSnackbar] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState();
  const [latestFav, setLatestFav] = useState();
  const [mutateFunctionRemove, { data: removeBookMarkInfo, loading, error }] =
    useMutation(REMOVE_FAVOURITES, { client: apiMiddlewareClient });

  const heading = props.data[2]?.data?.dictionaryLables?.favorites_heading;
  const description =
    props.data[2]?.data?.dictionaryLables?.favorites_description;
  const noData = props.data[2]?.data?.dictionaryLables?.no_data_text;
  const favoritesDeleted =
  props.data[2]?.data?.dictionaryLables?.your_favorites_deleted;
  const trashIconAltText = 
  props.data[2]?.data?.dictionaryLables?.remove_favorites_hover_text;

  useEffect(() => {
    if (favourites && favourites.length > 0) {
      const selData =
        favourites &&
        favourites.length > 0 &&
        [...favourites].reverse().map((val) => {
          return { ...val, isShowDelete: false };
        });
      setLatestFav(selData);
    }
  }, [favourites]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSnackbar(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, [snackbar]);

  useEffect(() => {
    if (removeBookMarkInfo && favourites) {
      const remainingRecords = favourites.filter((fav) => {
        return fav.url !== selectedUrl;
      });
      dispatch(setFavourites(remainingRecords));
      window.scrollTo(0, 0);
      setSnackbar(true);
    }
  }, [removeBookMarkInfo]);

  const removeFavourites = (url) => {
    setSelectedUrl(url);
    mutateFunctionRemove({
      variables: {
        pageUrl: url,
      },
    });
    setLatestFav([]);
  };

  const setIcon = (data, option) => {
    if (latestFav && latestFav.length > 0) {
      const selData = latestFav.map((val) => {
        if (val.url == data.url) {
          return { ...val, isShowDelete: option == "removeFav" };
        }
        return { ...val, isShowDelete: false };
      });
      setLatestFav(selData);
    }
  };

  if (loading) return <Loader />;
  if (error) return <APIError />;

  return (
    <div className={styles.container} style={props.style}>
      {snackbar && (
        <Snackbar
          className={styles.snackbarFav}
          open
          message={favoritesDeleted}
          variant="two-line"
        />
      )}
      <div className={styles.bookmarkHeader}>
        <h3 className={`text-left ${styles.h2}`}>{heading}</h3>
        <p>{description}</p>
      </div>
      <div className={styles.scrollingContainer}>
        {latestFav && latestFav.length > 0 ? (
          latestFav.map((fav) => {
            return (
              <div
                key={fav.url}
                className={styles.wrapper}
                onMouseEnter={() => setIcon(fav, "removeFav")}
                onMouseLeave={() => setIcon(fav, "addFav")}
              >
                <Link
                  to={fav.url}
                  className={`text-left ${styles.bookmarkItem}`}
                >
                  <h4>{fav.title}</h4>
                  {/* <span>{fav.subTitle}</span> */}
                  <span>{fav.description ? fav.description : fav.subTitle}</span>
                </Link>
                {(fav.isShowDelete || isMobile) && (
                  <button className={`text-right ${styles.deleteIcon}`} title={trashIconAltText}>
                    <Icon
                      name="trash-can"
                      size="small"
                      onClick={() => {
                        removeFavourites(fav.url);
                      }}
                    />
                  </button>
                )}
              </div>
            );
          })
        ) : (
          <p className={styles.noBookmark}>{noData}</p>
        )}
      </div>
    </div>
  );
};

export default Favourites;