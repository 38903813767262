import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import ToggleSwitch from "../../../components/common/toggleSwitch/toggleSwitch";
import styles from "./MyScheduleAPI.module.css";
import "moment/locale/es";
import { apiMiddlewareClient } from "../../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_MY_SCHEDULE } from "../../../features/api-wrapper/apiQueries";
import APIError from "../../ErrorHandling/APIError";
import MyScheduleCard from "./MyScheduleCard";
import Loader from "../../../components/global/Loader";
import { getScheduleDateRange } from "../../Dashboard/MyScheduleBoard";
import { Toggle } from "@mgmresorts/mgm-ui";

const MyScheduleAPI = (props) => {
  const [myScheduleToday, setMyScheduleToday] = useState();
  const [upcomingDays, setUpcomingDays] = useState([]);
  let [is24Hour, setIs24Hour] = useState(true);
  const { dictionaryLables } = props.csData;
  const [getMySchedule, { loading, error, data: myScheduleData }] =
    useLazyQuery(GET_MY_SCHEDULE, {
      fetchPolicy: "no-cache",
      client: apiMiddlewareClient,
    });
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );

  const hide24Hour = () => {
    setIs24Hour(!is24Hour);
  };

  useEffect(() => {
    getMySchedule({ variables: {} });
  }, []);

  const formatData = (scheduleData) => {
    const groupedAPIData = scheduleData.reduce((acc, value) => {
      if (!acc[value.startDate]) {
        acc[value.startDate] = [];
      }
      acc[value.startDate].push(value);
      return acc;
    }, {});
    const res = Object.entries(groupedAPIData).map(([heading, data]) => ({
      heading:
        globalProperties?.short_day[
          new Date(moment(heading).format("MM/DD/YYYY")).getDay()
        ],
      data,
      notes: data[0].notes,
    }));
    return res;
  };

  useEffect(() => {
    if (myScheduleData && myScheduleData?.mySchedule) {
      if (
        myScheduleData?.mySchedule.isAckMessageAvailable ||
        myScheduleData?.mySchedule.isMessageAvailable
      ) {
        return props.message({
          isAckMessageAvailable:
            myScheduleData?.mySchedule.isAckMessageAvailable,
          isMessageReview: myScheduleData?.mySchedule.isMessageAvailable,
        });
      }
      // generate dates
      const today = new Date();
      const yesterday = new Date(today.setDate(today.getDate() - 1));
      const dayAfterTomorrow = new Date(today.setDate(today.getDate() + 3));
      const myscheduleDefaultArray = getScheduleDateRange(
        yesterday,
        dayAfterTomorrow,
        "days",
        dictionaryLables?.no_shift_schduled
      );
      // if api returns valid data
      if (
        myScheduleData?.mySchedule?.dayWiseSchedule &&
        myScheduleData?.mySchedule?.dayWiseSchedule.length > 0
      ) {
        const scheduleResponse = myScheduleData?.mySchedule;
        let dayWiseScheduleData = scheduleResponse.dayWiseSchedule.map(
          (schedule) => {
            const startTime = schedule.startTime.split(" ");
            const endTime = schedule.endTime.split(" ");
            return {
              ...schedule,
              isWaitingTime: false,
              startTime24: moment(startTime[1], ["h:mm A"]).format("HH:mm"),
              endTime24: moment(endTime[1], ["h:mm A"]).format("HH:mm"),
              startTime12: moment(startTime[1], ["HH:mm"]).format("hh:mm A"),
              endTime12: moment(endTime[1], ["HH:mm"]).format("hh:mm A"),
              isToday: moment(schedule.startDate).isSame(moment(), "day"),
              isPrevious:
                new Date(moment(schedule.startDate).format("MM/DD/YYYY")) <
                new Date(moment().format("MM/DD/YYYY")),
            };
          }
        );

        // delete date from myscheduleDefaultArray array if date exist
        dayWiseScheduleData.forEach((element) => {
          myscheduleDefaultArray.forEach((el, idx) => {
            if (
              moment(element.startDate).format("YYYY-MM-DD") === el.startDate
            ) {
              delete myscheduleDefaultArray[idx];
            }
          });
        });
        let consolidatedData = dayWiseScheduleData.concat(
          myscheduleDefaultArray.filter((el) => el != null)
        );
        if (
          scheduleResponse.onWaitlistDates &&
          scheduleResponse.onWaitlistDates.length > 0
        ) {
          const waitingListData = consolidatedData.map((day) => {
            const waitingList = scheduleResponse.onWaitlistDates.some(
              (o2) => day.startDate === o2
            );
            return waitingList ? { ...day, isWaitingTime: true } : day;
          });
          consolidatedData = waitingListData;
        }
        const res = formatData(consolidatedData);
        const todayData = res.filter((today) =>
          today.data.some((o2) => o2.isToday && !o2.isPrevious)
        );
        const upcomingDays = res.filter((upcome) =>
          upcome.data.some((o2) => !o2.isToday && !o2.isPrevious)
        );

        if (todayData.length > 0) {
          setMyScheduleToday(todayData[0]);
        }
        setUpcomingDays(upcomingDays);
      } else {
        const defaultResponse = formatData(myscheduleDefaultArray);
        const todayData = defaultResponse.filter((today) =>
          today.data.some((o2) => o2.isToday && !o2.isPrevious)
        );
        const upcomingDays = defaultResponse.filter((upcome) =>
          upcome.data.some((o2) => !o2.isToday && !o2.isPrevious)
        );

        if (todayData.length > 0) {
          setMyScheduleToday(todayData[0]);
        }
        setUpcomingDays(upcomingDays);
      }
    }
  }, [myScheduleData]);

  if (loading) return <Loader />;
  if (error)
    return (
      <div className="api-error">
        <APIError error={error} />
      </div>
    );

  return (
    <>
      {myScheduleToday && (
        <Fragment>
          <div className={styles.DateAndToggle}>
            <div className={styles.Today}>{dictionaryLables?.today}</div>
            <div className={styles.toggleContainer}>
              <div className="pr-2">{dictionaryLables?.clock_hours}</div>
              <div className={styles.clock} onClick={hide24Hour}>
                {/* <ToggleSwitch  /> */}
                <Toggle checked={is24Hour} />
              </div>
            </div>
          </div>
          <MyScheduleCard
            apiData={myScheduleToday}
            is24Hour={is24Hour}
            csData={props.csData}
          />
        </Fragment>
      )}
      {upcomingDays && upcomingDays.length > 0 && (
        <Fragment>
          <div className={styles.DateAndToggle}>
            <div className={styles.upcomingDays}>
              {" "}
              {dictionaryLables?.upcoming_days}
            </div>
            {!myScheduleToday && (
              <div className={styles.toggleContainer}>
                <div className="pr-2">{dictionaryLables?.clock_hours}</div>
                <div className={styles.clock} onClick={hide24Hour}>
                  {/* <ToggleSwitch onChange={hide24Hour} /> */}
                  <Toggle checked={is24Hour} />
                </div>
              </div>
            )}
          </div>
          {upcomingDays.map((schedule, index) => {
            return (
              <MyScheduleCard
                key={index}
                apiData={schedule}
                is24Hour={is24Hour}
                csData={props.csData}
              />
            );
          })}
        </Fragment>
      )}
    </>
  );
};

export default MyScheduleAPI;
