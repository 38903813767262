import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  onLivePreviewPage: true,
};

const liveSlice = createSlice({
  name: "live",
  initialState,
  reducers: {
    setOnLivePreviewPage(state, action) {
      state.onLivePreviewPage = action.payload;
    },
  },
});

export const liveActions = liveSlice.actions;
export default liveSlice;
