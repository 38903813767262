import useFetchData from "../hooks/useFetchData";
import ErrorHanding from "../pages/ErrorHandling/ErrorHanding";
import Loader from "../components/global/Loader";

const WithFetchData = (EmptyComponent) => {
  function PackedComponent(props) {
    //console.log("HIGHER ORDER COMP!!");
    const { loading, error, data, internalSystemType } = useFetchData(props.url, props.pageType);
    if (loading) return <Loader />;
    if (error) return <ErrorHanding error={error} />;
    return <EmptyComponent data={data} internalSystemType={internalSystemType} />;
  }
  return PackedComponent;
};
export default WithFetchData;
