import Callout from "../components/common/Callout";
import HelpLinks from "../components/common/HelpLinks";
import ContentCardFrame from "../components/common/ContentCardFrame";
import ContentAuthorNote from "../pages/ErrorHandling/ContentAuthorNote";

const GetRightRailComponent = (props) => {
  const componentMapping = [
    { name: "callout", component: "Callout" },
    { name: "help_link", component: "HelpLinks" },
    { name: "content_card", component: "ContentCardFrame" },
  ];

  const componentNames = {
    Callout,
    HelpLinks,
    ContentCardFrame,
  };

  if (props.error)
    return (
      <ContentAuthorNote
        message={`'${props.comp}' -> ${props.error?.message}`}
      />
    );

  let index = componentMapping.findIndex((item) => item.name === props.comp);
  if (index === -1)
    return (
      <ContentAuthorNote
        message={`'${props.comp}' -> Component not yet created in frontend!`}
      />
    );

  let componentName = componentMapping[index]?.component;

  if (!componentName)
    return (
      <ContentAuthorNote
        message={`Something went wrong! Component not derived properly!`}
      />
    );

  let Component = componentNames[componentName];

  if (!Component)
    return (
      <ContentAuthorNote
        message={`Something went wrong! Component not derived properly!`}
      />
    );
  return <Component data={props.data} />;
};

export default GetRightRailComponent;
