import {ApolloClient, InMemoryCache, from, HttpLink} from '@apollo/client';
 
const GRAPHQL_ENDPOINT =
 'https://graphql.contentstack.com/stacks/blt8dcef5b29debe527?environment=dev&include_branch=false';
 
const apolloClient = () => {
 const link = new HttpLink({
   uri: GRAPHQL_ENDPOINT,
   headers: {
     access_token: 'cs4808ab2d255c893b61711dd1',
   },
 });
 
 return new ApolloClient({
   link: from([link]),
   cache: new InMemoryCache(),
 });
};
export default apolloClient;

