const AccordionContent = (props) => {
  //let percentage = (props.list.amount/props.grossPay*100).toFixed(1) + "%";
  return (
    <div>
      <p>{props.list.name}</p>
      <div>
        {/* <span>{props.hide ? "xx%" : percentage}</span> */}
        <span>{props.hide ? `${props.symbol}xx.xx` : `${props.symbol}${(Math.round(props.list.amount*100)/100).toFixed(2)}`}</span>
      </div>
    </div>
  );
};

export default AccordionContent;
