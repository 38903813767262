import { createContext, useContext } from "react";
import { customEventTracking } from "../../appInsights";


const TrackingContext = createContext();

export const TrackingProvider = ({ children }) => {
  
  const trackEvent = (eventName, eventData) => {
    // Implement your tracking logic here
    const compObj = {pageUrl:window.location.pathname}
    console.log("Event: trackingProvider", eventName, "Data:", eventData);
    customEventTracking(eventName, { ...eventData,...compObj });
  };

  return (
    <TrackingContext.Provider value={{ trackEvent }}>
      {children}
    </TrackingContext.Provider>
  );
};

export const useTracking = () => useContext(TrackingContext);