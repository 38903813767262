import { useQuery } from "@apollo/client";
import { Icon } from "@mgmresorts/mgm-ui";
import { useRef } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useSelector } from "react-redux";
import Loader from "../../../components/global/Loader";
import { apiMiddlewareClient } from "../../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_FEATURED_PROPERTY_NEWS } from "../../../features/api-wrapper/apiQueries";
import APIError from "../../ErrorHandling/APIError";
import FeaturedTile from "./FeaturedTile";
import "./Features.module.css";
import style from "./Features.module.css";
import OccuStyle from "./Occupancy.module.css";
import useWindowDimensions from "../../../hooks/useScreenDetails";

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};

const Features = (props) => {
  const carousel = useRef(null);
  const [isMobile] = useWindowDimensions();
  const { loading, error, data } = useQuery(GET_FEATURED_PROPERTY_NEWS, {
    client: apiMiddlewareClient,
  });
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );
  const { dictionaryLables } = props.data;

  if (loading) return <Loader />;
  if (error)
    return (
      <div style={{ margin: "30px 20px" }}>
        <APIError error={error} />
      </div>
    );

  return (
    <>
      {data && data.featuredPropertyNews && (
        <div className={`mt-5 mb-3`}>
          <div className="row">
            <div className={`col-8 ${OccuStyle.occupancyHeader}`}>
              <h3 className="d-inline">{dictionaryLables?.featured}</h3>
            </div>
            {data.featuredPropertyNews.length > 3 && (
              <div className={`${OccuStyle.navHolder} col-4`}>
                <button
                  title={globalProperties?.previous}
                  className={OccuStyle.leftnavP}
                  onClick={(e) => carousel?.current?.slidePrev(e)}
                >
                  {" "}
                  <Icon name="chevron-left" size="small" />
                </button>
                <button
                  title={globalProperties?.next}
                  className={OccuStyle.rightnavP}
                  onClick={(e) => carousel?.current?.slideNext(e)}
                >
                  <Icon name="chevron-right" size="small" />
                </button>
              </div>
            )}
          </div>
          <div className={`mt-3 ${style.featuresContainer}`}>
            {(data.featuredPropertyNews.length > 3 && !isMobile) ||
            (data.featuredPropertyNews.length > 1 && isMobile) ? (
              <AliceCarousel
                key="carousel"
                items={data.featuredPropertyNews.map((data, index) => {
                  if (
                    (index - carousel?.current?.state.currentIndex) % 3 ==
                    0
                  ) {
                    return (
                      <div className={style.tileSpace} key={data.newsId}>
                        <FeaturedTile data={data} />
                      </div>
                    );
                  } else if (
                    (index - carousel?.current?.state.currentIndex + 1) % 3 ==
                    0
                  ) {
                    return (
                      <div className={style.tileSpaceL} key={data.newsId}>
                        <FeaturedTile data={data} />
                      </div>
                    );
                  } else {
                    return (
                      <div className={style.tileSpaceM} key={data.newsId}>
                        <FeaturedTile data={data} />
                      </div>
                    );
                  }
                })}
                responsive={responsive}
                keyboardNavigation={true}
                ref={carousel}
                infinite={false}
                controlsStrategy="responsive"
                mouseTracking
                dotsDisabled={true}
                buttonsDisabled={true}
                centerMode={false}
              />
            ) : data.featuredPropertyNews?.length > 0 ? (
              <div
                className={isMobile ? style.plainTilesMob : style.plainTiles}
              >
                {data.featuredPropertyNews.map((data) => {
                  return (
                    <div key={data.newsId}>
                      <FeaturedTile data={data} />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className={style.noEvents}>
                {dictionaryLables?.no_events_message}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Features;
