import { CardContainer } from "@mgmresorts/mgm-ui";
import styles from "./HelpLinks.module.css";
import ChooseCta from "../../hooks/ChooseCta";
import { useState } from "react";
import Modal from "../global/ui/Modal";
import InnerHtml from "../dynamic/InnerHtml";

const HelpLinks = (props) => {
  //console.log(props.data);
  let [showPopup, setShowPopup] = useState(false);
  let [selectedModal, setSelectedModal] = useState();

  const openModal = (args) => {
    let modal = props.data.children[[args]]?.cta?.modalContent;
    if (modal != null && modal != undefined) {
      setSelectedModal({
        heading: modal.heading,
        body: modal.content,
      });
    } else {
      setSelectedModal({ heading: "", body: "" });
    }
    setShowPopup(true);
  };

  const onPopupClose = () => {
    setShowPopup(false);
  };

  return (
    <section className={styles.helpLinks}>
      <CardContainer
        className={styles.cardContainer}
        style={{ borderRadius: "8px", margin: "0", padding: "0" }}
      >
        <div className={styles.cardContainerInner}>
          <h3 data-cslp={props.data?.$?.heading}>{props.data.heading}</h3>
          {props.data.children?.map((item, index) => (
            <ChooseCta
              key={index}
              data={item?.cta}
              size="large"
              iconRight="arrow-right"
              type="text_link"
              args={index}
              openModal={openModal}
            />
          ))}
        </div>
      </CardContainer>
      {showPopup && (
        <Modal onClose={onPopupClose} heading={selectedModal.heading}>
          <InnerHtml description={selectedModal.body} />
        </Modal>
      )}
    </section>
  );
};

export default HelpLinks;
