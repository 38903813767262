import style from "./ProfileAgentsearch.module.css";
import {  useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {setLocalStoreValue,getLocalStoreValue,clearSpecificLocalStoreVal} from "../../utils/localStore"
import { searchResultActions } from "../../data/search-result-slice";
import { useDispatch } from "react-redux";

const ProfileAgentsearch = ()=>{
    let data;
    let name;
    useSelector((state) => {
        data = state.userProfile.data;
        if(data && data.employeeId )
        {
            setLocalStoreValue('data',JSON.stringify(data))
        }
        else
        {
            data = JSON.parse(getLocalStoreValue('data'))
        }
        if(data && data.name)
        {
            name = data.name;
        }
        
        
    })
   
    const getInitials =  ()=> {
        if(!name)
        {
            return;
        }
        var parts = name.split(' ')
        console.log('parts >>>',parts);
        var initials = ''
        for (var i = 0; i < parts.length; i++) {
          if (parts[i].length > 0 && parts[i] !== '' && (i==0 || i== parts.length-1)) {
            initials += parts[i][0]
          }
        }
        return initials
      }

    console.log("getInitials>>>",getInitials());
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const goToNewSearch = ()=>
    {
        setLocalStoreValue("preview","");
        clearSpecificLocalStoreVal('data');
        let cleardataObj = {};
        dispatch(
            searchResultActions.setsearchResultPageData(cleardataObj)
          );
        navigate("/employeeId");
    }
    return(<div className={style.pholder}>
        <div className={style.lnav}>
            <div className={style.lnavclick} onClick={goToNewSearch}>New search</div>
        </div>
        <div className={style.psection}>
            <div className={style.divider}></div>
            <div className={style.profileIcon}><span>{getInitials()}</span></div>
            {data && <div className={style.pnameh}><p className={style.p1}>{data.name} ({data.employeeId})</p><p className={style.p2}>{data.businessTitle}{data.businessTitle == null?'':','} {data.companyName}</p></div>}
        </div>
    </div>)
}
export default ProfileAgentsearch;